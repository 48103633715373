import CacheExtension from "../../StarterKit/Filter/CacheExtension";
import React, {useEffect, useState,} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconActionButton} from "./IconActionButton";
import ClientService from "../Services/Bll/ClientService";

export const ProductModal = ({product}) => {
    const clientService = new ClientService();

    const [open, setOpen] = useState(false)
    const [data, setData] = useState(null)
    const [email, setEmail] = useState(null)
    const [address, setAddress] = useState(null)
    const [phone, setPhone] = useState(null)
    const [edit, setEdit] = useState({
        mails: false,
        addresses: false,
        phones: false
    })
    const [errors, setErrors] = useState({
        mails: "",
        addresses: "",
        phones: ""
    });

    $(function () {
        $('[data-toggle="tooltip"]').tooltip()
    })

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        clientService.read(product.owner).then((res) => {
            const r = res.getData()
            setData(r)
            setEmail(r.mails && Object.keys(r.mails).find(key => r.mails[key] === 1))
            setAddress(r.addresses && Object.keys(r.addresses).find(key => r.addresses[key] === 1))
            setPhone(r.phones && Object.keys(r.phones).find(key => r.phones[key] === 1))
            setOpen(true);
        })

    };

    const handleSubmit = async (field) => {
        let updated = {};
        switch(field) {
            case 'mails':
                const emailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
                if (!emailRegex.test(email)) {
                    setErrors({
                        ...errors,
                        mails: CacheExtension.getDataFromCache("InvalidEmail")
                    })
                } else {
                    updated[field] = {...data[field]};
                    Object.keys(updated[field]).forEach(key => {
                        updated[field][key] = (key === email) ? 1 : 0;
                    });
                    if (!updated[field][email]) {
                        updated[field][email] = 1;
                    }
                }
                break;
            case 'addresses':
                updated[field] = {...data[field]};
                Object.keys(updated[field]).forEach(key => {
                    updated[field][key] = (key === address) ? 1 : 0;
                });
                if (!updated[field][address]) {
                    updated[field][address] = 1;
                }
                setEdit({
                    ...edit,
                    addresses: false
                })
                break;
            case 'phones':
                const phoneRegex = /^\d{10}$/;
                if (!phoneRegex.test(phone)) {
                    setErrors({
                        ...errors,
                        phones: CacheExtension.getDataFromCache("InvalidPhone")
                    })
                } else {
                    updated[field] = {...data[field]};
                    Object.keys(updated[field]).forEach(key => {
                        updated[field][key] = (key === phone) ? 1 : 0;
                    });
                    if (!updated[field][phone]) {
                        updated[field][phone] = 1;
                    }
                    setEdit({
                        ...edit,
                        phones: false
                    })
                }
                break;
        }

        let res = await clientService.update(product.owner, updated);

        if (res.getCode() == -1094) {
            setErrors({
                mails: CacheExtension.getDataFromCache("EmailAlreadyUsed")
            })
        } else if(field == "mails") {
            setEdit({
                ...edit,
                mails: false
            })
        }
    };

    return (
        <>
            {product.ownerEnabled == 1 ?
                <IconActionButton tooltip={CacheExtension.getDataFromCache("User")} action={handleOpen} state={true} className={"btn productUserOwned"} icon={<FontAwesomeIcon icon="fas fa-user" />}></IconActionButton> :
                <IconActionButton tooltip={CacheExtension.getDataFromCache("User")} action={handleOpen} state={true} className={"btn productUserTransit"} icon={<FontAwesomeIcon icon="fas fa-user" />}></IconActionButton>
            }
            {open && data && data.length != 0 && (
                <div className="modal-container">
                    <div className="modal-overlay" onClick={handleClose}></div>
                    <div className="modal-dialog w-50" style={{maxWidth: "none", height: "90%"}}>
                        <div className="modal-content" style={{height: "100%"}}>
                            <div className="modal-header">
                                <h5 className="modal-title">{product.serial}</h5>
                                <button type="button" className="close" onClick={handleClose}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            {(data && data.length != 0) ?
                                <div className="fullSize modal-body">
                                    <div className={'form-group'}>
                                        <label className={'mb-1 productModalLabel'}>{CacheExtension.getDataFromCache("Name")}</label>
                                        <p>{data.name}</p>
                                    </div>

                                    <div className={'form-group'}>
                                        <label className={'mb-1 productModalLabel'}>{CacheExtension.getDataFromCache("Lastname")}</label>
                                        <p>{data.lastName}</p>
                                    </div>

                                    <div className={'form-group productModalGrp'}>
                                        <div className="d-flex">
                                            <label className={'mb-1 productModalLabel'}>{CacheExtension.getDataFromCache("Email")}</label>
                                            <IconActionButton
                                                className={"btn modelDetails py-0"}
                                                action={() => {
                                                    setEmail(Object.keys(data.mails).find(key => data.mails[key] === 1));
                                                    setEdit({
                                                        ...edit,
                                                        mails: !edit.mails
                                                    });
                                                    setErrors({
                                                        ...errors,
                                                        mails: ""
                                                    })
                                                }}
                                                state={true}
                                                tooltip={CacheExtension.getDataFromCache('Edit')}
                                                icon={<FontAwesomeIcon icon="fas fa-pen"/>}
                                            />
                                            {edit.mails &&
                                                <IconActionButton className={"btn modelDetails py-0"} action={() => handleSubmit("mails")} state={true} icon={<FontAwesomeIcon icon="fas fa-check"/>}  tooltip={CacheExtension.getDataFromCache('Validate')}/>
                                            }
                                        </div>
                                        {edit.mails ?
                                            <input type="email" style={{width : '300px'}} className="form-control"
                                                   value={email}
                                                   onChange={function (e) {
                                                       if ($(e.target).val() !== ''){
                                                           setEmail($(e.target).val())
                                                       }
                                                   }}
                                            /> :
                                            <p>{email}</p>
                                        }
                                        {errors.mails && <div className="showError">{errors.mails}</div>}
                                    </div>

                                    <div className={'form-group productModalGrp'}>
                                        <div className="d-flex">
                                            <label className={'mb-1 productModalLabel'}>{CacheExtension.getDataFromCache("Address")}</label>
                                            <IconActionButton
                                                className={"btn modelDetails py-0"}
                                                action={() => {
                                                    setAddress(Object.keys(data.addresses).find(key => data.addresses[key] === 1));
                                                    setEdit({
                                                        ...edit,
                                                        addresses: !edit.addresses
                                                    });
                                                    setErrors({
                                                        ...errors,
                                                        addresses:""
                                                    })
                                                }}
                                                state={true}
                                                tooltip={CacheExtension.getDataFromCache('Edit')}
                                                icon={<FontAwesomeIcon icon="fas fa-pen"/>}
                                            />
                                            {edit.addresses &&
                                                <IconActionButton className={"btn modelDetails py-0"} action={() => handleSubmit("addresses")} state={true} icon={<FontAwesomeIcon icon="fas fa-check"/>}  tooltip={CacheExtension.getDataFromCache('Validate')}/>
                                            }
                                        </div>
                                        {edit.addresses ?
                                            <input type="text" style={{width : '300px'}} className="form-control"
                                                   value={address}
                                                   onChange={function (e) {
                                                       if ($(e.target).val() !== ''){
                                                           setAddress($(e.target).val())
                                                       }
                                                   }}
                                            /> :
                                            <p>{address}</p>
                                        }
                                        {errors.addresses && <div className="showError">{errors.addresses}</div>}
                                    </div>
                                    <div className={'form-group productModalGrp'}>
                                        <div className="d-flex">
                                            <label className={'mb-1 productModalLabel'}>{CacheExtension.getDataFromCache("Phone")}</label>
                                            <IconActionButton
                                                className={"btn modelDetails py-0"}
                                                action={() => {
                                                    setPhone(Object.keys(data.phones).find(key => data.phones[key] === 1));
                                                    setEdit({
                                                        ...edit,
                                                        phones: !edit.phones
                                                    });
                                                    setErrors({
                                                        ...errors,
                                                        phones: ""
                                                    })
                                                }}
                                                state={true}
                                                tooltip={CacheExtension.getDataFromCache('Edit')}
                                                icon={<FontAwesomeIcon icon="fas fa-pen"/>}
                                            />
                                            {edit.phones &&
                                                <IconActionButton className={"btn modelDetails py-0"} action={() => handleSubmit("phones")} state={true} icon={<FontAwesomeIcon icon="fas fa-check"/>}  tooltip={CacheExtension.getDataFromCache('Validate')}/>
                                            }
                                        </div>
                                        {edit.phones ?
                                            <input type="number" maxLength={10} style={{width : '300px'}} className="form-control"
                                                   value={phone}
                                                   onChange={function (e) {
                                                       if ($(e.target).val() !== ''){
                                                           setPhone($(e.target).val())
                                                       }
                                                   }}
                                            /> :
                                            <p>{phone}</p>
                                        }
                                        {errors.phones && <div className="showError">{errors.phones}</div>}
                                    </div>
                                </div> :
                                <div className="m-3">{CacheExtension.getDataFromCache('NoUser')}</div>
                            }
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
