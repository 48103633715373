import SettingService from "../../../StarterKit/Service/Bll/SettingService";
import {useNavigate} from "react-router-dom";

export default class HelperService {

    async setLocalStorage() {
        var serviceSettings = new SettingService();

        try {
            if (!localStorage.getItem('locale')) {
                localStorage.setItem('locale', localStorage['locale'] ? localStorage['locale'] : 'FR');
            }
            if (!localStorage.getItem('securityZone')) {
                var securityZone = await serviceSettings.getFromGroup("securityZone");
                localStorage.setItem(securityZone.context.group, JSON.stringify(securityZone.getData()));
            }
            var resultCache = await serviceSettings.getFromGroup("cacheBack");
            var settings = resultCache.getData();
            if (Object.keys(settings).length > 0) {
                for (const settingsKey in settings) {
                    if (!localStorage.getItem(settingsKey) || settingsKey == 'labelBack' || settingsKey == 'backHome') {
                        var resultSettings = await serviceSettings.getFromGroup(settingsKey);
                        localStorage.setItem(settingsKey, JSON.stringify(resultSettings.getData()));
                    }
                }
            }
        } catch (e) {
            console.log(e)
            window.location.href = "/login";
        }

    }
}
