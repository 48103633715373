import '../../styles/login.css';
import '../../styles/sk.css';
import '../../styles/register.css';
import logo from "../../res/logo.png"
import axios from 'axios';
import {motion as m} from "framer-motion"
import React, {useEffect, useState} from "react";

export default function ForgotPassword() {
    const [useProduct, setUseProduct] = useState('');
    const [product, setProduct] = useState([]);

    const state = {
        name: '',
        password: '',
    }

    const handleChangeLogin = event => {
        state.name = event.target.value;
    }
    const handleChangePassword = event => {
        state.password = event.target.value;
    }

    const handleSubmit = event => {
        event.preventDefault();
        const user = {
            username: state.name,
            password: state.password
        };
        axios.post(`http://localhost:5015/login_check`,  user, { headers : {'Content-Type': 'application/json','Access-Control-Allow-Origin' : '*',"Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS","Access-Control-Allow-Headers": "Content-Type, Authorization, X-Requested-With"}} )
            .then(res => {
            })
    }
    return (
        <>
            <m.div initial={{ opacity : 0}} animate={{ opacity: 1}} transition={{duration: 0.50, ease: "easeOut"}}>
                <div className="row mt-5">
                    <div className="offset-1 offset-sm-2 offset-xl-3 col-10 col-sm-8 col-xl-6 tableBackground mt-5">
                        <div className="login-form text-center pt-3 pb-3">
                            <div id="header">
                                <div className="row cm-color-title">
                                    <div className="col-1 text-right ml-3" style={{ fontSize: "2rem" }}>
                                        <a
                                            className="actionLink "
                                            data-sk="icon"
                                            data-sktype="back"
                                            href="/src/StarterKit/Route/Login"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title=""
                                            data-original-title="Retour à l'accueil"
                                        >
                                            <svg
                                                className="svg-inline--fa fa-arrow-left"
                                                aria-hidden="true"
                                                focusable="false"
                                                data-prefix="fas"
                                                data-icon="arrow-left"
                                                role="img"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 448 512"
                                                data-fa-i2svg=""
                                            >
                                                <path
                                                    fill="currentColor"
                                                    d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"
                                                />
                                            </svg>
                                            {/* <i class="fas fa-arrow-left"></i> Font Awesome fontawesome.com */}
                                        </a>
                                    </div>
                                    <div className="col-10 text-center">
                                        <h1>
                                            {"{"}
                                            {"{"} "ForgotPasswordPageTitle"|cache {"}"}
                                            {"}"}
                                        </h1>
                                    </div>
                                </div>
                            </div>
                            <div className="row ">
                                <div className="col-10 offset-1 text-center mb-2 text-danger">
                                    {"{"}
                                    {"{"} "ForgotPasswordInformation"|cache {"}"}
                                    {"}"}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-10 offset-1">
                                    <form onSubmit="return skRecover()">
                                        <div className="form-group">
                                            <label htmlFor="sk_username" className="required">
                                                {"{"}
                                                {"{"}'LoginUsername'|cache {"}"}
                                                {"}"}
                                            </label>
                                            <input
                                                type="text"
                                                id="sk_username"
                                                required="required"
                                                autoFocus="autofocus"
                                                data-toggle="tooltip"
                                                data-placement="left"
                                                title=""
                                                className="form-control"
                                                data-original-title="{{'MandatoryField'|cache }}"
                                            />
                                        </div>
                                        <div className="form-group">
                                            <button type="submit" className="button btn">
                                                {"{"}
                                                {"{"}'ForgotPasswordConfirmButton'|cache {"}"}
                                                {"}"}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </m.div>
        </>
    );
}






