import PropTypes from "prop-types";
import CacheExtension from "../../StarterKit/Filter/CacheExtension";
import React, {useEffect, useState,} from "react";
import ProductService from "../Services/Bll/ProductService";
import MediaService from "../../StarterKit/Service/Bll/MediaService";
import {Document, Page} from "react-pdf";

export const ProductInvoiceModal = ({product, manage = false}) => {
    let currentDate = new Date().toISOString().split('T')[0];
    const initialForm = {
        date: currentDate
    };
    const service = new ProductService()
    const mediaService = new MediaService();
    const [open, setOpen] = useState(false);
    const [form, setForm] = useState(initialForm);
    const [image, setImage] = useState(null);

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        if (product.product && product.product.invoice && product.product.invoice != 'reminder' && product.product.invoice != 'Refused') {
            mediaService.read(product.product.invoice).then(function (result) {
                let imageUrl = URL.createObjectURL(result);
                setImage({url: imageUrl, type: result.type});
            });
        }
        setOpen(true);
    };

    const handleChange = event => {
        setForm({
            ...form,
            [event.target.name]:event.target.value
        });
    }
    function onDocumentLoadSuccess(){
        console.log('load')
    }

    return (
        <>
            <button className="btn btnBo" onClick={handleOpen}>
                {CacheExtension.getDataFromCache('See')}
            </button>
            {open && (
                <div className="modal-container">
                    <div className="modal-overlay" onClick={handleClose}></div>
                    <div className="modal-dialog w-75" style={{maxWidth: "none", height: "90%"}}>
                        <div className="modal-content" style={{height: "100%"}}>
                            <div className="modal-header">
                                <h5 className="modal-title">{CacheExtension.getDataFromCache('ProductInvoiceModal')}</h5>
                                <button type="button" className="close" onClick={handleClose}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="fullSize modal-body">
                                {image &&
                                    <div id="productInvoicePicture">
                                        {image.type === "application/pdf" ? (
                                            image.url &&
                                            <Document  onClick={() => window.open(image.url, "_blank")}  file={image.url} onLoadSuccess={onDocumentLoadSuccess}>
                                                <Page pageNumber={1} width={Math.min(600, window.innerWidth - 20)} renderAnnotationLayer={false} renderTextLayer={false}/>
                                            </Document>
                                        ) : (
                                            <img onClick={() => window.open(image.url, "_blank")} className={"w-100"} src={image.url} alt="invoice"/>
                                        )}
                                    </div>
                                }
                                {manage &&
                                    <>
                                        <div>
                                            <div className="form-group">
                                                <label className={'mb-1'} htmlFor="date"><strong>{CacheExtension.getDataFromCache('Sku')}</strong></label>
                                                <p>{product.product.modelId}</p>
                                            </div>
                                            <div className="form-group">
                                                <label className={'mb-1'} htmlFor="date"><strong>{CacheExtension.getDataFromCache('Title')}</strong></label>
                                                <p>{product.model.title}</p>
                                            </div>
                                            <div className="form-group">
                                                <label className={'mb-1'} htmlFor="date"><strong>{CacheExtension.getDataFromCache('VariantId')}</strong></label>
                                                <p>{product.model.variantId}</p>
                                            </div>
                                            <div className="form-group">
                                                <label className={'mb-1'} htmlFor="date"><strong>{CacheExtension.getDataFromCache('Serial')}</strong></label>
                                                <p>{product.product.serial}</p>
                                            </div>
                                            <div className="form-group">
                                                <label className={'mb-1'} htmlFor="date"><strong>{CacheExtension.getDataFromCache('Email')}</strong></label>
                                                <p>{product.userEmail}</p>
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="form-group col-md-3">
                                                <label htmlFor="date"><strong>* {CacheExtension.getDataFromCache('SaleDate')}</strong></label>
                                                <input value={form.date} className="form-control" type='date' name="date" onChange={handleChange} />
                                            </div>
                                            <div className="form-group col-md-3">
                                                <label htmlFor="price"><strong>{CacheExtension.getDataFromCache('Price')}</strong></label>
                                                <input value={form.price} className="form-control" type='number' name="price" onChange={handleChange} />
                                            </div>
                                        </div>
                                        <div className="form-group d-flex justify-content-between">
                                            <button className="w-25 btn btnBo mr-3" disabled={!form.date} onClick={async () => {
                                                await service.validateInvoice(product.product.key, form)
                                                window.location.reload()
                                            }}>{CacheExtension.getDataFromCache('Validate')}</button>
                                            <button className="w-25 btn btnBo" onClick={async () => {
                                                await service.refuseInvoice(product.product.key)
                                                window.location.reload()
                                            }}>{CacheExtension.getDataFromCache('Refuse')}</button>
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

ProductInvoiceModal.propTypes = {
    product : PropTypes.object.isRequired
}
