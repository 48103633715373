// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ReactCollapse--collapse {
    transition: height 500ms;
}
.chevron{
    transition: 0.5s ease-in-out;
}

.rotateUp {
    transform: rotate(180deg);
}

.collapse-container {
    border: 1px #DEDEDE solid;
    border-radius: 10px;
    margin: 10px 0px 10px 0px;
}


`, "",{"version":3,"sources":["webpack://./src/StarterKit/css/collapse.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;AAC5B;AACA;IACI,4BAA4B;AAChC;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;IACzB,mBAAmB;IACnB,yBAAyB;AAC7B","sourcesContent":[".ReactCollapse--collapse {\n    transition: height 500ms;\n}\n.chevron{\n    transition: 0.5s ease-in-out;\n}\n\n.rotateUp {\n    transform: rotate(180deg);\n}\n\n.collapse-container {\n    border: 1px #DEDEDE solid;\n    border-radius: 10px;\n    margin: 10px 0px 10px 0px;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
