import PropTypes from "prop-types";
import CacheExtension from "../../StarterKit/Filter/CacheExtension";
import React, {useEffect, useState,} from "react";
import ProductService from "../Services/Bll/ProductService";
import {Dropdown} from 'semantic-ui-react';
import {ImportComponent} from "../../StarterKit/Components/ImportComponent";
import FileModel from "../../StarterKit/Model/FileModel";
import ClientService from "../Services/Bll/ClientService";

export const ProductShopModal = (props) => {
    let currentDate = new Date().toISOString().split('T')[0];

    const initialForm = {
        name: '',
        lastname: '',
        phone: '',
        address: '',
        city: '',
        zipCode: '',
        country: '',
        navision: '',
        comment: '',
        date: currentDate,
        email: '',
        defaultLanguage: 'EN'
    };


    const service = new ProductService()
    const clientService = new ClientService();
    const [data, setData] = useState(null);
    const [open, setOpen] = useState(false);
    const [form, setForm] = useState(initialForm);
    const [selected, setSelected] = useState("");
    const [clientExist, setClientExist] = useState(false);
    const [step2Sell, setStep2Sell] = useState(false);
    const [invoiceFile, setInvoiceFile] = useState(null);
    const [loading, setLoading] = useState(false);



    $(function () {
        $('[data-toggle="tooltip"]').tooltip()
    })

    const handleClose = () => {
        setOpen(false);
        setForm(initialForm)
        setSelected("");
        setClientExist(false);
        setStep2Sell(false);
        setInvoiceFile(null);
    };

    const handleOpen = () => {
        service.listFromModel(props.model).then(function (result) {
            let res = result.getData();
            setData(res)
            setOpen(true);
        })
    };

    const handleChange = event => {
        if(event.target.name == "email") {
            setForm({
                ...form,
                [event.target.name]:event.target.value.toLowerCase()
            });
        } else {
            setForm({
                ...form,
                [event.target.name]:event.target.value
            });
        }
    }

    const validateMail = (email) => {
        if(email != '') {
            const emailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

            if (!emailRegex.test(email)) {
                return false
            }
        } else {
            return false
        }
        return true
    };

    function checkMail(email) {
        setLoading(true)
        clientService.checkMail(email).then((res) => {
            setClientExist(res.getData());
            setLoading(false)
        })
    }


    async function handleInvoice() {
        $("body").css('cursor','wait');
        var fileModel = new FileModel()
        var file = $('input[type="file"]')[0].files[0];
        if (file) {
            var fileType = file.type;
            var allowedTypes = ["image/png", "image/jpeg", "application/pdf", "image/jpg"];
            if (allowedTypes.includes(fileType)) {
                fileModel.title = file.name
                fileModel.file = file
                setInvoiceFile(fileModel)
                var reader = new FileReader();
                reader.onload = function(e) {
                    if (fileType === "application/pdf") {
                        $('#fileDisplayArea').html(`<embed src="${e.target.result}" width="50%" height="500" type="application/pdf">`);
                    } else {
                        $('#fileDisplayArea').html(`<img src="${e.target.result}" alt="Image" width="50%"/>`);
                    }
                }
                reader.readAsDataURL(file);
            } else {
                alert("Format de fichier non autorisé.");
            }
        }
        $("body").css('cursor','default');
    }

    return (
        <>
            <button disabled={data && data.length === 0} className="btn btnBo" onClick={handleOpen}>
                {CacheExtension.getDataFromCache('Sell')}
            </button>
            {open && (
                <div key={open} className="modal-container">
                    <div className="modal-overlay" onClick={handleClose}></div>
                    <div className="modal-dialog w-75" style={{maxWidth: "none", height: "90%"}}>
                        <div className="modal-content" style={{height: "100%"}}>
                            <div className="modal-header">
                                <h5 className="modal-title">{CacheExtension.getDataFromCache('ProductList')}</h5>
                                <button type="button" className="close" onClick={handleClose}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="fullSize modal-body">
                                {data &&
                                    <>
                                        <div className={'form-group'}>
                                            <label className={'mb-1'}><strong>* {CacheExtension.getDataFromCache("Serial")}</strong></label>
                                            <Dropdown
                                                style={{width: '200px'}}
                                                placeholder={CacheExtension.getDataFromCache('SelectSerial')}
                                                fluid
                                                search
                                                selection
                                                onChange={(e, {value}) => {
                                                    setSelected(value)
                                                }}
                                                options={data.map((product, i) =>({
                                                    key: i,
                                                    value: product.key,
                                                    text: product.serial
                                                }))}
                                            />
                                        </div>
                                        <>
                                            <div className={'filters d-flex align-items-center'}>
                                                <div className={'form-group mr-3'}>
                                                    <label htmlFor="email"><strong>* {CacheExtension.getDataFromCache('Email')}</strong></label>
                                                    <input type="text"
                                                           className="form-control"
                                                           name="email"
                                                           value={form.email}
                                                           disabled={step2Sell}
                                                           onChange={handleChange}/>
                                                </div>
                                                <button disabled={!validateMail(form.email) || !selected}  className={'btn btnBo mt-3 mr-3'} onClick={() => {
                                                    checkMail(form.email)
                                                    setStep2Sell(true)
                                                }}>{CacheExtension.getDataFromCache('CheckMail')}</button>
                                                <button disabled={!step2Sell}  className={'btn btnBo mt-3'} onClick={() => {
                                                    setForm(initialForm)
                                                    setStep2Sell(false)
                                                    setInvoiceFile(null)
                                                }}>{CacheExtension.getDataFromCache('ResetMail')}</button>
                                            </div>
                                            {step2Sell && clientExist.exist && !loading &&
                                                <div>
                                                    <div className="form-row">
                                                        <div className="form-group col-md-2">
                                                            <strong>{CacheExtension.getDataFromCache('ClientFound')}</strong>
                                                        </div>
                                                    </div>
                                                    <div className="form-row">
                                                        <div className="form-group col-md-2">
                                                            <label htmlFor="name">{CacheExtension.getDataFromCache('Firstname')}</label>
                                                            <div>{clientExist.name}</div>
                                                        </div>
                                                        <div className="form-group col-md-2">
                                                            <label htmlFor="lastname">{CacheExtension.getDataFromCache('Lastname')}</label>
                                                            <div>{clientExist.lastName}</div>
                                                        </div>
                                                    </div>
                                                    <div className="form-row">
                                                        <div className="form-group col-md-3">
                                                            <label htmlFor="name">{CacheExtension.getDataFromCache('Phone')}</label>
                                                            <div>{Object.keys(clientExist.phones).find(key => clientExist.phones[key] === 1) || ''}</div>
                                                        </div>
                                                    </div>
                                                    <div className="form-row">
                                                        <div className="form-group col-md-3">
                                                            <label htmlFor="name">{CacheExtension.getDataFromCache('Address')}</label>
                                                            <div>{Object.keys(clientExist.addresses).find(key => clientExist.addresses[key] === 1) || ''}</div>
                                                        </div>
                                                    </div>
                                                </div>

                                            }
                                            {step2Sell && !loading &&
                                                <div className={"mb-3"}>
                                                    <div className="form-row">
                                                        <div className="form-group col-md-2">
                                                            <label htmlFor="date"><strong>* {CacheExtension.getDataFromCache('SaleDate')}</strong></label>
                                                            <input value={form.date} className="form-control" type='date' name="date" onChange={handleChange} />
                                                        </div>
                                                    </div>
                                                    {!clientExist.exist &&
                                                        <div className="form-row">
                                                            <div className="form-group col-md-2">
                                                                <label htmlFor="date"><strong>* {CacheExtension.getDataFromCache('Language')}</strong></label>
                                                                <Dropdown
                                                                    defaultValue={'EN'}
                                                                    fluid
                                                                    selection
                                                                    onChange={async (e, {value}) => {
                                                                        setForm({
                                                                            ...form,
                                                                            defaultLanguage: value
                                                                        })
                                                                    }}
                                                                    options={[{value : 'FR', text: 'FR'}, {value : 'EN', text: 'EN'}]}
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                    <form

                                                        id={'_form'}
                                                        onChange={() => handleInvoice()}
                                                        action="">
                                                        <ImportComponent formName={CacheExtension.getDataFromCache("productInvoice.import")} className={'col-4'} form={false}/>
                                                    </form>
                                                    <div className="row">
                                                        <div className="col-4" id="fileDisplayArea"></div>
                                                    </div>
                                                </div>
                                            }

                                            {step2Sell && !clientExist.exist && !loading &&
                                                <>
                                                    <div>
                                                        <div className="form-row">
                                                            <div className="form-group col-md-3">
                                                                <label htmlFor="name">{CacheExtension.getDataFromCache('Firstname')}</label>
                                                                <input type="text"
                                                                       className="form-control"
                                                                       name="name"
                                                                       onChange={handleChange}/>
                                                            </div>
                                                            <div className="form-group col-md-3">
                                                                <label htmlFor="lastname">{CacheExtension.getDataFromCache('Lastname')}</label>
                                                                <input type="text"
                                                                       className="form-control"
                                                                       name="lastname"
                                                                       onChange={handleChange}/>
                                                            </div>
                                                        </div>
                                                        <div className={"form-row"}>
                                                            <div className="form-group col-md-3">
                                                                <label htmlFor="phone">{CacheExtension.getDataFromCache('Phone')}</label>
                                                                <input type="tel"
                                                                       className="form-control"
                                                                       name="phone"
                                                                       pattern="[0-9+]*"
                                                                       onKeyDown={(event) => {
                                                                           if (!/[0-9+]/.test(event.key) && ![ 'Backspace', 'Delete', 'Tab', 'ArrowLeft', 'ArrowRight' ].includes(event.key)) {
                                                                               event.preventDefault();
                                                                           }
                                                                       }}
                                                                       onChange={handleChange}/>
                                                            </div>
                                                        </div>
                                                        <div className="form-row">
                                                            <div className="form-group col-md-3">
                                                                <label htmlFor="address">{CacheExtension.getDataFromCache('Address')}</label>
                                                                <input type="text"
                                                                       className="form-control"
                                                                       name="address"
                                                                       onChange={handleChange}/>
                                                            </div>
                                                            <div className="form-group col-md-3">
                                                                <label htmlFor="city">{CacheExtension.getDataFromCache('City')}</label>
                                                                <input type="text"
                                                                       className="form-control"
                                                                       name="city"
                                                                       onChange={handleChange}/>
                                                            </div>
                                                        </div>
                                                        <div className="form-row">
                                                            <div className="form-group col-md-3">
                                                                <label htmlFor="zipCode">{CacheExtension.getDataFromCache('ZipCode')}</label>
                                                                <input type="text"
                                                                       className="form-control"
                                                                       name="zipCode"
                                                                       onChange={handleChange}/>
                                                            </div>
                                                            <div className="form-group col-md-3">
                                                                <label htmlFor="country">{CacheExtension.getDataFromCache('Country')}</label>
                                                                <input type="text"
                                                                       className="form-control"
                                                                       name="country"
                                                                       onChange={handleChange}/>
                                                            </div>
                                                        </div>
                                                        <div className="form-row">
                                                            <div className="form-group col-md-6">
                                                                <label htmlFor="comment">{CacheExtension.getDataFromCache('Comment')}</label>
                                                                <textarea
                                                                    className="form-control"
                                                                    name="comment"
                                                                    onChange={handleChange}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            }

                                            {step2Sell && !loading &&
                                                <div className="d-flex justify-content-end">
                                                    <button className="btn btnBo" disabled={!form.email || !form.date} onClick={async () => {
                                                        if (clientExist.exist) {
                                                            await service.sell(selected, form, invoiceFile);
                                                        } else {
                                                            await service.sellAndCreate(selected, form, invoiceFile);
                                                        }
                                                        window.location.reload();
                                                    }}>{CacheExtension.getDataFromCache('Validate')}</button>
                                                </div>
                                            }
                                        </>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

ProductShopModal.propTypes = {
    model : PropTypes.string.isRequired,
    icon : PropTypes.element.isRequired,
}
