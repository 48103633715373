import {BaseApiService} from "../../../StarterKit/Service/BaseApiService";
import configData from "../../../config.json";
import HelperService from "../../../StarterKit/Service/Bll/HelperService";
import { saveAs } from 'file-saver';


export default class ApiSealVectors extends BaseApiService {


    async import(file,type) {
        this.setPostMethod();
        this.setSecureByUser();
        this.callNeedFileInBody();
        this.setBody({Type : type});
        this.setFile(file);
        this.setShowReturn();
        this.setApiRoute(sessionStorage['language'] + "/sealVector/import");
        return await this.callApi();
    }
    async getNext(type){
        this.setGetMethod();
        this.setSecureByUser();
        this.setApiRoute(sessionStorage['language'] + "/sealVector/getNext/"+type);
        return await this.callApi();
    }

    async associateNext(product, type) {
        this.setGetMethod();
        this.setSecureByUser();
        this.setApiRoute(sessionStorage['language'] + "/sealVector/products/"+product+"/associateNext/"+type);
        return await this.callApi();

    }

    async readDxf(key, name) {
        var helperService = new HelperService();
        var token = this.helper.getToken()
        let response = await fetch(configData.SERVER_URL +sessionStorage['language'] + "/sealVector/" + key + "/toDxf",{
            headers: {
                'Accept': '*/*',
                'Content-Type': 'image/x-dxf',
                'Access-Control-Allow-Origin' : '*',
                "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
                "Access-Control-Allow-Headers": "Content-Type, Authorization, X-Requested-With",
                'Authorization': 'bearer '+ token
            },
            method : 'GET'
        })
        let blob = await response.blob();
        saveAs(blob, name)
    }


    async list(type){
        this.setGetMethod();
        this.setSecureByUser();
        this.setApiRoute(sessionStorage['language'] + "/sealVector/"+type);
        return await this.callApi();
    }

    async destroy(key){
        this.setPutMethod();
        this.setSecureByUser();
        this.setApiRoute(sessionStorage['language'] + "/sealVector/destroy/"+key);
        return await this.callApi();
    }
}
