import '../../styles/login.css';
import '../../styles/sk.css';
import '../../styles/groups.css';
import {motion as m} from "framer-motion"
import React, {useEffect, useState} from "react";
import { Nav } from "../../components/navComponents"
import { Header } from "../../components/headerComponent"
import CacheExtension from "../Filter/CacheExtension";
import Autoform from "../Autoform/Autoform";
import GroupService from "../Service/Bll/GroupService";
import {IconActionButton} from "../../App/Components/IconActionButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useNavigate} from "react-router-dom";
import SettingService from "../Service/Bll/SettingService";
import {createRoot} from "react-dom/client";
import {Dropdown, Input, Button} from 'semantic-ui-react'


export default function Groups() {

    const service = new GroupService();
    const settingService = new SettingService();

    const [groups, setGroups] = useState(null);
    const [originalGroups, setOriginalGroups] = useState(null);
    const [zones, setZones] = useState(null);
    const [orders, setOrders] = useState(null);
    const [filters, setFilters] = useState({
        key:null,
        readSecurityZone: null,
        writeSecurityZone: null
    });

    const navigate = useNavigate();

    async function fetchData() {
        let res = await service.list();
        setGroups(res);
        setOriginalGroups(res);
        let zoneRes = await settingService.getFromGroup("securityZone");
        let ordersRes = await settingService.getFromGroup("settingsOrder");
        setZones(zoneRes.getData());
        setOrders(ordersRes.getData());
    }

    useEffect(() => {
        fetchData()
    }, []);
    if (!groups) return  null
    let valueForm = groups.context['forms']
    const handleNav = (path) => {
        navigate(path)
    }
    const handleFilterChange = (key, value) => {
        setFilters(prevFilters => ({ ...prevFilters, [key]: value }));
        filter({ ...filters, [key]: value });
    };
    const filter = (updatedFilters) => {
        const filteredData = originalGroups.data.filter((item) => {
            return (
                (!updatedFilters.key ||
                    item.key.toLowerCase().includes(updatedFilters.key.toLowerCase())) &&
                (!updatedFilters.readSecurityZone ||
                    item.readSecurityZone === updatedFilters.readSecurityZone) &&
                (!updatedFilters.writeSecurityZone ||
                    item.writeSecurityZone === updatedFilters.writeSecurityZone)
            );
        });
        const updatedGroups = { ...originalGroups, data: filteredData };
        setGroups(updatedGroups);
    };

    async function updateGroup(key) {
        var container__modal = document.querySelector('.container__modal')
        var autoform = new Autoform([
            {
                arrayFinal :[key],
                submitCallback: async() => {
                    autoform.closeModal();
                    fetchData();
                },
                groupsForm: "",
                langForm: "",
                declaration: 'update',
                methodRoute: "put",
                route: "groups",
                keyName: 'update',
                data: valueForm.update,
                modale: true,
                name: 'groups',
                classInput: 'form-control',
                cancel: 'cancel',
                submit: 'submit',
                modaleHeader: "HeaderUpdateGroup",
                hidden: true
            }
        ], GroupService);
        const root = createRoot(container__modal);
        var a = root.render(autoform.render());
        setTimeout(() => {
            autoform.openModal(key,"")
        }, "100")
    }

    const deleteGroup = (key) => {
        var container__modal = document.querySelector('.container__modal')
        var autoform = new Autoform([
            {
                arrayFinal: [key],
                submitCallback: () => {
                    setGroups((prevGroups) => {
                        const updatedData = prevGroups.data.filter((obj) => obj.key !== key);
                        const updatedGroups = { ...prevGroups, data: updatedData };
                        return updatedGroups;
                    });
                    autoform.closeModal();
                },
                groupsForm: "",
                langForm: "",
                idModal: key,
                methodRoute: "delete",
                declaration: 'delete',
                route: `profiles`,
                keyName: 'delete ',
                data: {},
                modale: true,
                name: 'profile',
                classInput: 'form-control',
                cancel: 'cancel',
                submit: 'submit',
                modaleHeader: "Voulez vous vraiment delete",
                hidden: true
            }
        ], GroupService);
        const root = createRoot(container__modal);
        var a = root.render(autoform.render());
        setTimeout(() => {
            autoform.openModal(key, "");
        }, "100");
    };

    return (
        <>
            <m.div className={"wrapper"}>
                <Nav />
                <div id={"page"}>
                    <Header />
                        <div id="content">
                            <div className="card">
                                <div className="card-header">
                                    <h3>{CacheExtension.getDataFromCache("GroupsPageTitle")}</h3>
                                </div>
                                <div className="card-body container fullSize">
                                    <div className="row mt-2 pb-2">
                                        <div className="col-6">
                                            <div className="row h-50 align-items-center">
                                                <div className="col-6">
                                                    <strong>{CacheExtension.getDataFromCache("GroupsKey")}</strong>
                                                </div>
                                                <div className="col-6">
                                                    <strong>{CacheExtension.getDataFromCache("GroupsReadSecurityZone")}</strong>
                                                </div>
                                            </div>
                                            <div className="row h-50 align-items-center mt-2">
                                                <div className="offset-6 col-6">
                                                    <strong>{CacheExtension.getDataFromCache("GroupsWriteSecurityZone")}</strong>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-5">
                                            <div className="row h-50 align-items-center">
                                                <div className="col-4 text-center">
                                                    <strong>{CacheExtension.getDataFromCache("GroupsOneForAllLanguages")}</strong>
                                                </div>
                                                <div className="col-3 text-center">
                                                    <strong>{CacheExtension.getDataFromCache("GroupsNeedKey")}</strong>
                                                </div>
                                                <div className="col-5 text-center">
                                                    <strong>{CacheExtension.getDataFromCache("GroupsOrderBy")}</strong>
                                                </div>
                                            </div>
                                            <div className="row h-50 align-items-center mt-2">
                                                <div className="col-4 text-center">
                                                    <strong>{CacheExtension.getDataFromCache("GroupsCreate")}</strong>
                                                </div>
                                                <div className="col-3 text-center">
                                                    <strong>{CacheExtension.getDataFromCache("GroupsUpdate")}</strong>
                                                </div>
                                                <div className="col-5 text-center">
                                                    <strong>{CacheExtension.getDataFromCache("GroupsDelete")}</strong>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-1">
                                            &nbsp;
                                        </div>
                                    </div>

                                    <div className="row border-bottom mt-1">
                                        {zones &&
                                            <div className="col-6">
                                                <div className="row h-50 align-items-center">
                                                    <div className="col-6">
                                                            <Input
                                                                value={filters.key || ''}
                                                                onChange={(e, { value }) => handleFilterChange('key', value)}
                                                                placeholder={CacheExtension.getDataFromCache('GroupsSearchKey')}
                                                            />
                                                            <Button className="btnFilter" icon={<FontAwesomeIcon icon="fas fa-times" />} onClick={() => handleFilterChange('key', '')} />
                                                    </div>
                                                    <div className="col-6 d-flex">
                                                            <Dropdown
                                                                placeholder={CacheExtension.getDataFromCache('GroupsAllZone')}
                                                                fluid
                                                                search
                                                                selection
                                                                options={Object.keys(zones).map((key, i) => ({ key: i, value: key, text: zones[key].text }))}
                                                                onChange={(e, { value }) => handleFilterChange('readSecurityZone', value)}
                                                                style={{ maxWidth: '300px' }}
                                                                className={'mb-3'}
                                                            />
                                                            <Button icon={<FontAwesomeIcon icon="fas fa-times" />} onClick={() => handleFilterChange('readSecurityZone', '')} className="align-self-start btnFilter" />
                                                    </div>
                                                </div>
                                                <div className="row h-50 align-items-center mt-2">
                                                    <div className="offset-6 col-6 d-flex">
                                                            <Dropdown
                                                                placeholder={CacheExtension.getDataFromCache('GroupsAllZone')}
                                                                fluid
                                                                search
                                                                selection
                                                                options={Object.keys(zones).map((key, i) => ({ key: i, value: key, text: zones[key].text }))}
                                                                onChange={(e, { value }) => handleFilterChange('writeSecurityZone', value)}
                                                                style={{ maxWidth: '300px' }}
                                                                className={'mb-3'}
                                                            />
                                                            <Button icon={<FontAwesomeIcon icon="fas fa-times"/>} onClick={() => handleFilterChange('writeSecurityZone', '')} className="align-self-start btnFilter" />
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>

                                    {new Autoform([{submitCallback: () => fetchData(), declaration: 'add' , methodRoute:"post", route:"/groups",  keyName:'new', data: valueForm.new, modale:false, name:'group', classInput:'form-control'  }],GroupService ).render() }

                                    {Object.entries(groups.data).map((group,i) => {
                                        const background = i % 2 === 0 ? 'oddTableLine' : 'evenTableLine';
                                        return (
                                        <div key={i} className={"row pb-2 pt-2 align-items-center "+background}>
                                            <div className="col-6 pt-1 pb-1">
                                                <div className="row h-50 align-items-center">
                                                    <div className="col-6">
                                                        {group[1].count > 0 ?
                                                            <button
                                                                className="d-flex align-items-center btn actionLink"
                                                                onClick={() => handleNav('/admin_settings/' + group[1].key)}
                                                            >
                                                                <FontAwesomeIcon icon="fas fa-info-circle" />
                                                                <strong className="ml-3">{group[1].key}</strong>
                                                            </button> :
                                                            <button
                                                                className="d-flex align-items-center btn actionLink text-secondary"
                                                                onClick={() => handleNav('/admin_settings/' + group[1].key)}
                                                            >
                                                                <FontAwesomeIcon icon="fas fa-info-circle" />
                                                                <strong className="ml-3">{group[1].key}</strong>
                                                            </button>
                                                        }

                                                    </div>
                                                    <div className="col-6">
                                                        {zones && zones[group[1].readSecurityZone].text}
                                                    </div>
                                                </div>
                                                <div className="row h-50 align-items-center mt-2">
                                                    <div className="offset-6 col-6">
                                                        {zones && zones[group[1].writeSecurityZone].text}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-5">
                                                <div className="row h-50 align-items-center">
                                                    <div className="col-4 text-center">
                                                        {group[1].isSameForAllLanguages == 1 ?
                                                            <FontAwesomeIcon icon="fas fa-thumbs-up" className="text-success"/> :
                                                            <FontAwesomeIcon icon="fas fa-ban" className="text-danger"/>
                                                        }
                                                    </div>
                                                    <div className="col-3 text-center">
                                                        {group[1].hasAutomaticKey == 1 ?
                                                            <FontAwesomeIcon icon="fas fa-thumbs-up" className="text-success"/> :
                                                            <FontAwesomeIcon icon="fas fa-ban" className="text-danger"/>
                                                        }
                                                    </div>
                                                    <div className="col-5 text-center mt-n1">
                                                        {orders && orders[group[1].orderBy].text}
                                                    </div>
                                                </div>

                                                <div className="row h-50 align-items-center mt-2">
                                                    <div className="col-4 text-center">
                                                        {group[1].isCreatable == 1 ?
                                                            <FontAwesomeIcon icon="fas fa-thumbs-up" className="text-success"/> :
                                                            <FontAwesomeIcon icon="fas fa-ban" className="text-danger"/>
                                                        }
                                                    </div>
                                                    <div className="col-3 text-center">
                                                        {group[1].isUpdatable == 1 ?
                                                            <FontAwesomeIcon icon="fas fa-thumbs-up" className="text-success"/> :
                                                            <FontAwesomeIcon icon="fas fa-ban" className="text-danger"/>
                                                        }
                                                    </div>
                                                    <div className="col-5 text-center">
                                                        {group[1].isDeletable == 1 ?
                                                            <FontAwesomeIcon icon="fas fa-thumbs-up" className="text-success"/> :
                                                            <FontAwesomeIcon icon="fas fa-ban" className="text-danger"/>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-1 text-center">
                                                <div className="row">
                                                    <div className="col-6">
                                                        <IconActionButton action={() => updateGroup(group[1].key)} state={true} icon={<FontAwesomeIcon icon="fas fa-pencil-alt" title={CacheExtension.getDataFromCache('RightsUpdateProfileTooltip')}/>}/>

                                                    </div>
                                                    <div className="col-6">
                                                        {group[1].count == 0 &&
                                                            <IconActionButton action={() => deleteGroup(group[1].key)} state={true} icon={<FontAwesomeIcon icon="fas fa-trash-alt" title={CacheExtension.getDataFromCache('RightsDeleteProfileTooltip')}/>}/>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div> )
                                    })
                                    }
                                </div>
                            </div>
                        </div>
                </div>
            </m.div>
        </>
    );
}






