import {BaseApiService} from "../BaseApiService";
import ApiResult from "../../Model/ApiResult";
import Constants from "../../Constants";


/*******************************************************************
 * Diji Starter Kit
 *
 * ApiLogin
 *******************************************************************/
export default class ApiZones extends BaseApiService {
    /*******************************************************************
     * public function
     *******************************************************************/


    async getControllers(tier) {
        this.setGetMethod();
        this.setSecureByUser();
        this.setApiRoute(sessionStorage['language'] + "/securities/" + tier);
        return await this.callApi();
    }
    async addZone(tier, Zone) {
        this.setPostMethod();
        this.setSecureByUser();
        this.callNeedDataInBody();
        this.setBody(Zone);
        this.setApiRoute(sessionStorage['language'] + "/securities/" + tier);
        return await this.callApi();
    }

    /********************/
    async updateZone(tier, key, zone) {
        this.setPutMethod();
        this.setSecureByUser();
        this.callNeedDataInBody();
        this.setBody(zone);
        this.setApiRoute(sessionStorage['language'] + "/securities/" + tier + "/" + key);
        return await this.callApi();

    }


    async deleteZone(tier,key) {
        this.setDeleteMethod();
        this.setSecureByUser();
        this.setApiRoute(sessionStorage['language'] + "/securities/" +tier + "/" + key);
        return  await this.callApi();
    }
}
