import HelperService from "./HelperService";
import ApiGroups from "../Api/ApiGroups";

export default class GroupService {
    #helper;
    #api;

    constructor() {
        this.#api = new ApiGroups();
        this.#helper = new HelperService();
    }


    async list() {
        console.debug("[Diji SK] - Service - GroupService - list");
        const result = await this.#api.getGroups();
        return result;
    }

    async add(group) {
        console.debug("[Diji SK] - Service - GroupService - add");
        const result = await this.#api.addGroup(group);
        return result;
    }

    async update(key, group) {
        console.debug(`[Diji SK] - Service - GroupService - update ${key}`);
        const result = await this.#api.updateGroup(key, group);
        return result;
    }

    async delete(key) {
        console.debug(`[Diji SK] - Service - GroupService - delete ${key}`);
        const result = await this.#api.deleteGroup(key);
        return result;
    }
}
