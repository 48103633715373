import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export default class BooleanAutoform {
    #state;

    constructor(parameter) {
        this.#state = {
            mandatory: false,
            modale: parameter.modale,
            parameter: parameter,
            value: false
        };

        if (this.#state.keyform) {
            this.#state.mandatory = true;
        }
    }


    handleClick = (e) => {
        e.preventDefault();
        this.#state.value = !this.#state.value;

        const button = document.querySelector(
            "." +
            this.#state.parameter.parameterkeyForm +
            "-" +
            this.#state.parameter.parameterName +
            "-" +
            this.#state.parameter.parameterData
        );

        const newValue = this.#state.value ? "true" : "false";
        button.setAttribute("data-value", newValue);
        const trueIcon = button.querySelector(".trueIcon")
        const falseIcon = button.querySelector(".falseIcon")
        if (this.#state.value) {
            trueIcon.classList.remove("hidden");
            falseIcon.classList.add("hidden");
        } else {
            trueIcon.classList.add("hidden");
            falseIcon.classList.remove("hidden");
        }
    };


    check() {
        var checkValue = true;
        var returnTable = [];
        var arrayInput = {};
        var errorCode = "";
        var className =
            "." +
            this.#state.parameter.parameterkeyForm +
            "-" +
            this.#state.parameter.parameterName +
            "-" +
            this.#state.parameter.parameterData;

        var input = document.querySelector(className);
        let value = input.getAttribute("data-value");

        if (value != "false" && value != "true") {
            checkValue = false;
        }

        returnTable.push(
            checkValue,
            this.#state.parameter.parameterData,
            value,
            errorCode
        );
        return returnTable;
    }

    render() {
        return (
            <div
                key={Math.random()}
                className={`banner ${
                    this.#state.modale === true ? "form-group" : ""
                }`}
            >
                <label>
                    {"label-" +
                        this.#state.parameter.parameterkeyForm +
                        "-" +
                        this.#state.parameter.parameterName +
                        "-" +
                        this.#state.parameter.parameterData}
                </label>
                <button
                    onClick={this.handleClick}
                    className={
                        this.#state.parameter.parameterkeyForm +
                        "-" +
                        this.#state.parameter.parameterName +
                        "-" +
                        this.#state.parameter.parameterData +
                        " form-control btn actionLink switchBtn"
                    }
                    id={this.#state.parameter.parameterClass + '-' + this.#state.parameter.parameterData}
                    data-value={this.#state.value ? "true" : "false"}
                >
                    <FontAwesomeIcon
                        icon="fas fa-thumbs-up"
                        className={
                            "trueIcon text-success hidden"
                        }
                    />
                    <FontAwesomeIcon icon="fas fa-ban"
                                     className={
                                         "falseIcon text-danger"
                                     }
                    />
                </button>
            </div>
        );
    }
}