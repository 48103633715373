import CacheExtension from "../../StarterKit/Filter/CacheExtension";
import React, {useEffect, useState,} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconActionButton} from "./IconActionButton";

export const ModelModal = ({model}) => {

    const [open, setOpen] = useState(false)

    const [generic, setGeneric] = useState(null);
    const [technic, setTechnic] = useState(null);

    $(function () {
        $('[data-toggle="tooltip"]').tooltip()
    })

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        const genericKeys = ["hasDiamonds", "movement"];

        const technicKeys = [
            "boxMaterial",
            "boxDescription",
            "boxFloor",
            "boxDetail",
            "boxHeight",
            "movementDetail",
            "waterproof",
            "hasChrono",
            "hasMoonPhase"
        ];

        let entries = Object.entries(model);
        let genericArr = []
        let technicArr = []
        for (let [key, value] of entries) {
            if (genericKeys.includes(key)) {
                genericArr.push({ key: key, value: value });
            } else if (technicKeys.includes(key)) {
                technicArr.push({ key: key, value: value });
            }
        }
        setGeneric(genericArr)
        setTechnic(technicArr)
        setOpen(true);
    };

    return (
        <>
            <IconActionButton tooltip={model.title} action={handleOpen} state={true} className={"btn modelDetails"} icon={<FontAwesomeIcon icon="fas fa-circle-info" />}></IconActionButton>
            {open && (
                <div className="modal-container">
                    <div className="modal-overlay" onClick={handleClose}></div>
                    <div className="modal-dialog w-75" style={{maxWidth: "none", height: "90%"}}>
                        <div className="modal-content" style={{height: "100%"}}>
                            <div className="modal-header">
                                <h5 className="modal-title">{model.title}</h5>
                                <button type="button" className="close" onClick={handleClose}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="fullSize modal-body model-modal">
                                <div className={'productCardPicture'} style={{backgroundImage : `url(${model.image})`,mixBlendMode:'multiply', height:"200px" }}></div>
                                <span className={"py-4"} dangerouslySetInnerHTML={{ __html: model.edito }}></span>
                                {generic &&
                                    <div id="productDescriptionGenericBlock" >
                                    <span id="productDescriptionGenericTitle">
                                      {CacheExtension.getDataFromCache("productDescription.generic")}
                                    </span>
                                        {generic.map(attribute =>
                                                attribute.value !== '' &&
                                                <>
                                      <span id="productDescriptionGenericKey">
                                        {CacheExtension.getDataFromCache("product.key." + attribute.key)}
                                      </span>
                                                    {isNaN(attribute.value) ?
                                                        <span id="productDescriptionGenericValue" dangerouslySetInnerHTML={{ __html: attribute.value }}></span> :
                                                        <span id="productDescriptionGenericValue">{attribute.value === 1 ? CacheExtension.getDataFromCache("Yes") : CacheExtension.getDataFromCache("No")}</span>
                                                    }
                                                </>
                                        )}
                                    </div>
                                }
                                {Object.keys(model.optionsJson).length !== 0 &&
                                    <div id="productDescriptionOptionsBlock" >
                                      <span id="productDescriptionOptionsTitle">
                                        Options
                                      </span>
                                        {Object.entries(model.optionsJson).map(([key, value]) =>
                                            value !== '' && value !== 0 &&
                                            <>
                                                  <span id="productDescriptionGenericKey">
                                                    {key}
                                                  </span>
                                                {isNaN(value) ?
                                                    <span id="productDescriptionGenericValue" dangerouslySetInnerHTML={{ __html: value }}></span> :
                                                    <span id="productDescriptionGenericValue">{value === 1 ? CacheExtension.getDataFromCache("Yes") : CacheExtension.getDataFromCache("No")}</span>
                                                }
                                            </>
                                        )}
                                    </div>
                                }
                                {technic &&
                                    <div id="productDescriptionTechnicBlock">
                                     <span id="productDescriptionTechnicTitle">
                                        {CacheExtension.getDataFromCache("productDescription.technic")}
                                     </span>
                                        { technic.map(attribute =>
                                                attribute.value !== '' &&
                                                <>
                                    <span id="productDescriptionTechnicKey">
                                        {CacheExtension.getDataFromCache("product.key." + attribute.key)}
                                    </span>
                                                    {(isNaN(attribute.value) || attribute.key == 'waterproof') ?
                                                        <span id="productDescriptionTechnicValue" dangerouslySetInnerHTML={{ __html: attribute.value }}></span> :
                                                        <span id="productDescriptionTechnicValue">{attribute.value === 1 ? CacheExtension.getDataFromCache("Yes") : CacheExtension.getDataFromCache("No")}</span>
                                                    }
                                                </>
                                        )}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
