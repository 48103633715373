// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#header {
    padding-top: 10px;
    padding-bottom: 30px;
    width: 100%;
    max-width:100%;
    z-index:20;
}

.user {
    width: 300px;
    z-index:30;
    font-weight: bold;
    padding-top: 5px;
    padding-left: 20px;
    padding-bottom: 7px;

    background-color: var(--sk_nav);
    color: var(--sk_text_inverse);
}

#walletTitle {
    font-family: OpenSans;
    font-weight: 300;
    font-size:40px!important;
    color: var(--main-link-color);
    margin-top: 0;
    margin-bottom: 0;
    line-height: 60px;
}

.container__nav__bo .profilName {
    font-size: 20px!important;
}`, "",{"version":3,"sources":["webpack://./src/styles/header.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,oBAAoB;IACpB,WAAW;IACX,cAAc;IACd,UAAU;AACd;;AAEA;IACI,YAAY;IACZ,UAAU;IACV,iBAAiB;IACjB,gBAAgB;IAChB,kBAAkB;IAClB,mBAAmB;;IAEnB,+BAA+B;IAC/B,6BAA6B;AACjC;;AAEA;IACI,qBAAqB;IACrB,gBAAgB;IAChB,wBAAwB;IACxB,6BAA6B;IAC7B,aAAa;IACb,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":["#header {\n    padding-top: 10px;\n    padding-bottom: 30px;\n    width: 100%;\n    max-width:100%;\n    z-index:20;\n}\n\n.user {\n    width: 300px;\n    z-index:30;\n    font-weight: bold;\n    padding-top: 5px;\n    padding-left: 20px;\n    padding-bottom: 7px;\n\n    background-color: var(--sk_nav);\n    color: var(--sk_text_inverse);\n}\n\n#walletTitle {\n    font-family: OpenSans;\n    font-weight: 300;\n    font-size:40px!important;\n    color: var(--main-link-color);\n    margin-top: 0;\n    margin-bottom: 0;\n    line-height: 60px;\n}\n\n.container__nav__bo .profilName {\n    font-size: 20px!important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
