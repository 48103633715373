import CacheExtension from "../../StarterKit/Filter/CacheExtension";
import React, {useEffect, useState,} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconActionButton} from "./IconActionButton";
import ProductService from "../Services/Bll/ProductService";

export const SavDetailsModal = ({item}) => {
    const service = new ProductService();
    const initialForm = {
        comment : item.sav.comment
    }
    const [open, setOpen] = useState(false)
    const [edit, setEdit] = useState(false)
    const [form, setForm] = useState(initialForm)

    $(function () {
        $('[data-toggle="tooltip"]').tooltip()
    })

    const handleClose = () => {
        setOpen(false);
        setEdit(false);
        setForm(initialForm);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleChange = event => {
        setForm({
            ...form,
            [event.target.name]:event.target.value
        });
    }

    const handleSubmit = async () => {
        let res = await service.updateSav(item.sav.key, form);
        setEdit(false)
    };

    return (
        <>
            <IconActionButton tooltip={CacheExtension.getDataFromCache("Details")} action={handleOpen} state={true} className={"btn modelDetails"} icon={<FontAwesomeIcon icon="fas fa-circle-info" />}></IconActionButton>
            {open && item && (
                <div className="modal-container">
                    <div className="modal-overlay" onClick={handleClose}></div>
                    <div className="modal-dialog w-50" style={{maxWidth: "none", height: "90%"}}>
                        <div className="modal-content" style={{height: "100%"}}>
                            <div className="modal-header pb-0">
                                <h1 className="QrTitle mb-2">{CacheExtension.getDataFromCache("Details")}</h1>
                                <button type="button" className="close" onClick={handleClose}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            {item ?
                                <div className="fullSize modal-body">
                                    <div className={'form-group'}>
                                        <label className={'mb-1 productModalLabel'}>{CacheExtension.getDataFromCache("SavType")}</label>
                                        <p>{CacheExtension.getDataFromCache(item.sav.type)}</p>
                                    </div>
                                    <div className={'form-group'}>
                                        <label className={'mb-1 productModalLabel'}>{CacheExtension.getDataFromCache("AuthResult")}</label>
                                        <p>{CacheExtension.getDataFromCache(item.sav.authentication)}</p>
                                    </div>

                                    <div className={'form-group'}>
                                        <label className={'mb-1 productModalLabel'}>{CacheExtension.getDataFromCache("SealNumber")}</label>
                                        <p>{item.sealNumber}</p>
                                    </div>
                                    <div className={'form-group'}>
                                        <label className={'mb-1 productModalLabel'}>{CacheExtension.getDataFromCache("SealScore")}</label>
                                        <p>{item.sav.sealScore}</p>
                                    </div>
                                    <div className="form-group">
                                        <div className="d-flex">
                                            <label className={'mb-1 productModalLabel'}>{CacheExtension.getDataFromCache("Comment")}</label>

                                            <IconActionButton
                                                className={"btn modelDetails py-0"}
                                                action={() => {
                                                    setEdit(true)
                                                }}
                                                state={true}
                                                tooltip={CacheExtension.getDataFromCache('Edit')}
                                                icon={<FontAwesomeIcon icon="fas fa-pen"/>}
                                            />
                                            {edit &&
                                                <IconActionButton className={"btn modelDetails py-0"} action={() => handleSubmit()} state={true} icon={<FontAwesomeIcon icon="fas fa-check"/>}  tooltip={CacheExtension.getDataFromCache('Validate')}/>
                                            }
                                        </div>
                                        {edit ?
                                            <textarea type="text" name="comment" style={{width : '100%'}} className="form-control"
                                                      value={form.comment}
                                                      onChange={handleChange}
                                            /> :
                                            <p>{form.comment}</p>
                                        }
                                    </div>
                                </div> :
                                <p className="m-3">{CacheExtension.getDataFromCache('NoUser')}</p>
                            }
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
