import {BaseApiService} from "../../../StarterKit/Service/BaseApiService";

export default class ApiClient extends BaseApiService {
    async subscribe(data) {
        this.setPostMethod();
        this.setSecureByService();
        this.callNeedDataInBody();
        this.setBody(data);
        this.setApiRoute(sessionStorage['language'] + "/clients");
        return await this.callApi();
    }

    async read(key) {
        this.setGetMethod();
        this.setSecureByUser();
        this.setApiRoute(sessionStorage['language'] + "/clients/"+key);
        return await this.callApi();
    }

    async update(key, data){
        this.setPutMethod();
        this.setSecureByUser();
        this.callNeedDataInBody();
        this.setBody(data);
        this.setApiRoute(sessionStorage['language'] + "/clients/"+key);
        return await this.callApi();
    }

    async anonymize(key){
        this.setPutMethod();
        this.setSecureByUser();
        this.setApiRoute(sessionStorage['language'] + "/clients/anonymize/"+key);
        return await this.callApi();
    }

    async delete(key){
        this.setPutMethod();
        this.setSecureByUser();
        this.setApiRoute(sessionStorage['language'] + "/clients/delete/"+key);
        return await this.callApi();
    }

    async checkMail(mail) {
        this.setGetMethod();
        this.setSecureByUser();
        this.setApiRoute(sessionStorage['language'] + "/clients/checkMail/"+mail);
        return await this.callApi();
    }

}