import HelperService from "../../../StarterKit/Service/Bll/HelperService";
import ApiProduct from "../Api/ApiProduct";

export default class ProductService {
    #helper;
    #api;

    constructor() {
        this.#api = new ApiProduct();
        this.#helper = new HelperService();
    }


    async destroyed(key) {
        const result = await this.#api.destroy(key);
        return result;
    }

    async received(key) {
        var shop = sessionStorage['firstname']
        if (shop){
            const result = await this.#api.received(shop,key);
            return result;
        }
    }

    async lost(key) {
        const result = await this.#api.lost(key);
        return result;
    }

    async send(key,shop) {
        const result = await this.#api.send(key,shop);
        return result;
    }

    async stolen(key) {
        const result = await this.#api.stolen(key);
        return result;
    }

    async sell(product, form, file = null) {
        const result = await this.#api.sell(product, form, file);
        return result;
    }

    async sellAndCreate(product, form, file = null) {
        const result = await this.#api.sellAndCreate(product, form, file);
        return result;
    }

    async read(gtin, serial){
        const result = await this.#api.read(gtin, serial);
        return result;
    }

    async listFromModel(key){
        const result = await this.#api.listFromModel(key);
        return result;
    }

    async listState(state){
        const result = await this.#api.listState(state);
        return result;
    }

    async listInvoices(state, page, skuSearch, variantIdSearch, serialSearch, orderField, orderDirection){
        const result = await this.#api.listInvoices(state, page, skuSearch, variantIdSearch, serialSearch, orderField, orderDirection);
        return result;
    }

    async getToken(seal,sku,product){
        const result = await this.#api.getToken(seal,sku,product);
        return result;
    }

    async validate(product) {
        const result = await this.#api.validate(product);
        return result;
    }

    async validateInvoice(product, form) {
        const result = await this.#api.validateInvoice(product, form);
        return result;
    }

    async refuseInvoice(product) {
        const result = await this.#api.refuseInvoice(product);
        return result;
    }

    async updateWarrantyDates(product, form) {
        const result = await this.#api.updateWarrantyDates(product, form);
        return result;
    }

    async linkToModel(product, model) {
        const result = await this.#api.linkToModel(product, model);
        return result;
    }

    async listAll(page, skuSearch, variantIdSearch, serialSearch, orderField, orderDirection){
        const result = await this.#api.listAll(page, skuSearch, variantIdSearch, serialSearch, orderField, orderDirection);
        return result;
    }

    async listAllSav(page, skuSearch, variantIdSearch, serialSearch, orderField, orderDirection){
        const result = await this.#api.listAllSav(page, skuSearch, variantIdSearch, serialSearch, orderField, orderDirection);
        return result;
    }

    async addSav(product, form){
        const result = await this.#api.addSav(product, form);
        return result;
    }

    async closeSav(sav, form){
        const result = await this.#api.closeSav(sav, form);
        return result;
    }

    async checkModelSerialExist(sku, serial){
        const result = await this.#api.checkModelSerialExist(sku, serial);
        return result;
    }

    async checkSealNumber(product, sealNumber){
        const result = await this.#api.checkSealNumber(product, sealNumber);
        return result;
    }

    async updateSav(sav, form){
        const result = await this.#api.updateSav(sav, form);
        return result;
    }

    async listSavForProduct(product){
        const result = await this.#api.listSavForProduct(product);
        return result;
    }

    async updateShop(product, shop){
        const result = await this.#api.updateShop(product, shop);
        return result;
    }

    async removeSerial(key){
        const result = await this.#api.removeSerial(key);
        return result;
    }

    async removeFromShop(key){
        const result = await this.#api.removeFromShop(key);
        return result;
    }

    async updateLinkedModel(product, model){
        const result = await this.#api.updateLinkedModel(product, model);
        return result;
    }

    async importInvoice(file,product) {
        const result = await this.#api.importInvoice(file,product);
        return result;
    }

    async listAllKey(){
        const result = await this.#api.listAllKey();
        return result;
    }

    async regenerateToken(product) {
        const result = await this.#api.regenerateToken(product);
        return result;
    }
}
