// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
    background-color: var(--sk_background);
    font-size:0.9em;
    overflow: hidden;
    color: var(--sk_text);

}

h1 {
    font-weight:bold;
    text-align:center;
}

p {
    font-size: 1.1em;
    font-weight: 300;
    line-height: 1.7em;
}

a,
a:hover,
a:focus {
    color: inherit;
    text-decoration: none;
}

i,
span {
    display: inline-block;
}


a.labelDebug {
    cursor: pointer;
    font-weight: bold;
    text-decoration: underline !important;
}

.new-profile label {
    display:none;
}`, "",{"version":3,"sources":["webpack://./src/styles/rights.css"],"names":[],"mappings":"AAAA;IACI,sCAAsC;IACtC,eAAe;IACf,gBAAgB;IAChB,qBAAqB;;AAEzB;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;;;IAGI,cAAc;IACd,qBAAqB;AACzB;;AAEA;;IAEI,qBAAqB;AACzB;;;AAGA;IACI,eAAe;IACf,iBAAiB;IACjB,qCAAqC;AACzC;;AAEA;IACI,YAAY;AAChB","sourcesContent":["body {\n    background-color: var(--sk_background);\n    font-size:0.9em;\n    overflow: hidden;\n    color: var(--sk_text);\n\n}\n\nh1 {\n    font-weight:bold;\n    text-align:center;\n}\n\np {\n    font-size: 1.1em;\n    font-weight: 300;\n    line-height: 1.7em;\n}\n\na,\na:hover,\na:focus {\n    color: inherit;\n    text-decoration: none;\n}\n\ni,\nspan {\n    display: inline-block;\n}\n\n\na.labelDebug {\n    cursor: pointer;\n    font-weight: bold;\n    text-decoration: underline !important;\n}\n\n.new-profile label {\n    display:none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
