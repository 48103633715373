import Autoform from "../Autoform";
import CacheExtension from "../../Filter/CacheExtension";
class StringAutoform{
    #state
    constructor(parameter) {
        this.#state = {
            mandatory : false,
            modale : parameter.modale,
            parameter : parameter
        }
        if (this.#state.keyform) {
            this.#state.mandatory = true
        }
    }

    check() {
        var checkValue = true
        var errorCode = ""
        var arrayInput = {}
        var returnTable = []
        var className = "." +  this.#state.parameter.parameterkeyForm + "-" + this.#state.parameter.parameterName + "-"+this.#state.parameter.parameterData
        var input = document.querySelector(className)
        if (input.value === "" &&  this.#state.mandatory === true) {
            checkValue = false
            if (this.#state.parameter.parameterData === "name") {
                errorCode = -1072
            }
            if (this.#state.parameter.parameterData === "lastname") {
                errorCode = -1071
            }
            if (this.#state.parameter.parameterData === "phoneNumber") {
                errorCode = -1080
            }
        }
        if (this.#state.parameter.parameterData === "phoneNumber" && input.value !== "")
        if (input.value.trim() === "") {
            checkValue = false
        }
        arrayInput[input.name] = input.value

        returnTable.push(checkValue,input.name,input.value,errorCode)

        return returnTable
    }

    render() {
        return(
            <div key={Math.random()} className={`banner ${ this.#state.modale == true ? "form-group" : ""}`}>
                <label>{CacheExtension.getDataFromCache('label-' + this.#state.parameter.parameterkeyForm + "-" + this.#state.parameter.parameterName + "-"+this.#state.parameter.parameterData)}</label>
                <input required={this.#state.mandatory}
                       placeholder={CacheExtension.getDataFromCache('placeholder-' + this.#state.parameter.parameterkeyForm + "-" + this.#state.parameter.parameterName + "-"+this.#state.parameter.parameterData)}
                       name={this.#state.parameter.parameterData}
                       className={this.#state.parameter.parameterkeyForm + "-" + this.#state.parameter.parameterName + "-"+this.#state.parameter.parameterData + " form-control"} id={this.#state.parameter.parameterClass + '-' + this.#state.parameter.parameterData} type="text"/>
            </div>
        )
    }
}

export default StringAutoform