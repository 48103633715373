import HelperService from "../../../StarterKit/Service/Bll/HelperService";
import ApiSealVectors from "../Api/ApiSealVectors";

export default class SealVectorService {
    #helper;
    #api;

    constructor() {
        this.#api = new ApiSealVectors();
        this.#helper = new HelperService();
    }


    async import(file,type) {
        const result = await this.#api.import(file,type);
        return result;
    }

    async readDxf(key, name) {
        await this.#api.readDxf(key, name);
    }

    async associateNext(product, type) {
        const result = await this.#api.associateNext(product, type);
        return result;
    }

    async destroy(key) {
        const result = await this.#api.destroy(key);
        return result;
    }

    async list(type) {
        const result = await this.#api.list(type);
        return result;
    }
}
