import '../../styles/login.css';
import '../../styles/sk.css';
import '../../styles/register.css';
import {motion as m} from "framer-motion"
import React, {useEffect, useState} from "react";
import { Nav } from "../../components/navComponents"
import { Header } from "../../components/headerComponent"
import Consts from "../../App/Consts";

export default function HomePage() {

    var profile = sessionStorage['profile']
    var url = JSON.parse(localStorage.getItem(Consts.GROUP_HOME));
    if (url) {
        if (url[profile] === undefined) {
            window.location.href = '/login'
        }
    }
    return (
        <>
            <m.div className={"wrapper"}>
                <Nav />
                <div id={"page"}>
                    <Header />
                    <div id="content">
                        Bienvenue
                    </div>
                </div>

            </m.div>
        </>
    );
}






