import React, {useEffect, useState} from "react";
import ModelService from "../Services/Bll/ModelService";
import {motion as m} from "framer-motion";
import {ProductCardDetail} from "../Components/ProductCardDetail";
import CacheExtension from "../../StarterKit/Filter/CacheExtension";
import {Dropdown} from 'semantic-ui-react';
import {HeaderComponent} from "../Components/HeaderComponent";
import 'semantic-ui-css/semantic.min.css'
import '../css/bo.css'
import SettingService from "../../StarterKit/Service/Bll/SettingService";
import ShopService from "../Services/Bll/ShopService";
import ProductService from "../Services/Bll/ProductService";
import {ConfirmModalComponent} from "../../StarterKit/Components/ConfirmModalComponent";
import {Link} from "react-router-dom";
import ClientService from "../Services/Bll/ClientService";
import {ImportComponent} from "../../StarterKit/Components/ImportComponent";
import FileModel from "../../StarterKit/Model/FileModel";


export default function StockGestion() {
    let currentDate = new Date().toISOString().split('T')[0];

    const initialForm = {
        name: '',
        lastname: '',
        phone: '',
        address: '',
        city: '',
        zipCode: '',
        country: '',
        navision: '',
        comment: '',
        date: currentDate,
        email: '',
        defaultLanguage: 'EN'
    };

    const settingService = new SettingService();
    const shopService = new ShopService();
    const productService = new ProductService();
    const clientService = new ClientService();
    const service = new ModelService();
    const [data, setData] = useState(null);
    const [shopsList, setShopsList] = useState(null);
    const [post, setPost] = useState(null);
    const [context, setContext] = useState('');

    const [models, setModels] = useState(null);

    const [SKU, setSKU] = useState('');
    const [serial, setSerial] = useState('');
    const [selected, setSelected] = useState('');
    const [shop, setShop] = useState('');
    const [alreadyLinked, setAlreadyLinked] = useState('');
    const [linked, setLinked] = useState(false);
    const [sendingType, setSendingType] = useState('shop');
    const [form, setForm] = useState(initialForm);
    const [clientExist, setClientExist] = useState(false);
    const [step2Sell, setStep2Sell] = useState(false);
    const [invoiceFile, setInvoiceFile] = useState(null);
    const [lastStep, setLastStep] = useState(false);
    const [loading, setLoading] = useState(false);
    const [updateVariant, setUpdateVariant] = useState(false);

    useEffect(() => {

        service.listSkus().then(function (result) {
            let res = result.getData();
            setData(res)
        })

        settingService.getFromGroup('productType').then(function (result) {
            setPost(Object.keys(result.getData()))
        })

        shopService.listAll().then(function (result) {
            var res = {}
            for (const country in result.getData()) {
                for (const state in result.getData()[country]) {
                    result.getData()[country][state].forEach(function (e) {
                        res[e['key']] = e['name']
                    })
                }

            }
            res = Object.fromEntries(Object.entries(res).sort((a, b) => a[1].localeCompare(b[1])));
            setShopsList(res)
        })
    }, [])

    const CreateGuid = () => {
        function _p8(s) {
            var p = (Math.random().toString(16) + "000000000").substr(2, 8);
            return s ? "-" + p.substr(0, 4) + "-" + p.substr(4, 4) : p;
        }

        return _p8() + _p8(true) + _p8(true) + _p8();
    }

    if (context) {
        var forms = context.context.forms
    }


    const handleChange = event => {
        if(event.target.name == "email") {
            setForm({
                ...form,
                [event.target.name]:event.target.value.toLowerCase()
            });
        } else {
            setForm({
                ...form,
                [event.target.name]:event.target.value
            });
        }
    }
    const validateMail = (email) => {
        if(email != '') {
            const emailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

            if (!emailRegex.test(email)) {
                return false
            }
        } else {
            return false
        }
        return true
    };

    function checkMail(email) {
        setLoading(true);
        clientService.checkMail(email).then((res) => {
            setClientExist(res.getData());
            setLoading(false);
        })
    }

    async function handleInvoice() {
        $("body").css('cursor','wait');
        var fileModel = new FileModel()
        var file = $('input[type="file"]')[0].files[0];
        if (file) {
            var fileType = file.type;
            var allowedTypes = ["image/png", "image/jpeg", "application/pdf", "image/jpg"];
            if (allowedTypes.includes(fileType)) {
                fileModel.title = file.name
                fileModel.file = file
                setInvoiceFile(fileModel)
                var reader = new FileReader();
                reader.onload = function(e) {
                    if (fileType === "application/pdf") {
                        $('#fileDisplayArea').html(`<embed src="${e.target.result}" width="50%" height="500" type="application/pdf">`);
                    } else {
                        $('#fileDisplayArea').html(`<img src="${e.target.result}" alt="Image" width="50%"/>`);
                    }
                }
                reader.readAsDataURL(file);
            } else {
                alert("Format de fichier non autorisé.");
            }
        }
        $("body").css('cursor','default');
    }


    function modalHtml(sku, serial, shop = null) {
        return (
            <div className="mb-5">
                {shop ?
                    <div className="mb-3">
                        <strong>{CacheExtension.getDataFromCache("SendShopModalText")}</strong>
                    </div> :
                    <div className="mb-3">
                        <strong>{CacheExtension.getDataFromCache("SellModalText")}</strong>
                    </div>
                }
                <div className="mb-2">
                    <strong>{CacheExtension.getDataFromCache("SKU")}</strong>{` ${sku}`}
                </div>
                <div className="mb-2">
                    <strong>{CacheExtension.getDataFromCache("Serial")}</strong>{` ${serial}`}
                </div>
                {shop ?
                    <div className="mb-2">
                        <strong>{CacheExtension.getDataFromCache("Shop")}</strong>{` ${shop}`}
                    </div> :
                    <>
                        <div className="mb-2">
                            <strong>{CacheExtension.getDataFromCache("Email")}</strong>{` ${form.email}`}
                        </div>
                        {form.date &&
                            <div className="mb-2">
                                <strong>{CacheExtension.getDataFromCache("SaleDate")}</strong>{` ${form.date}`}
                            </div>
                        }
                        {form.defaultLanguage && !clientExist.exist &&
                            <div className="mb-2">
                                <strong>{CacheExtension.getDataFromCache("Language")}</strong>{` ${form.defaultLanguage}`}
                            </div>
                        }
                        {form.name &&
                            <div className="mb-2">
                                <strong>{CacheExtension.getDataFromCache("Name")}</strong>{` ${form.name}`}
                            </div>
                        }
                        {form.lastname &&
                            <div className="mb-2">
                                <strong>{CacheExtension.getDataFromCache("Lastname")}</strong>{` ${form.lastname}`}
                            </div>
                        }
                        {form.phone &&
                            <div className="mb-2">
                                <strong>{CacheExtension.getDataFromCache("Phone")}</strong>{` ${form.phone}`}
                            </div>
                        }
                        {(form.address || form.zipCode || form.city || form.country) &&
                            <div className="mb-2">
                                <strong>{CacheExtension.getDataFromCache("Address")}</strong>{` ${form.address} ${form.zipCode} ${form.city} ${form.country}`}
                            </div>
                        }
                        {form.navision &&
                            <div className="mb-2">
                                <strong>Navision</strong>{` ${form.navision}`}
                            </div>
                        }
                        {form.comment &&
                            <div className="mb-2">
                                <strong>{CacheExtension.getDataFromCache("Comment")}</strong>{` ${form.comment}`}
                            </div>
                        }
                    </>
                }

            </div>
        )
    }

    return (
        <>
            {data && shopsList &&
                <m.div className={"wrapper"}>

                    <div id={"page"}>
                        <HeaderComponent logo={'/pictures/Logo_Pequignet.png'}
                                         title={CacheExtension.getDataFromCache('Stock').replace('%%1%%', sessionStorage['lastname'])}></HeaderComponent>
                        <div id="content">
                            <div id={"page"}>
                                <div className="d-flex justify-content-end">
                                    <div className={"d-flex flex-column"}>
                                        <Link className="btn btnBo mb-2" to="/models">
                                            {CacheExtension.getDataFromCache('Models')}
                                        </Link>
                                        <Link className="btn btnBo mb-2" to="/products">
                                            {CacheExtension.getDataFromCache('Instances')}
                                        </Link>
                                        {/*<InTransitModalComponent/>*/}
                                    </div>
                                </div>
                                <div className={'stockSection'}>
                                    <span
                                        className={"step"}>{CacheExtension.getDataFromCache('Step').replace('%%1%%', CacheExtension.getDataFromCache('stepGestion1'))}</span>
                                    <div className={'stockSectionContent justify-content-start'}>
                                        {Object.keys(data).length > 0 ?
                                            <div className={'filters w-100 d-flex align-items-center'}>
                                                <div className={'form-group col-2 mr-1'}>
                                                    <p className={'mb-1'}>{CacheExtension.getDataFromCache("SKU")}</p>
                                                    <Dropdown
                                                        placeholder={CacheExtension.getDataFromCache('SelectSKU')}
                                                        fluid
                                                        search
                                                        selection
                                                        onChange={async (e, {value}) => {
                                                            const selected = Object.entries(data).find(entry => entry[0] === value);
                                                            if(selected) {
                                                                setSKU(selected)
                                                                const res = await service.listFromSku(encodeURIComponent(selected[0]))
                                                                setModels(res.getData())
                                                            } else {
                                                                setSKU("")
                                                                setModels(null)
                                                            }
                                                            setSerial('');
                                                            setSelected('');
                                                            setShop('');
                                                            setAlreadyLinked('')
                                                            setLinked(false)
                                                            setForm(initialForm)
                                                            setClientExist(false);
                                                            setStep2Sell(false);
                                                            setInvoiceFile(null);
                                                            setLastStep(false);
                                                            setSendingType('shop');
                                                            setUpdateVariant(false);
                                                            $('.ProductCardContainer').removeClass('selectedModel')
                                                        }}
                                                        options={[
                                                            { key: 'none', value: '', text: CacheExtension.getDataFromCache('SelectSKU') },
                                                            ...Object.entries(data).map((value, i) => ({
                                                                key: i,
                                                                value: value[0],
                                                                text: value[0]
                                                            }))
                                                        ]}
                                                    />
                                                </div>
                                                <div className={'form-group mr-3'}>
                                                    <p className={'mb-1'}>{CacheExtension.getDataFromCache("Serial")}</p>
                                                    <Dropdown
                                                        key={SKU}
                                                        style={{width: '200px'}}
                                                        placeholder={CacheExtension.getDataFromCache('SelectSerial')}
                                                        fluid
                                                        search
                                                        selection
                                                        disabled={!SKU[1] || Object.entries(SKU[1]).length === 0}
                                                        onChange={(e, {value}) => {
                                                            const selected = Object.entries(SKU[1].serials).find(entry => entry[0] === value);
                                                            if (selected) {
                                                                setSerial(selected);
                                                                setAlreadyLinked('')
                                                                setLinked(false)
                                                                if(selected[1].modelKey) {
                                                                    setAlreadyLinked(models.find(item => item.key === selected[1].modelKey))
                                                                    setLinked(true)
                                                                }
                                                            } else {
                                                                setSerial('');
                                                                setAlreadyLinked('')
                                                                setLinked(false)
                                                            }
                                                            setSelected('');
                                                            setShop('');
                                                            setForm(initialForm)
                                                            setClientExist(false);
                                                            setStep2Sell(false);
                                                            setInvoiceFile(null);
                                                            setLastStep(false);
                                                            setSendingType('shop');
                                                            setUpdateVariant(false);
                                                            $('.ProductCardContainer').removeClass('selectedModel')
                                                        }}
                                                        options={[
                                                            { key: 'none', value: '', text: CacheExtension.getDataFromCache('SelectSerial') },
                                                            ...(SKU[1] && Object.entries(SKU[1].serials).length > 0 ? Object.entries(SKU[1].serials).map((value, i) => ({
                                                                key: i,
                                                                value: value[0],
                                                                text: value[0]
                                                            })) : [])
                                                        ]}
                                                    />
                                                </div>
                                            </div> :
                                            <div className="my-2">{CacheExtension.getDataFromCache('NoProducts')}</div>
                                        }
                                    </div>
                                </div>

                                <div className={SKU ? 'stockSection' : 'stockSection disabledSection'}>
                                    <span className={"step"}>{CacheExtension.getDataFromCache('Step').replace('%%1%%',CacheExtension.getDataFromCache('stepGestion2'))}</span>
                                    <div className={'my-2 container-fluid justify-content-center'}>
                                        {models && SKU &&
                                         <>
                                             <div className={'justify-content-center row text-center mb-2'}>
                                                 {!updateVariant &&
                                                    <>
                                                        {alreadyLinked ?
                                                            <div style={{maxWidth:"none"}} key={alreadyLinked.key} className={'selectedModel col-3'}>
                                                                <ProductCardDetail key={alreadyLinked.key} sku={SKU[0]} data={alreadyLinked} buttons={true}></ProductCardDetail>
                                                            </div> :
                                                            <>
                                                                {models.map((model,index) => {
                                                                    return (
                                                                        <div key={model.key} style={{maxWidth:"none"}} className={'ProductCardContainer col-3'} onClick={function (e) {
                                                                            if(serial && !linked) {
                                                                                setSelected(model)
                                                                                $('.ProductCardContainer').removeClass('selectedModel')
                                                                                $(e.target).closest('.ProductCardContainer').addClass('selectedModel')
                                                                            }
                                                                        }}>
                                                                            <ProductCardDetail key={model.key} sku={SKU[0]} data={model} buttons={true}></ProductCardDetail>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </>
                                                        }
                                                    </>
                                                 }
                                             </div>
                                             {(serial && !alreadyLinked && !updateVariant) &&
                                                 <div className="row d-flex justify-content-end">
                                                     <button disabled={!selected || linked} className={'btn btnBo'} onClick={async function () {
                                                         setData(prevData => {
                                                             let newData = {...prevData};
                                                             if(newData[SKU[0]] && newData[SKU[0]].serials[serial[0]]) {
                                                                 newData[SKU[0]].serials[serial[0]].modelKey = selected.key;
                                                             }
                                                             return newData;
                                                         });
                                                         await productService.linkToModel(serial[1].key, selected.key)
                                                         setLinked(true)
                                                     }}>{CacheExtension.getDataFromCache('Validate')}</button>
                                                 </div>

                                             }
                                             {updateVariant &&
                                                <>
                                                    <div className={'justify-content-center row text-center mb-2'}>
                                                        {models.map((model,index) => {
                                                            return (
                                                                <div key={model.key} style={{maxWidth:"none"}} className={'ProductCardContainer col-3'} onClick={function (e) {
                                                                    if(serial && !linked) {
                                                                        setSelected(model)
                                                                        $('.ProductCardContainer').removeClass('selectedModel')
                                                                        $(e.target).closest('.ProductCardContainer').addClass('selectedModel')
                                                                    }
                                                                }}>
                                                                    <ProductCardDetail key={model.key} sku={SKU[0]} data={model} buttons={true}></ProductCardDetail>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                    <div className="row d-flex justify-content-end">
                                                        <button disabled={!selected || linked} className={'btn btnBo'} onClick={async function () {
                                                            setData(prevData => {
                                                                let newData = {...prevData};
                                                                if(newData[SKU[0]] && newData[SKU[0]].serials[serial[0]]) {
                                                                    newData[SKU[0]].serials[serial[0]].modelKey = selected.key;
                                                                }
                                                                return newData;
                                                            });
                                                            await productService.updateLinkedModel(serial[1].key, selected.key)
                                                            setLinked(true)
                                                        }}>{CacheExtension.getDataFromCache('Validate')}</button>
                                                    </div>
                                                </>
                                             }
                                         </>
                                        }
                                    </div>
                                </div>

                                <div className={linked ? 'stockSection' : 'stockSection disabledSection'}>
                                    <span className={"step"}>{CacheExtension.getDataFromCache('Step').replace('%%1%%',CacheExtension.getDataFromCache('stepGestion3'))}</span>
                                    <div className={'my-2 container-fluid'}>
                                        <div className="mb-4">
                                            <button disabled={!linked} className={'btn btnBo'} onClick={async function () {
                                                setShop('')
                                                setLastStep(false)
                                                setLinked(false)
                                                setUpdateVariant(true)
                                            }}>{CacheExtension.getDataFromCache('UpdateVariant')}</button>
                                        </div>
                                        <div className="mb-2">
                                            <div className="form-group">
                                                <div className="custom-control custom-radio custom-control-inline">
                                                    <input
                                                        className="custom-control-input"
                                                        type="radio"
                                                        name="options"
                                                        id="shop"
                                                        value="shop"
                                                        disabled={!linked}
                                                        checked={sendingType === 'shop'}
                                                        onChange={() => {
                                                            setShop('')
                                                            setForm(initialForm)
                                                            setStep2Sell(false)
                                                            setLastStep(false)
                                                            setSendingType('shop')
                                                        }}
                                                    />
                                                    <label className="custom-control-label" htmlFor="shop">{CacheExtension.getDataFromCache('Shop')}</label>
                                                </div>
                                                <div className="custom-control custom-radio custom-control-inline">
                                                    <input
                                                        className="custom-control-input"
                                                        type="radio"
                                                        name="options"
                                                        id="eshop"
                                                        value="eshop"
                                                        disabled={!linked}
                                                        checked={sendingType === 'eshop'}
                                                        onChange={() => {
                                                            setShop('')
                                                            setForm({
                                                                ...initialForm,
                                                                saleType: 'eshop'
                                                            })
                                                            setStep2Sell(false)
                                                            setLastStep(false)
                                                            setSendingType('eshop')
                                                        }}
                                                    />
                                                    <label className="custom-control-label" htmlFor="eshop">E-shop</label>
                                                </div>
                                            </div>

                                        </div>
                                            {sendingType ==='shop' &&
                                                <div className={'text-center mb-2'}>
                                                    <Dropdown
                                                        placeholder={CacheExtension.getDataFromCache('SelectShop')}
                                                        fluid
                                                        id={CreateGuid()}
                                                        search
                                                        selection
                                                        disabled={!linked}
                                                        options={Object.entries(shopsList).map((shop, i) => ({key: shop[0], value: shop[0], text: shop[1] }))}
                                                        onChange={(e, {value}) => {
                                                            const selected = Object.entries(shopsList).find(entry => entry[0] === value);
                                                            setShop(selected)
                                                        }}
                                                        style={{maxWidth : '300px'}} className={'mb-3'}/>
                                                </div>
                                            }
                                            {sendingType ==='eshop' &&
                                                <>
                                                    <div className={'filters d-flex align-items-center'}>
                                                            <div className={'form-group mr-3'}>
                                                                <label htmlFor="email"><strong>* {CacheExtension.getDataFromCache('Email')}</strong></label>
                                                                <input type="text"
                                                                       className="form-control"
                                                                       name="email"
                                                                       value={form.email}
                                                                       disabled={step2Sell}
                                                                       onChange={handleChange}/>
                                                            </div>
                                                            <button disabled={!validateMail(form.email)}  className={'btn btnBo mt-3 mr-3'} onClick={() => {
                                                                checkMail(form.email)
                                                                setStep2Sell(true)
                                                            }}>{CacheExtension.getDataFromCache('CheckMail')}</button>
                                                            <button disabled={!step2Sell}  className={'btn btnBo mt-3'} onClick={() => {
                                                                setForm({
                                                                    ...initialForm,
                                                                    saleType: form.saleType
                                                                })
                                                                setStep2Sell(false)
                                                                setLastStep(false)
                                                                setInvoiceFile(null)
                                                            }}>{CacheExtension.getDataFromCache('ResetMail')}</button>
                                                    </div>
                                                    {step2Sell && clientExist.exist && !loading &&
                                                        <div>
                                                                <div className="form-row">
                                                                    <div className="form-group col-md-2">
                                                                        <strong>{CacheExtension.getDataFromCache('ClientFound')}</strong>
                                                                    </div>
                                                                </div>
                                                                <div className="form-row">
                                                                    <div className="form-group col-md-2">
                                                                        <label htmlFor="name">{CacheExtension.getDataFromCache('Firstname')}</label>
                                                                        <div>{clientExist.name}</div>
                                                                    </div>
                                                                    <div className="form-group col-md-2">
                                                                        <label htmlFor="lastname">{CacheExtension.getDataFromCache('Lastname')}</label>
                                                                        <div>{clientExist.lastName}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="form-row">
                                                                    <div className="form-group col-md-3">
                                                                        <label htmlFor="name">{CacheExtension.getDataFromCache('Phone')}</label>
                                                                        <div>{Object.keys(clientExist.phones).find(key => clientExist.phones[key] === 1) || ''}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="form-row">
                                                                    <div className="form-group col-md-3">
                                                                        <label htmlFor="name">{CacheExtension.getDataFromCache('Address')}</label>
                                                                        <div>{Object.keys(clientExist.addresses).find(key => clientExist.addresses[key] === 1) || ''}</div>
                                                                    </div>
                                                                </div>
                                                        </div>

                                                    }
                                                    {step2Sell && !loading &&
                                                        <div className={"mb-3"}>
                                                            <div className="form-row">
                                                                <div className="form-group col-md-2">
                                                                    <label htmlFor="date"><strong>* {CacheExtension.getDataFromCache('SaleDate')}</strong></label>
                                                                    <input value={form.date} className="form-control" type='date' name="date" onChange={handleChange} />
                                                                </div>
                                                            </div>
                                                            <form

                                                                id={'_form'}
                                                                onChange={() => handleInvoice()}
                                                                action="">
                                                                <ImportComponent formName={"* " + CacheExtension.getDataFromCache("productInvoice.import")} className={'col-4'} form={false}/>
                                                            </form>
                                                            <div className="row">
                                                                <div className="col-4" id="fileDisplayArea"></div>
                                                            </div>
                                                        </div>
                                                    }

                                                    {step2Sell && !clientExist.exist && !loading &&
                                                        <>
                                                            <div>
                                                                <div className="form-row">
                                                                    <div className="form-group col-md-2">
                                                                        <label htmlFor="date"><strong>* {CacheExtension.getDataFromCache('Language')}</strong></label>
                                                                        <Dropdown
                                                                            defaultValue={'EN'}
                                                                            fluid
                                                                            selection
                                                                            onChange={async (e, {value}) => {
                                                                                setForm({
                                                                                    ...form,
                                                                                    defaultLanguage: value
                                                                                })
                                                                            }}
                                                                            options={[{value : 'FR', text: 'FR'}, {value : 'EN', text: 'EN'}]}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="form-row">
                                                                    <div className="form-group col-md-3">
                                                                        <label htmlFor="name">{CacheExtension.getDataFromCache('Firstname')}</label>
                                                                        <input type="text"
                                                                               className="form-control"
                                                                               name="name"
                                                                               onChange={handleChange}/>
                                                                    </div>
                                                                    <div className="form-group col-md-3">
                                                                        <label htmlFor="lastname">{CacheExtension.getDataFromCache('Lastname')}</label>
                                                                        <input type="text"
                                                                               className="form-control"
                                                                               name="lastname"
                                                                               onChange={handleChange}/>
                                                                    </div>
                                                                </div>
                                                                <div className={"form-row"}>
                                                                    <div className="form-group col-md-3">
                                                                        <label htmlFor="phone">{CacheExtension.getDataFromCache('Phone')}</label>
                                                                        <input type="tel"
                                                                               className="form-control"
                                                                               name="phone"
                                                                               pattern="[0-9+]*"
                                                                               onKeyDown={(event) => {
                                                                                   if (!/[0-9+]/.test(event.key) && ![ 'Backspace', 'Delete', 'Tab', 'ArrowLeft', 'ArrowRight' ].includes(event.key)) {
                                                                                       event.preventDefault();
                                                                                   }
                                                                               }}
                                                                               onChange={handleChange}/>
                                                                    </div>
                                                                </div>
                                                                <div className="form-row">
                                                                    <div className="form-group col-md-3">
                                                                        <label htmlFor="address">{CacheExtension.getDataFromCache('Address')}</label>
                                                                        <input type="text"
                                                                               className="form-control"
                                                                               name="address"
                                                                               onChange={handleChange}/>
                                                                    </div>
                                                                    <div className="form-group col-md-3">
                                                                        <label htmlFor="city">{CacheExtension.getDataFromCache('City')}</label>
                                                                        <input type="text"
                                                                               className="form-control"
                                                                               name="city"
                                                                               onChange={handleChange}/>
                                                                    </div>
                                                                </div>
                                                                <div className="form-row">
                                                                    <div className="form-group col-md-3">
                                                                        <label htmlFor="zipCode">{CacheExtension.getDataFromCache('ZipCode')}</label>
                                                                        <input type="text"
                                                                               className="form-control"
                                                                               name="zipCode"
                                                                               onChange={handleChange}/>
                                                                    </div>
                                                                    <div className="form-group col-md-3">
                                                                        <label htmlFor="country">{CacheExtension.getDataFromCache('Country')}</label>
                                                                        <input type="text"
                                                                               className="form-control"
                                                                               name="country"
                                                                               onChange={handleChange}/>
                                                                    </div>
                                                                </div>
                                                                <div className="form-row">
                                                                    <div className="form-group col-md-3">
                                                                        <label htmlFor="navision">Navision</label>
                                                                        <input type="text"
                                                                               className="form-control"
                                                                               name="navision"
                                                                               maxLength={16}
                                                                               onChange={handleChange}/>
                                                                    </div>
                                                                </div>
                                                                <div className="form-row">
                                                                    <div className="form-group col-md-6">
                                                                        <label htmlFor="comment">{CacheExtension.getDataFromCache('Comment')}</label>
                                                                        <textarea
                                                                            className="form-control"
                                                                            name="comment"
                                                                            onChange={handleChange}/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    }

                                                    {step2Sell && !loading &&
                                                        <div className="row d-flex justify-content-end">
                                                            <button className="btn btnBo" disabled={!invoiceFile || !form.date} onClick={() => {
                                                                setLastStep(true)
                                                            }}>{CacheExtension.getDataFromCache('Validate')}</button>
                                                        </div>
                                                    }
                                                </>
                                            }
                                    </div>
                                </div>

                                <div className={(shop || lastStep) ? 'stockSection' : 'stockSection disabledSection'}>
                                    <span className={"step"}>{CacheExtension.getDataFromCache('Step').replace('%%1%%',CacheExtension.getDataFromCache('stepGestion4'))}</span>
                                    <div className={'stockSectionContent justify-content-end mb-2'}>
                                        <ConfirmModalComponent disabled={!(shop || lastStep)} html={modalHtml(SKU[0], serial[0], shop[1] ?? null)} icon={<span>{CacheExtension.getDataFromCache(shop ? 'SendShopModal' : 'Send')}</span>} title={CacheExtension.getDataFromCache(shop ? 'SendShopModal' : 'Send')} action={async function (e) {
                                            if(shop){
                                                await productService.send(serial[1].key,shop[0]);
                                            } else if (clientExist.exist) {
                                                await productService.sell(serial[1].key,form, invoiceFile);
                                            } else {
                                                await productService.sellAndCreate(serial[1].key,form, invoiceFile);
                                            }
                                            window.location.reload();
                                        }}></ConfirmModalComponent>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </m.div>
            }
        </>
    );
}

