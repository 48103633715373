import {BaseApiService} from "../../../StarterKit/Service/BaseApiService";


export default class ApiShop extends BaseApiService {


    async list() {
        this.setGetMethod();
        this.setSecureByUser();
        this.setApiRoute(sessionStorage['language'] + "/shop");
        return await this.callApi();
    }

    async listAll() {
        this.setGetMethod();
        this.setSecureByUser();
        this.setApiRoute(sessionStorage['language'] + "/shops");
        return await this.callApi();
    }

    async import(file) {
        this.setPostMethod();
        this.setSecureByUser();
        this.callNeedFileInBody();
        this.setFile(file);
        this.setShowReturn();
        this.setApiRoute(sessionStorage['language'] + "/shops/import?force=true");
        return await this.callApi();
    }

}
