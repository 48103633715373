import React, {useEffect, useState} from "react";
import {motion as m} from "framer-motion";
import CacheExtension from "../../StarterKit/Filter/CacheExtension";
import {HeaderComponent} from "../Components/HeaderComponent";
import 'semantic-ui-css/semantic.min.css'
import '../css/bo.css'
import '../css/Model.css'
import Models from "../Components/Models";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import SettingService from "../../StarterKit/Service/Bll/SettingService";
import ModelsPaginateCollapse from "../Components/ModelsPaginateCollapse";
import ModelsPaginate from "../Components/ModelsPaginate";

export default function ModelsPage() {
    var serviceSettings = new SettingService();

    const [paginate, setPaginate] = useState(false);
    const [collapse, setCollapse] = useState(false);

    useEffect(() => {
        serviceSettings.getFromGroup("app").then(function (res) {
            setPaginate(res.getData()['paginate'].text === 'yes')
            setCollapse(res.getData()['collapseList'].text === 'yes')
        })

    }, [])

    return (
        <>
            <m.div className={"wrapper"}>
                <div id={"page"}>
                    <HeaderComponent logo={'/pictures/Logo_Pequignet.png'} title={CacheExtension.getDataFromCache('Models')}></HeaderComponent>
                    <div style={{padding:"20px"}}>
                        <Link className="backLinkModel" to={
                            sessionStorage["profile"] == "prod" ? `/prod/stock` :
                                sessionStorage["profile"] == "gestion" ? `/gestion/stock` :
                                    `/sav`
                        }>
                            <FontAwesomeIcon icon="fas fa-chevron-left" style={{marginRight:"10px", color:"rgb(255, 158, 23)"}} />
                            {CacheExtension.getDataFromCache('Back')}
                        </Link>
                    </div>
                    <div id="content">
                        <div id={"page"}>
                            {collapse ?
                                <ModelsPaginateCollapse/> :
                                <ModelsPaginate/>
                            }
                        </div>
                    </div>
                </div>
            </m.div>
        </>
    );
}

