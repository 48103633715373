import React from "react";
import '../styles/menu.css';
import '../styles/header.css';
import '../styles/message.css'

export const Header = () => {
    var lastname = ""
    if (sessionStorage['lastname']) {
        lastname = sessionStorage['lastname'];
    }

    return (
        <>
            <div className="user color-nav text-center">
                <a  data-placement="bottom" data-toggle="tooltip"
                   data-original-title="Changer le mot de passe">
                    {lastname}
                </a>
            </div>
            {/*<div className="message pt-2 pb-2 pl-4 pr-4 m-2" id="siteMessages">*/}
            {/*</div>*/}
            <div className={'container__modal'}>

            </div>
            <div className={"modal-backdrop fade"}>

            </div>

        </>
    );
}

