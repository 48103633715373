import React, {useEffect, useState} from "react";
import CacheExtension from "../../StarterKit/Filter/CacheExtension";
import 'semantic-ui-css/semantic.min.css'
import '../css/bo.css'
import '../css/Model.css'
import {IconActionButton} from "../Components/IconActionButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ProductService from "../Services/Bll/ProductService";
import {ProductModal} from "./ProductModal";
import SealVectorService from "../Services/Bll/SealVectorService";
import {Dropdown} from "semantic-ui-react";
import ShopService from "../Services/Bll/ShopService";
import ModelService from "../Services/Bll/ModelService";
import {ConfirmModalComponent} from "../../StarterKit/Components/ConfirmModalComponent";
import MediaService from "../../StarterKit/Service/Bll/MediaService";
import {ProductDetails} from "./ProductDetails";
import {DeleteSerialModalComponent} from "./DeleteSerialModalComponent";

export default function Products({pSearch}) {
    const service = new ProductService();
    const sealVectorService = new SealVectorService();
    const shopService = new ShopService();
    const modelService = new ModelService();
    const mediaService = new MediaService();

    const [data, setData] = useState(null);
    const [nbProducts, setNbProducts] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [skuSearch, setSkuSearch] = useState("");
    const [variantIdSearch, setVariantIdSearch] = useState(pSearch || "");
    const [serialSearch, setSerialSearch] = useState("");
    const [orderField, setOrderField] = useState("sku");
    const [orderDirection, setOrderDirection] = useState("asc");

    const [shopsList, setShopsList] = useState(null);
    const [skuList, setSkuList] = useState(null);

    useEffect(() => {
        service.listAll(currentPage, skuSearch, variantIdSearch, serialSearch, orderField, orderDirection).then(function (res) {
            setData(res.getData())
            setNbProducts(res.getContext().count)
        })

    }, [currentPage, skuSearch, variantIdSearch, serialSearch, orderField, orderDirection])

    useEffect(() => {
        setVariantIdSearch(pSearch || '');
    }, [pSearch]);

    useEffect(() => {
        shopService.listAll().then(function (result) {
            var res = {}
            for (const country in result.getData()) {
                for (const state in result.getData()[country]) {
                    result.getData()[country][state].forEach(function (e) {
                        res[e['key']] = e['name']
                    })
                }

            }
            res = Object.fromEntries(Object.entries(res).sort((a, b) => a[1].localeCompare(b[1])));
            setShopsList(res)
        })

        modelService.listForAllSku().then((res) => {
            setSkuList(res.getData())
        })
    }, []);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    async function saveSealVector(product) {
        let name = "M-"+product.modelId.replace(/\//g, "-")+"-"+product.serial+".dxf"
        await sealVectorService.readDxf(product.sealVectorAsset, name)
    }

    async function saveAuthCard(product) {
        let name = "C-"+product.modelId.replace(/\//g, "-")+"-"+product.serial+".dxf"
        await mediaService.save(product.authCardAsset, name)
    }

    async function removeSerial(key){
        await service.removeSerial(key)
        setData(prevData => {
            const newProducts = prevData.products.filter(product => product.key !== key);
            return { ...prevData, products: newProducts };
        });
    }

    async function removeFromShop(key){
        await service.removeFromShop(key)
        setData(prevData => {
            const newProducts = prevData.products.map(product => {
                if (product.key === key) {
                    return { ...product, shopKey: "" };
                }
                return product;
            });
            return { ...prevData, products: newProducts };
        });

    }

    function removeFromShopHtml() {
        return (
            <div className="mb-5">
                <div className="mb-3">
                    <label><strong>{CacheExtension.getDataFromCache("RemoveShopText")}</strong></label>
                </div>
            </div>
        )
    }

    return (
        <>
            {data &&
                <>
                    <div className="d-flex justify-content-between" style={{padding: "2rem 2rem 2rem 1.25rem"}}>
                        <span className={"nbModel"}>{CacheExtension.getDataFromCache('NbInstances')} : <strong>{nbProducts}</strong></span>
                    </div>
                    <div className="row" style={{margin: "0"}}>
                        <div className="col-3">
                            <div className="input-group w-75 mb-3">
                                <input
                                    type="text"
                                    placeholder={CacheExtension.getDataFromCache('Serial')}
                                    className="form-control"
                                    value={serialSearch}
                                    onChange={e => {
                                        setSerialSearch(e.target.value)
                                        setCurrentPage(1)
                                    }}
                                />
                                <div className="input-group-append">
                                    <button
                                        className="btn btn-outline-secondary"
                                        type="button"
                                        onClick={()=> {
                                            setSerialSearch("")
                                            setCurrentPage(1)
                                        }}
                                    >
                                        <svg
                                            className="svg-inline--fa fa-xmark"
                                            aria-hidden="true"
                                            focusable="false"
                                            data-prefix="fas"
                                            data-icon="xmark"
                                            role="img"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 320 512"
                                            data-fa-i2svg=""
                                        >
                                            <path
                                                fill="currentColor"
                                                d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z"
                                            />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="input-group w-75 mb-3">
                                <input
                                    type="text"
                                    placeholder={CacheExtension.getDataFromCache('Sku')}
                                    className="form-control"
                                    value={skuSearch}
                                    onChange={e => {
                                        setSkuSearch(e.target.value)
                                        setCurrentPage(1)
                                    }}
                                />
                                <div className="input-group-append">
                                    <button
                                        className="btn btn-outline-secondary"
                                        type="button"
                                        onClick={()=> {
                                            setSkuSearch("")
                                            setCurrentPage(1)
                                        }}
                                    >
                                        <svg
                                            className="svg-inline--fa fa-xmark"
                                            aria-hidden="true"
                                            focusable="false"
                                            data-prefix="fas"
                                            data-icon="xmark"
                                            role="img"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 320 512"
                                            data-fa-i2svg=""
                                        >
                                            <path
                                                fill="currentColor"
                                                d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z"
                                            />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="input-group w-75 mb-3">
                                <input
                                    type="text"
                                    placeholder={CacheExtension.getDataFromCache('VariantId')}
                                    className="form-control"
                                    value={variantIdSearch}
                                    onChange={e => {
                                        setVariantIdSearch(e.target.value)
                                        setCurrentPage(1)
                                    }}
                                />
                                <div className="input-group-append">
                                    <button
                                        className="btn btn-outline-secondary"
                                        type="button"
                                        onClick={()=> {
                                            setVariantIdSearch("")
                                            setCurrentPage(1)
                                        }}
                                    >
                                        <svg
                                            className="svg-inline--fa fa-xmark"
                                            aria-hidden="true"
                                            focusable="false"
                                            data-prefix="fas"
                                            data-icon="xmark"
                                            role="img"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 320 512"
                                            data-fa-i2svg=""
                                        >
                                            <path
                                                fill="currentColor"
                                                d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z"
                                            />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-3 d-flex justify-content-end">
                            <nav>
                                <ul className="pagination">
                                    {[...Array(data.totalPages).keys()].map((page) => {
                                        if (page === 0 || page === data.totalPages - 1 || (page >= currentPage - 2 && page <= currentPage) || (currentPage < 4 && page < 4) || (currentPage >= data.totalPages - 2 && page >= data.totalPages - 4)) {
                                            return (
                                                <li key={page} className={`page-item ${currentPage === page + 1 ? 'active' : ''}`}>
                                                    <button className={`btn ${currentPage === page + 1 ? 'btnBo' : 'btnBoReverse'}`} onClick={() => handlePageChange( page + 1)}>
                                                        {page + 1}
                                                    </button>
                                                </li>
                                            );
                                        } else {
                                            return null;
                                        }
                                    })}
                                </ul>
                            </nav>
                        </div>
                    </div>
                    <div className={'modelsSection card-body pt-0'}>
                        <div className="row mx-0 py-2" style={{borderBottom: '1px solid #dee2e6'}}>
                            <div className="col-9">
                                <div className="row">
                                    <div className="col-4">
                                        <div className="row">
                                            <div className="col-4">
                                                <strong>{CacheExtension.getDataFromCache('Serial')}</strong>
                                                {orderField == "serial" ?
                                                    (orderDirection == "asc" ?
                                                        <IconActionButton  action={() => {
                                                            setOrderDirection("desc")
                                                            setCurrentPage(1)
                                                        }} state={true} icon={<FontAwesomeIcon icon="fas fa-caret-up"/>}  className={"btn modelDetails px-1 py-0"} tooltip={CacheExtension.getDataFromCache('SortAsc')}/>
                                                        :
                                                        <IconActionButton  action={() => {
                                                            setOrderDirection("asc")
                                                            setCurrentPage(1)
                                                        }} state={true} icon={<FontAwesomeIcon icon="fas fa-caret-down"/>}  className={"btn modelDetails px-1 py-0"} tooltip={CacheExtension.getDataFromCache('SortDesc')}/>)
                                                    :
                                                    <IconActionButton  action={() => {
                                                        setOrderField("serial")
                                                        setOrderDirection("asc")
                                                        setCurrentPage(1)
                                                    } } state={true} icon={<FontAwesomeIcon icon="fas fa-sort"/>}  className={"btn modelDetails px-1 py-0"} tooltip={CacheExtension.getDataFromCache('SortDesc')}/>
                                                }
                                            </div>
                                            <div className="col-3">
                                                <strong>{CacheExtension.getDataFromCache('Sku')}</strong>
                                                {orderField == "sku" ?
                                                    (orderDirection == "asc" ?
                                                        <IconActionButton  action={() => {
                                                            setOrderDirection("desc")
                                                            setCurrentPage(1)
                                                        }} state={true} icon={<FontAwesomeIcon icon="fas fa-caret-up"/>} className={"btn modelDetails px-1 py-0"} tooltip={CacheExtension.getDataFromCache('SortAsc')}/>
                                                        :
                                                        <IconActionButton  action={() => {
                                                            setOrderDirection("asc")
                                                            setCurrentPage(1)
                                                        }} state={true} icon={<FontAwesomeIcon icon="fas fa-caret-down"/>} className={"btn modelDetails px-1 py-0"} tooltip={CacheExtension.getDataFromCache('SortDesc')}/>)
                                                    :
                                                    <IconActionButton  action={() => {
                                                        setOrderField("sku")
                                                        setOrderDirection("asc")
                                                        setCurrentPage(1)
                                                    } } state={true} icon={<FontAwesomeIcon icon="fas fa-sort"/>} className={"btn modelDetails px-1 py-0"} tooltip={CacheExtension.getDataFromCache('SortDesc')}/>
                                                }
                                            </div>
                                            <div className="col-5">
                                                <strong>{CacheExtension.getDataFromCache('Statut')}</strong>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-8">
                                        <div className="row">
                                            <div className="col-3">
                                                <strong>{CacheExtension.getDataFromCache('VariantId')}</strong>
                                                {orderField == "variantId" ?
                                                    (orderDirection == "asc" ?
                                                        <IconActionButton  action={() => {
                                                            setOrderDirection("desc")
                                                            setCurrentPage(1)
                                                        }} state={true} icon={<FontAwesomeIcon icon="fas fa-caret-up"/>} className={"btn modelDetails px-1 py-0"} tooltip={CacheExtension.getDataFromCache('SortAsc')}/>
                                                        :
                                                        <IconActionButton  action={() => {
                                                            setOrderDirection("asc")
                                                            setCurrentPage(1)
                                                        }} state={true} icon={<FontAwesomeIcon icon="fas fa-caret-down"/>} className={"btn modelDetails px-1 py-0"} tooltip={CacheExtension.getDataFromCache('SortDesc')}/>)
                                                    :
                                                    <IconActionButton  action={() => {
                                                        setOrderField("variantId")
                                                        setOrderDirection("asc")
                                                        setCurrentPage(1)
                                                    } } state={true} icon={<FontAwesomeIcon icon="fas fa-sort"/>} className={"btn modelDetails px-1 py-0"} tooltip={CacheExtension.getDataFromCache('SortDesc')}/>
                                                }
                                                <p>({CacheExtension.getDataFromCache('BraceletColorDate')})</p>
                                            </div>
                                            <div className="col-3">
                                                <strong>{CacheExtension.getDataFromCache('ProdDate')}</strong>
                                                {orderField == "CreatedOn" ?
                                                    (orderDirection == "asc" ?
                                                        <IconActionButton  action={() => {
                                                            setOrderDirection("desc")
                                                            setCurrentPage(1)
                                                        }} state={true} icon={<FontAwesomeIcon icon="fas fa-caret-up"/>} className={"btn modelDetails px-1 py-0"} tooltip={CacheExtension.getDataFromCache('SortAsc')}/>
                                                        :
                                                        <IconActionButton  action={() => {
                                                            setOrderDirection("asc")
                                                            setCurrentPage(1)
                                                        }} state={true} icon={<FontAwesomeIcon icon="fas fa-caret-down"/>} className={"btn modelDetails px-1 py-0"} tooltip={CacheExtension.getDataFromCache('SortDesc')}/>)
                                                    :
                                                    <IconActionButton  action={() => {
                                                        setOrderField("CreatedOn")
                                                        setOrderDirection("desc")
                                                        setCurrentPage(1)
                                                    } } state={true} icon={<FontAwesomeIcon icon="fas fa-sort"/>} className={"btn modelDetails px-1 py-0"} tooltip={CacheExtension.getDataFromCache('SortDesc')}/>
                                                }
                                            </div>
                                            <div className="col-3">
                                                <strong>{CacheExtension.getDataFromCache('Shop')}</strong>
                                                <div>
                                                    <strong>{CacheExtension.getDataFromCache('ShopDate')}</strong>
                                                    {orderField == "shopDate" ?
                                                        (orderDirection == "asc" ?
                                                            <IconActionButton  action={() => {
                                                                setOrderDirection("desc")
                                                                setCurrentPage(1)
                                                            }} state={true} icon={<FontAwesomeIcon icon="fas fa-caret-up"/>} className={"btn modelDetails px-1 py-0"} tooltip={CacheExtension.getDataFromCache('SortAsc')}/>
                                                            :
                                                            <IconActionButton  action={() => {
                                                                setOrderDirection("asc")
                                                                setCurrentPage(1)
                                                            }} state={true} icon={<FontAwesomeIcon icon="fas fa-caret-down"/>} className={"btn modelDetails px-1 py-0"} tooltip={CacheExtension.getDataFromCache('SortDesc')}/>)
                                                        :
                                                        <IconActionButton  action={() => {
                                                            setOrderField("shopDate")
                                                            setOrderDirection("desc")
                                                            setCurrentPage(1)
                                                        } } state={true} icon={<FontAwesomeIcon icon="fas fa-sort"/>} className={"btn modelDetails px-1 py-0"} tooltip={CacheExtension.getDataFromCache('SortDesc')}/>
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <strong>{CacheExtension.getDataFromCache('WarrantyDate')}</strong>
                                                {orderField == "warrantyDate" ?
                                                    (orderDirection == "asc" ?
                                                        <IconActionButton  action={() => {
                                                            setOrderDirection("desc")
                                                            setCurrentPage(1)
                                                        }} state={true} icon={<FontAwesomeIcon icon="fas fa-caret-up"/>} className={"btn modelDetails px-1 py-0"} tooltip={CacheExtension.getDataFromCache('SortAsc')}/>
                                                        :
                                                        <IconActionButton  action={() => {
                                                            setOrderDirection("asc")
                                                            setCurrentPage(1)
                                                        }} state={true} icon={<FontAwesomeIcon icon="fas fa-caret-down"/>} className={"btn modelDetails px-1 py-0"} tooltip={CacheExtension.getDataFromCache('SortDesc')}/>)
                                                    :
                                                    <IconActionButton  action={() => {
                                                        setOrderField("warrantyDate")
                                                        setOrderDirection("desc")
                                                        setCurrentPage(1)
                                                    } } state={true} icon={<FontAwesomeIcon icon="fas fa-sort"/>} className={"btn modelDetails px-1 py-0"} tooltip={CacheExtension.getDataFromCache('SortDesc')}/>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={"col-3"}>
                                <div className={"row"}>
                                    <div className="col-3 d-flex justify-content-center">
                                        <strong>{CacheExtension.getDataFromCache('User')}</strong>
                                    </div>
                                    <div className="col-3 d-flex justify-content-center">
                                        <strong>{CacheExtension.getDataFromCache('SealVector')}</strong>
                                    </div>
                                    <div className="col-2 d-flex justify-content-center">
                                        <strong>{CacheExtension.getDataFromCache('AuthCard')}</strong>
                                    </div>
                                    <div className="col-2  d-flex justify-content-center">
                                        <strong>{CacheExtension.getDataFromCache('Details')}</strong>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {data && data.products.length > 0 ?
                            <div className="">
                                {data.products.map((product, j) => (
                                        <div key={j} className={'' + (j % 2 === 0 ? 'oddTableLine' : 'evenTableLine')}>
                                            <div className="row mx-0" style={{height: "90px"}}>
                                                <div className="col-9">
                                                    <div className="row h-100">
                                                        <div className="col-4">
                                                            <div className="row h-100">
                                                                <div className="col-4 d-flex align-items-center">
                                                                    <p>{product.serial}</p>
                                                                </div>
                                                                <div className="col-3 d-flex align-items-center">
                                                                    <p>{product.modelId}</p>
                                                                </div>
                                                                <div className="col-5 d-flex align-items-center">
                                                                    <p>{CacheExtension.getDataFromCache(product.state)}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-8">
                                                            <div className="row h-100">
                                                                <div className="col-3 d-flex align-items-center">
                                                                    {product.model ?
                                                                        (
                                                                            sessionStorage['profile'] == "admin" ?
                                                                                <Dropdown
                                                                                    placeholder={product.model.variantId}
                                                                                    fluid
                                                                                    search
                                                                                    selection
                                                                                    defaultValue={product.model.key}
                                                                                    options={skuList && Object.entries(skuList).filter(([sku, models]) => sku == product.model.sku).flatMap(([sku, models]) => models.map(model => ({
                                                                                        key: model.key,
                                                                                        value: model.key,
                                                                                        text: (
                                                                                            <div>
                                                                                                <div>{model.variantId}</div>
                                                                                                {model.options && <div>({model.options["Type de bracelet"] || 'N/A'}, {model.options["Couleur"] || 'N/A'}, {model.options["Date"] || 'N/A'})</div>}
                                                                                            </div>
                                                                                        )
                                                                                    })))}
                                                                                    onChange={async (e, {value}) => {
                                                                                        await service.updateLinkedModel(product.key, value)
                                                                                    }}
                                                                                    style={{maxWidth : '300px', maxHeight: '65px'}}
                                                                                    className={'my-3'}
                                                                                />
                                                                                :
                                                                                <>
                                                                                    {skuList && product.model.sku in skuList && (
                                                                                        (() => {
                                                                                            const model = skuList[product.model.sku].find(item => item.variantId === product.model.variantId);
                                                                                            return model && (
                                                                                                <div>
                                                                                                    <p>{model.variantId} <br/>({model.options["Type de bracelet"] || 'N/A'}, {model.options["Couleur"] || 'N/A'}, {model.options["Date"] || 'N/A'}) </p>
                                                                                                </div>
                                                                                            );
                                                                                        })()
                                                                                    )
                                                                                    }
                                                                                </>
                                                                        )
                                                                        :
                                                                        <p>{CacheExtension.getDataFromCache('NoModelLinked')}</p>
                                                                    }
                                                                </div>
                                                                <div className="col-3 d-flex align-items-center">
                                                                    <p>{product.createdOn.split('T')[0]}</p>
                                                                </div>
                                                                <div className="col-3 d-flex align-items-center dlSV">
                                                                    <div>
                                                                        <div className="d-flex align-items-center dlSV">
                                                                            {
                                                                                product.shopKey ? (
                                                                                    shopsList && product.shopKey in shopsList ? (
                                                                                        sessionStorage['profile'] === "admin" && !product.owner ? (
                                                                                            <>
                                                                                                <Dropdown
                                                                                                    placeholder={CacheExtension.getDataFromCache('SelectShop')}
                                                                                                    fluid
                                                                                                    search
                                                                                                    selection
                                                                                                    defaultValue={product.shopKey}
                                                                                                    options={Object.entries(shopsList).map(([key, value]) => ({ key, value: key, text: value }))}
                                                                                                    onChange={async (e, { value }) => {
                                                                                                        await service.updateShop(product.key, value)
                                                                                                    }}
                                                                                                    style={{ minWidth: '10em', maxHeight: '65px' }}
                                                                                                />
                                                                                                <ConfirmModalComponent actionLink={true} html={removeFromShopHtml()} icon={<FontAwesomeIcon className={"btn text-danger p-0"} icon="fas fa-x"/>} title={CacheExtension.getDataFromCache('RemoveShop')} action={async function (e) {
                                                                                                    await removeFromShop(product.key)
                                                                                                }}/>
                                                                                            </>
                                                                                        ) : (
                                                                                            <p>{shopsList[product.shopKey]}</p>
                                                                                        )
                                                                                    ) : (
                                                                                        <p>{CacheExtension.getDataFromCache(`${product.shopKey}Sale`)}</p>
                                                                                    )
                                                                                ) : (
                                                                                    <p>{CacheExtension.getDataFromCache('NotInShop')}</p>
                                                                                )
                                                                            }
                                                                        </div>
                                                                        {product.shopDate &&
                                                                            <p>{product.shopDate}</p>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className="col-3 d-flex align-items-center">
                                                                    {product.warrantyDate ?
                                                                        <>
                                                                            {sessionStorage['profile'] === "admin" ?
                                                                                <div className="form-group">
                                                                                    <input value={product.warrantyDate} className="form-control" type='date' name="date" onChange={async(e)=> {
                                                                                        setData(prevData => {
                                                                                            const newProducts = prevData.products.map(p => {
                                                                                                if (p.key ===product.key) {
                                                                                                    return { ...p, warrantyDate: e.target.value };
                                                                                                }
                                                                                                return p;
                                                                                            });
                                                                                            return { ...prevData, products: newProducts };
                                                                                        });
                                                                                        await service.updateWarrantyDates(product.key, {[e.target.name]:e.target.value} )
                                                                                    }}/>
                                                                                </div> :
                                                                                <p>{product.warrantyDate}</p>
                                                                            }
                                                                        </> :
                                                                        <p>{CacheExtension.getDataFromCache('NoWarranty')}</p>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className={"col-3"}>
                                                    <div className={"row h-100"}>
                                                        <div className="col-3 d-flex justify-content-center">
                                                            {product.owner ?
                                                                <ProductModal product={product}/> :
                                                                <IconActionButton action={() => { return }} state={false} className={"btn"} icon={<FontAwesomeIcon icon="fas fa-user"/>}  tooltip={CacheExtension.getDataFromCache('NoUser')}/>
                                                            }
                                                        </div>
                                                        <div className="col-3 d-flex justify-content-center dlSV">
                                                            {product.sealVectorAsset ?
                                                                <IconActionButton  action={async () => await saveSealVector(product)} state={true} icon={<FontAwesomeIcon className={"actionLink"} icon="fas fa-download"/>}  tooltip={CacheExtension.getDataFromCache('Download')}/> :
                                                                <IconActionButton action={() => { return }} state={false} className={"btn"} icon={<FontAwesomeIcon icon="fas fa-download"/>}  tooltip={CacheExtension.getDataFromCache('NoSealVector')}/>
                                                            }
                                                        </div>
                                                        <div className="col-2 d-flex justify-content-center dlSV">
                                                            {product.authCardAsset ?
                                                                <IconActionButton action={async () => await saveAuthCard(product)} state={true} icon={<FontAwesomeIcon className={"actionLink"} icon="fas fa-download"/>} tooltip={CacheExtension.getDataFromCache('Download')}/> :
                                                                <IconActionButton action={() => { return }} state={false} className={"btn"} icon={<FontAwesomeIcon icon="fas fa-download"/>}  tooltip={CacheExtension.getDataFromCache('NoAuthCard')}/>
                                                            }
                                                        </div>
                                                        <div className="col-2 d-flex justify-content-center">
                                                            <ProductDetails product={product}/>
                                                        </div>
                                                        {sessionStorage['profile'] == "admin" &&
                                                            <div className="col-1 d-flex justify-content-center removeSerial">
                                                                <DeleteSerialModalComponent product={product.key}/>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                )}
                            </div>
                            :
                            <p className="text-center mt-2">{CacheExtension.getDataFromCache('NoProductMatch')}</p>
                        }
                    </div>
                </>
            }
        </>
    );
}

