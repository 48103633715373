import CacheExtension from "../../Filter/CacheExtension";

class PasswordAutoform {
    #state
    constructor(parameter) {
        this.#state = {
            mandatory : false,
            modale : parameter.modale,
            keyform : parameter.parameterObject.mandatory,
            parameter : parameter
        }
        if (this.#state.keyform) {
            this.#state.mandatory = true
        }
    }

    check() {
            var checkValue = true
            var returnTable = []
            var errorCode = ""
            var arrayInput = {}
            var classNamePassword = "." +  this.#state.parameter.parameterkeyForm + "-" + this.#state.parameter.parameterName + "-"+this.#state.parameter.parameterData
            var classNameConfirmPassword = "." + this.#state.parameter.parameterkeyForm + "-" + this.#state.parameter.parameterName + "-confirmPassword"
            var password = document.querySelector(classNamePassword)
            var confirmpassword = document.querySelector(classNameConfirmPassword)
            var pwDontMatch = document.querySelector(".PwDontMatch");
            password.addEventListener('click', function() {
                this.classList.remove("inputError");
                confirmpassword.classList.remove("inputError");

            });
            confirmpassword.addEventListener('click', function() {
                this.classList.remove("inputError");
                password.classList.remove("inputError");

            });
            if (!pwDontMatch.classList.contains("d-none")) {
                pwDontMatch.classList.add("d-none");
            }
            if (password.classList.contains("inputError")) {
                password.classList.remove("inputError");
            }
            if (confirmpassword.classList.contains("inputError")) {
                confirmpassword.classList.remove("inputError");
            }

            if (password.value === "" &&  this.#state.mandatory === true) {
                checkValue = false
            }
            if (password.value.trim() === "") {
                checkValue = false
            }
            if (confirmpassword.value != password.value) {
                password.classList.add("inputError");
                confirmpassword.classList.add("inputError");
                pwDontMatch.classList.remove("d-none");
                checkValue = false
            }
            if (this.#state.parameter.parameterObject.regex) {

                var regex =  new RegExp(this.#state.parameter.parameterObject.regex)
                var checkRegex = regex.test(password.value)
                if (checkRegex === false) {
                    password.classList.add("inputError");
                    confirmpassword.classList.add("inputError");
                    checkValue = false
                    errorCode = -1073
                }
            }


        returnTable.push(checkValue,password.name,password.value,errorCode)

        return returnTable
        }

    render() {
        return(
            <div  key={Math.random()} className={`mb-0 banner ${ this.#state.modale == true ? "form-group" : ""}`}>
                <div className={`banner ${ this.#state.modale == true ? "form-group" : ""}`}>
                    <label>{CacheExtension.getDataFromCache('label-' + this.#state.parameter.parameterkeyForm + "-" + this.#state.parameter.parameterName + "-"+this.#state.parameter.parameterData)}</label>
                    <input
                        required={this.#state.mandatory}
                        placeholder={CacheExtension.getDataFromCache('placeholder-' + this.#state.parameter.parameterkeyForm + "-" + this.#state.parameter.parameterName + "-"+this.#state.parameter.parameterData)}
                        name={this.#state.parameter.parameterData}
                        className={this.#state.parameter.parameterkeyForm + "-" + this.#state.parameter.parameterName + "-"+this.#state.parameter.parameterData + " form-control"}
                        type="password"/>
                </div>
                <div className={`banner ${ this.#state.modale == true ? "form-group" : ""}`}>
                    <label>{CacheExtension.getDataFromCache('label-' + this.#state.parameter.parameterkeyForm + "-" + this.#state.parameter.parameterName + "-confirmPassword")}</label>
                    <input
                        required={this.#state.mandatory}
                        placeholder={CacheExtension.getDataFromCache('placeholder-' + this.#state.parameter.parameterkeyForm + "-" + this.#state.parameter.parameterName + "-confirmPassword")}
                        name={this.#state.parameter.parameterData}
                        className={this.#state.parameter.parameterkeyForm + "-" + this.#state.parameter.parameterName + "-confirmPassword"+ " form-control"}
                        type="password"/>
                </div>
                <div className="PwDontMatch d-none">{CacheExtension.getDataFromCache('PasswordDoesntMatch')}</div>
                <div className="w-100 mb-3">{CacheExtension.getDataFromCache('PasswordDoesntMatchPattern')}</div>
            </div>
        )
    }
}

export default PasswordAutoform