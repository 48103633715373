import { ReactComponent as QRCodeIcon } from '../Picture/QRCode.svg'
import "../css/QRCode.css"
import React, {useEffect, useState,} from "react";
import PropTypes from "prop-types";
import ProductService from "../Services/Bll/ProductService";
import CacheExtension from "../../StarterKit/Filter/CacheExtension";
import QrReader from "react-qr-scanner";
import {IconActionButton} from "./IconActionButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default function ModaleQrCode(props) {
    $(function () {
        $('[data-toggle="tooltip"]').tooltip()
    })
    useEffect(() => {
    }, [])
    const [selected, setSelected] = useState("front");
    const [startScan, setStartScan] = useState(false);
    const [flash, setFlash] = useState(false);

    var productService = new ProductService();

    const CreateGuid = () => {
        function _p8(s) {
            var p = (Math.random().toString(16) + "000000000").substr(2, 8);
            return s ? "-" + p.substr(0, 4) + "-" + p.substr(4, 4) : p;
        }

        return _p8() + _p8(true) + _p8(true) + _p8();
    }

    var id = CreateGuid()

    function triggerScan(value) {
        $(".modal_"+id).find(".qrcodesvg").find('svg').replaceWith($(".modal_"+id).find("section"))
        setStartScan(value);
    }


    const qrCodeScan = async (data) => {
        if(data) {
            data = data.text
            var regex = /\/21\/(\d+)\//;
            var match = data.match(regex);
            var serial = match && match[1];

            var regexGtin = /\/01\/(\d+)\//;
            var matchGtin = data.match(regexGtin);
            var gtin = matchGtin && matchGtin[1];

            var result = await productService.read(gtin, serial)
            if (Object.keys(result.data).length > 0) {
                $(".modal_"+id).find("#inputName").val(result.data.model.title);
                $(".modal_"+id).find("#productKey").val(result.data.key);
            }
        }
    }
    const invokeCallBack = async () => {
        var product =  $(".modal_"+id).find("#productKey").val();
        var args = {};
        var process = true
        if (props.customInputs !== undefined){
            props.customInputs.map(function (i) {
                let val = $('#'+i.props.id).data('value') || $('#'+i.props.id).find('input').val();
                if (val === undefined){
                    process = false
                }else {
                    args[i.props.id] = val;
                }
            })
            if (process && Object.keys(args).length > 0){
                await props.action(product, args)
                window.location.reload()
            }
        } else {
                await props.action(product)
                window.location.reload()
        }
    }


    const qrCodeError = (err) => {
        console.error (err);
    }

    function openModal() {
        var container__modalSring = ".modal_" + id;
        var modale__showstring = ".backgroundmodal_" + id
        var container__modal = document.querySelector(container__modalSring)
        var modal_backdrop = document.querySelector(modale__showstring)
        modal_backdrop.classList.add('show')
        modal_backdrop.classList.add('open')
        container__modal.classList.add('show')
        container__modal.classList.add('open')
        container__modal.style.display = "block";
    }

    function closeModal(id) {
        var container__modalSring = ".modal_" + id;
        var modale__showstring = ".backgroundmodal_" + id
        var container__modal = document.querySelector(container__modalSring)
        var modal_backdrop = document.querySelector(modale__showstring)
        modal_backdrop.classList.remove('show')
        modal_backdrop.classList.remove('open')
        container__modal.classList.remove('show')
        container__modal.classList.remove('open')
        container__modal.style.display = "none";
    }


    return(
        <>
            <div>
                <button className={"btn actionLink"} onClick={function () {openModal(props)}} data-toggle={props.tooltip && "tooltip"} data-placement="top" title={props.tooltip && props.tooltip}>{props.icon}
                </button>
                <div>
                    <div id={'skModal'} className={"banner modal ModalQRCode " + "modal_" + id }>
                        <div className={"banner modal-dialog qrModal"}>
                            <div className={"banner modal-content"}>
                                <button type="button" className="closeModal close" data-dismiss="modal"
                                        aria-label="Close"><span aria-hidden="true"     onClick={() => closeModal(id)}
                                >×</span></button>
                                <h1 className={"QrTitle"}>Scanner votre QR code</h1>
                                <div>

                                    {startScan ? (
                                        <>
                                            <div className="topQrModal">
                                                {window.innerWidth <= 800 &&
                                                    <select style={{height:"50px"}} onChange={(e) => {
                                                        setSelected(e.target.value);
                                                        if (e.target.value === 'environment') {
                                                            flashlightHandler.accessFlashlight();
                                                        }
                                                    }}>
                                                        <option value={"front"}>Front Camera</option>
                                                        <option value={"environment"}>Back Camera</option>
                                                    </select>
                                                }
                                                {selected == "environment" && (
                                                    <IconActionButton action={() => {
                                                        setFlash((prevFlash) => {
                                                            const newFlash = !prevFlash;
                                                            flashlightHandler.setFlashlightStatus(newFlash);
                                                            return newFlash;
                                                        });
                                                    }} state={true} className={`flash ${flash && "flashOn"}`} icon={<FontAwesomeIcon icon="fa-solid fa-bolt" />}></IconActionButton>
                                                )}
                                            </div>

                                            {selected == "front" && (
                                                <>
                                                    <QrReader
                                                        key={selected}
                                                        delay={300}
                                                        style={{width: '100%'}}
                                                        onError={qrCodeError}
                                                        onScan={qrCodeScan}
                                                        constraints={{
                                                            video: { facingMode: "front" }
                                                        }}
                                                    />
                                                </>
                                            )}
                                            {selected == "environment" && (
                                                <>
                                                    <QrReader
                                                        key={selected}
                                                        delay={300}
                                                        style={{width: '100%'}}
                                                        onError={qrCodeError}
                                                        onScan={qrCodeScan}
                                                        constraints={{
                                                            video: { facingMode: "environment" }
                                                        }}
                                                    />
                                                </>
                                            )}
                                        </>
                                    ) :
                                        <div className="qrcodesvg" onClick={() => {triggerScan(!startScan)}}>
                                            <QRCodeIcon className="chevronBack"/>
                                        </div>
                                    }
                                    <br /><button id="newButtonScan" className="btnBo btn" onClick={() => {triggerScan(!startScan);}}>    scan </button>
                                    <div className={'d-flex flex-column p-3 align-items-center justify-content-around gap-2'}>
                                        {/*<div className={'form-group'}>*/}
                                        {/*    <label>{CacheExtension.getDataFromCache("Model")}</label>*/}
                                        {/*    <input id={'inputName'} className={'form-control mb-2'} type="text" />*/}
                                        {/*</div>*/}
                                        <div className={'form-group'}>
                                            <label>{CacheExtension.getDataFromCache("Product")}</label>
                                            <input type="text" name="productKey" id="productKey" className="form-control mb-2"/>
                                        </div>
                                        {/*<label>{CacheExtension.getDataFromCache("Model")}</label>*/}
                                        {/*<input id={'inputName'} className={'mb-2'} type="text" />*/}
                                        {/*<label>{CacheExtension.getDataFromCache("Key")}</label>*/}
                                        {/*<input value={''} type="text" name="productKey" id="productKey" className="mb-2"/>*/}
                                        {props.customInputs && props.customInputs.map(function (input) {
                                            return (
                                                input
                                            )
                                        })}
                                        <button onClick={invokeCallBack} className={'btn btnBo'}>{CacheExtension.getDataFromCache("Validate")}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div onClick={() => closeModal(id)} className={"modal-backdrop " + "backgroundmodal_" + id}></div>
                </div>
            </div>
        </>
    )
}

ModaleQrCode.prototype = {
    icon : PropTypes.element.isRequired,
    action : PropTypes.func.isRequired,
    customInputs : PropTypes.array,
    tooltip : PropTypes.string
}
