import ApiLogin from "../Api/ApiLogin";
import ApiResult from "../../Model/ApiResult";
import HelperService from "./HelperService";
import ApiLanguages from "../Api/ApiLanguages"
import ApiSettings from "../Api/ApiSettings";
import ApiControllers from "../Api/ApiControllers";
import Constants from "../../Constants";
import zone from "../../Route/Zones";
export default class ZoneService {
    #helper;
    #api;
    #apiControllers;
    #apiSettings;


    constructor() {
        this.apiControllers = new ApiControllers;
        this.apiSettings = new ApiSettings;
    }


    async getFromtier(tier) {
        // cast as ApiResult
        var result = Object.assign(new ApiResult(),await this.#api.getFromtier(tier))

        return result;
    }

    async list(tier = "")  {
        // console.debug("[Diji SK] - Service - ZoneService - list : " + sessionStorage['language'] + "/" + tier);

        var result = new ApiResult();
        var controllers = await this.apiControllers.getControllers(tier);
        if (controllers.getCode() == 0) {

            var zones = await this.apiSettings.getSettings(Constants.GROUP_SECURITY);

            if (zones.getCode() == 0) {
                result.setContext({
                    tier : tier,
                    forms : controllers.context.forms
                });
                result.setData({
                    controllers : controllers.getData(),
                    zones : zones.getData()
                });
            } else {
                result = zones;
            }
        } else {
            result = controllers;
        }
        return result;
    }


    async add(tier, zone)
    {
        console.debug(`[Diji SK] - Service - ZoneService - add in ${tier}`);
        // if (tier) {
        //     // return await this.#apiLanguages.addLanguage( model);
        // }
        console.debug(`[Diji SK] - Service - ZoneService - add ${tier} / ${zone} } `);

        const result = await this.apiControllers.addZone(tier, zone);
        return result;

    }

    /********************/
    async update(tier,key,Zone)
    {
        console.debug(`[Diji SK] - Service - ZoneService - update ${tier} / ${key} `);
        const result = await this.apiControllers.updateZone(tier,key,Zone);
        return result;
    }

    /********************/
    async delete(tier, key)
    {
        console.debug(`[Diji SK] - Service - ZoneService - delete ${tier} / ${key} `);
        const result = await  this.apiControllers.deleteZone(tier, key);
        return result;
    }

}
