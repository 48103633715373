import PropTypes from "prop-types";
import CacheExtension from "../../StarterKit/Filter/CacheExtension";
import React, {useEffect, useState,} from "react";
import {Dropdown} from 'semantic-ui-react'
import ShopService from "../Services/Bll/ShopService";
import {useForceUpdate} from "framer-motion";
import ProductService from "../Services/Bll/ProductService";




export const SendToShopModal = (props) => {
    var shopService = new ShopService();
    var productService = new ProductService();
    const [shops, setShops] = useState(null);

    const CreateGuid = () => {
        function _p8(s) {
            var p = (Math.random().toString(16) + "000000000").substr(2, 8);
            return s ? "-" + p.substr(0, 4) + "-" + p.substr(4, 4) : p;
        }

        return _p8() + _p8(true) + _p8(true) + _p8();
    }

    var id = CreateGuid()

    function openModal() {
        var container__modalSring = ".modal_" + id;
        var modale__showstring = ".backgroundmodal_" + id
        if ($(container__modalSring).closest('.modal').length > 0) {
            let mymodal = $(container__modalSring).detach();
            $('body').append(mymodal)
            $('.modal').modal('hide')
            $('.modal_'+id).find('.submit').on('click',async function () {
                var shop = $(this).closest('.modal').find('select').val()
                await productService.send(props.product,shop)
                window.location.reload()
            })
        }
        $(container__modalSring).modal('show')
        $(modale__showstring).css({'opacity' : 0.5,'display' : 'block'})
        shopService.listAll().then(function (result) {
            var res = {}
            for (const country in result.getData()) {
                for (const state in result.getData()[country]) {
                    result.getData()[country][state].forEach(function (e) {
                        res[e['key']] = e['name']
                    })
                }

            }
            setShops(res)
        })
    }

    function closeModal(id) {
        var container__modalSring = ".modal_" + id;
        var modale__showstring = ".backgroundmodal_" + id
        $(container__modalSring).modal('hide')
        $(modale__showstring).css({'display' : 'none'})
    }
    return (
        <>
            <div>
                <button data-toggle="tooltip"
                        data-placement="top"
                        title={CacheExtension.getDataFromCache('SendModal')}
                        className={"btn actionLink"}
                        onClick={function () {openModal(props)}}>{props.icon}
                </button>
                <div>
                    <div id={'skModal'} className={"banner modal ModalQRCode " + "modal_" + id }>
                        <div className={"banner modal-dialog"}>
                            <div className={"banner modal-content"}>
                                <button type="button" className="closeModal close" data-dismiss="modal"
                                        aria-label="Close"><span aria-hidden="true"     onClick={() => closeModal(id)}
                                >×</span></button>
                                <h1 className={"QrTitle"}>{CacheExtension.getDataFromCache('SendToShop')}</h1>
                                <div className={'modal-body'}>
                                    <div className={'d-flex justify-content-between align-items-center'}>
                                        { (shops) ? (
                                            <>
                                                <div className={'form-group col-7'}>
                                                    <label htmlFor="shopSelect">{CacheExtension.getDataFromCache('LabelSelectShop')}</label>
                                                    <select className={'col-12'} name="shopSelect" id={CreateGuid()}>
                                                        {Object.keys(shops).map(function (key) {
                                                            return <option key={key} value={key}>{shops[key]}</option>
                                                        })}
                                                    </select>
                                                </div>
                                            </>
                                        ) : ( ''
                                        )
                                        }
                                        <div>
                                            <button className={'btn btn-primary submit'}>{CacheExtension.getDataFromCache("Validate")}</button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

SendToShopModal.propTypes = {
    product : PropTypes.string.isRequired,
    icon : PropTypes.element.isRequired,
}
