import '../../styles/login.css';
import '../../styles/sk.css';
import '../../styles/register.css';
import logo from "../../res/logo.png"
import {motion as m} from "framer-motion"
import React, {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import LoginService from "../Service/Bll/LoginService";
import Login from "./Login";

export default function Logout() {
    const navigate = useNavigate();
    var service = new LoginService();
    React.useEffect(() => {
        service.logout()
        navigate("/login")
    })
}