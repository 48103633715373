import React from "react";
import PropTypes from "prop-types";
import '../css/ProductCardDetail.css';
import '../css/bo.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {ProductShopModal} from "./ProductShopModal";



export const ProductCardSav = (props) => {
    return (
        <>
            <div className={'p-2'} style={{width : '500px',flex: '0 0 25%'}}>
                <div className="productCardShop">
                    <div className="productCardTitle text-center py-2" style={{minHeight:'50px'}}>
                        {props.data.title && <strong>{props.data.title}</strong>}
                        {props.data.subtitle && <div style={{fontSize:'10px'}}>{props.data.subtitle}</div>}
                    </div>
                    <div className={'productCardContent mb-1'}>
                        <div className={'productCardHeader p-2 d-flex justify-content-between w-100'}>
                            <div className={"text-left"}>
                                {
                                    Object.entries(props.data.optionsJson).map(([key, value], index) => (
                                        <div key={index}><strong>{key} : </strong>{value}</div>
                                    ))
                                }
                            </div>
                        </div>
                        <div className={'productCardPicture'} style={{backgroundImage : `url(${props.data.image})`,mixBlendMode:'multiply' }}></div>
                    </div>
                </div>

            </div>
        </>
    );
}

ProductCardSav.propTypes = {
    data : PropTypes.object.isRequired,
    variant : PropTypes.string
}
