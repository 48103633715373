import HelperService from "./HelperService";
import ApiMedias from "../Api/ApiMedias";

export default class MediaService {
    #helper;
    #api;

    constructor() {
        this.#api = new ApiMedias();
        this.#helper = new HelperService();
    }


    async read(key) {
        let res = await this.#api.read(key);
        return res;
    }
    async save(key, name) {
        await this.#api.save(key, name);
    }

}
