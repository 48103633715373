import '../../styles/login.css';
import '../../styles/sk.css';
import '../../styles/register.css';
import logo from "../../res/logo512.png"
import {motion as m} from "framer-motion"
import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import LoginService from "../Service/Bll/LoginService";
import CacheExtension from "../Filter/CacheExtension";
import Consts from "../../App/Consts";

export default function Login() {
    const [error, setError] = useState(false);
    const navigate = useNavigate();
    const [openForget, setOpenForget] = useState(false);
    const [errors, setErrors] = useState({});
    const [checkMailMessage, setCheckMailMessage] = useState(false);
    const [errorForget, setErrorForget] = useState(false);
    const [formRecover, setFormRecover] = useState(null);

    var service = new LoginService();
    var url = JSON.parse(localStorage.getItem(Consts.GROUP_HOME));

    useEffect(() => {
        if (sessionStorage['isLogged'] !== undefined && sessionStorage['isLogged'] === 'yes'){
            navigate(url[sessionStorage['profile']].text)
        }
    })
    const handleCloseForget = () => {
        setOpenForget(false);
    };

    const handleOpenForget = () => {
        setOpenForget(true);
    };

    const state = {
        name: '',
        password: '',
    }

    const handleChangeLogin = event => {
        state.name = event.target.value;
    }
    const handleChangePassword = event => {
        state.password = event.target.value;
    }

    const  handleSubmit = async event => {
        event.preventDefault();
        const user = {
            username: document.getElementById('sk_username').value,
            password: document.getElementById('sk_password').value,
            service: "back"
        };
        var result = await service.login(user);
        if (result.getCode() === 0) {
            if (url) {
                navigate(url[sessionStorage['profile']].text)
            }
        } else {
            setError(true)
        }
    }

    const handleFormRecoverChange = event => {
        if(event.target.name == "username") {
            setFormRecover({
                ...formRecover,
                [event.target.name]:event.target.value.toLowerCase()
            });
        } else {
            setFormRecover({
                ...formRecover,
                [event.target.name]:event.target.value
            });
        }
    }
    const submitRecover = async (e) => {
        e.preventDefault();
        setErrorForget(false)
        if(formRecover) {
            let res = await service.recover(formRecover);
            if(res.getCode() === 0 ) {
                setCheckMailMessage(true)
            } else {
                setErrorForget(true)
            }
        }
    };

    return (
        <>
            <m.div initial={{ opacity : 0}} animate={{ opacity: 1}} transition={{duration: 0.50, ease: "easeOut"}}>
                <div className="row mt-5">
                    <div className="offset-1 offset-sm-2 offset-xl-3 col-10 col-sm-8 col-xl-6 tableBackground mt-5">
                        <div className="login-form text-center pt-3 pb-3">
                            <div id="header">
                                <div className="row cm-color-title">
                                    <div className="col-12 text-center">
                                        <img
                                            src={logo}
                                            className="logoHeader mb-3 mt-3"
                                            style={{height: 150}}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-10 offset-1">
                                    <form  onSubmit={handleSubmit} >
                                        <div className="form-group">
                                            <label htmlFor="sk_username" className="required">
                                                {CacheExtension.getDataFromCache('LoginUsername')}
                                            </label>
                                            <input
                                                onChange={handleChangeLogin}
                                                type="text"
                                                id="sk_username"
                                                required="required"
                                                name="name"
                                                autoFocus="autofocus"
                                                data-toggle="tooltip"
                                                data-placement="left"
                                                title=""
                                                className="form-control"
                                                data-original-title="{{'MandatoryField'|cache }}"
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="sk_password" className="required">
                                                {CacheExtension.getDataFromCache('LoginPassword')}
                                            </label>
                                            <input
                                                type="password"
                                                onChange={handleChangePassword}
                                                id="sk_password"
                                                name="password"
                                                required="required"
                                                data-toggle="tooltip"
                                                data-placement="left"
                                                title=""
                                                className="form-control"
                                                data-original-title="{{'MandatoryField'|cache }}"
                                            />
                                        </div>
                                        <div className="form-group">
                                            <button id="login_form_save" className="button submitform btn">
                                                {CacheExtension.getDataFromCache('LoginLogButton')}
                                            </button>
                                        </div>
                                        {error && <div className="showError">{CacheExtension.getDataFromCache('LoginErrorBo')}</div>}
                                    </form>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-10 offset-1 text-center">
                                    <div onClick={handleOpenForget} className="actionLink link">{CacheExtension.getDataFromCache('ForgottenPassword')}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {openForget && (
                    <div className="modal-container">
                        <div className="modal-overlay" onClick={handleCloseForget}></div>
                        <div className="modal-dialog w-75" style={{maxWidth: "none"}}>
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">{CacheExtension.getDataFromCache('ForgottenTitle')}</h5>
                                    <button type="button" className="close" onClick={handleCloseForget}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <form onSubmit={(e) => submitRecover(e)}>
                                    <div className="modal-body">
                                        <div id="confirmUser_Form">
                                            <div id="confirmUser_Group_confirmToken_email" className="form-inline mb-2">
                                                <label htmlFor="confirmUser_Control_confirmToken_email"
                                                       className="col-4 sk-auto-form sk-form-label font-weight-bold"
                                                       style={{justifyContent: "left"}}>{CacheExtension.getDataFromCache("email")}</label>
                                                <input id="confirmUser_Control_confirmToken_email" type="email" className="col-8 form-control sk-auto-form sk-form-email" name="username" onChange={handleFormRecoverChange} />
                                                {errors.mails && <div className="showError">{errors.mails}</div>}
                                                {errorForget && <div className="showError">{CacheExtension.getDataFromCache("errorForget")}</div>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={'modal-footer'}>
                                        <div className={'d-flex justify-content-center align-items-center'}>
                                            <div>
                                                <button disabled={checkMailMessage} type='submit' className={'btn btnBo submit'}>{CacheExtension.getDataFromCache("Validate")}</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                {checkMailMessage &&
                                    <div className="modal-body">
                                        <div>{CacheExtension.getDataFromCache('CheckYourMails')}</div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                )}
            </m.div>
        </>
    );
}


