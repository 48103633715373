// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
    background: #f7fbfd;
}
h1 {
    font-weight:bold;
    text-align:center;
}
h1,h2,h3,h4,h5 {
    font-family: OpenSans !important;
}


p {
    font-size: 1.1em;
    font-weight: 300;
    line-height: 1.7em;
    font-family: OpenSans;
}

a,
a:hover,
a:focus {
    color: inherit;
    text-decoration: none;
    font-family: OpenSans;
}

i,
span {
    display: inline-block;
    font-family: OpenSans;
}

strong {
    font-family: OpenSans;
}

button {
    font-family: OpenSans;
}

label {
    font-family: OpenSans;
}

div {
    font-family: OpenSans;
}



a.labelDebug {
    cursor: pointer;
    font-weight: bold;
    text-decoration: underline !important;
}

.btncancel {
    margin-left: 18px;
}`, "",{"version":3,"sources":["webpack://./src/styles/index.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB;AACA;IACI,gBAAgB;IAChB,iBAAiB;AACrB;AACA;IACI,gCAAgC;AACpC;;;AAGA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,kBAAkB;IAClB,qBAAqB;AACzB;;AAEA;;;IAGI,cAAc;IACd,qBAAqB;IACrB,qBAAqB;AACzB;;AAEA;;IAEI,qBAAqB;IACrB,qBAAqB;AACzB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,qBAAqB;AACzB;;;;AAIA;IACI,eAAe;IACf,iBAAiB;IACjB,qCAAqC;AACzC;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":["body {\n    background: #f7fbfd;\n}\nh1 {\n    font-weight:bold;\n    text-align:center;\n}\nh1,h2,h3,h4,h5 {\n    font-family: OpenSans !important;\n}\n\n\np {\n    font-size: 1.1em;\n    font-weight: 300;\n    line-height: 1.7em;\n    font-family: OpenSans;\n}\n\na,\na:hover,\na:focus {\n    color: inherit;\n    text-decoration: none;\n    font-family: OpenSans;\n}\n\ni,\nspan {\n    display: inline-block;\n    font-family: OpenSans;\n}\n\nstrong {\n    font-family: OpenSans;\n}\n\nbutton {\n    font-family: OpenSans;\n}\n\nlabel {\n    font-family: OpenSans;\n}\n\ndiv {\n    font-family: OpenSans;\n}\n\n\n\na.labelDebug {\n    cursor: pointer;\n    font-weight: bold;\n    text-decoration: underline !important;\n}\n\n.btncancel {\n    margin-left: 18px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
