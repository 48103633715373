import HelperService from "../../../StarterKit/Service/Bll/HelperService";
import ApiModel from "../Api/ApiModel";

export default class ModelService {
    #helper;
    #api;

    constructor() {
        this.#api = new ApiModel();
        this.#helper = new HelperService();
    }


    async list() {
        const result = await this.#api.list();
        return result;
    }

    async listAll() {
        const result = await this.#api.listAll();
        return result;
    }

    async listSav() {
        const result = await this.#api.listSav();
        return result;
    }

    async add(model,serial,sealnumber,sealToken,authPdf) {
        const result = await this.#api.add(model,serial,sealnumber,sealToken,authPdf);
        return result;
    }

    async import(file) {
        const result = await this.#api.import(file);
        return result;
    }

    async listSkus() {
        const result = await this.#api.listSkus();
        return result;
    }

    async listFromSku(sku) {
        const result = await this.#api.listFromSku(sku);
        return result;
    }

    async register(serial,sku) {
        const result = await this.#api.register(serial,sku);
        return result;
    }

    async lock(key) {
        const result = await this.#api.lock(key);
        return result;
    }

    async unlock(key) {
        const result = await this.#api.unlock(key);
        return result;
    }

    async listForAllSku() {
        const result = await this.#api.listForAllSku();
        return result;
    }
}
