import CacheExtension from "../../StarterKit/Filter/CacheExtension";
import React, {useEffect, useState,} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconActionButton} from "./IconActionButton";
import ProductService from "../Services/Bll/ProductService";
import {Dropdown} from 'semantic-ui-react'

export const SavCloseModal = ({item}) => {
    const service = new ProductService()

    const initialForm = {
        closingType: '',
        closingComment: ''
    };
    const [open, setOpen] = useState(false)
    const [form, setForm] = useState(initialForm)

    $(function () {
        $('[data-toggle="tooltip"]').tooltip()
    })

    const handleClose = () => {
        setOpen(false);
        setForm(initialForm)
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleChange = event => {
        setForm({
            ...form,
            [event.target.name]:event.target.value
        });
    }

    return (
        <>
            <button className="btn btnBo" style={{height:"40px"}} onClick={handleOpen}>
                {CacheExtension.getDataFromCache('CloseSav')}
            </button>
            {open && item && (
                <div className="modal-container">
                    <div className="modal-overlay" onClick={handleClose}></div>
                    <div className="modal-dialog w-50" style={{maxWidth: "none", height: "90%"}}>
                        <div className="modal-content" style={{height: "100%"}}>
                            <div className="modal-header pb-0">
                                <h1 className="QrTitle mb-2">{CacheExtension.getDataFromCache("CloseSav")}</h1>
                                <button type="button" className="close" onClick={handleClose}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            {item ?
                                <div className="fullSize modal-body">
                                    <div className={'form-group'}>
                                        <label className={'mb-1 productModalLabel'}>{CacheExtension.getDataFromCache("SavType")}</label>
                                        <p>{CacheExtension.getDataFromCache(item.sav.type)}</p>
                                    </div>
                                    <div className={'form-group'}>
                                        <label className={'mb-1 productModalLabel'}>{CacheExtension.getDataFromCache("AuthResult")}</label>
                                        <p>{CacheExtension.getDataFromCache(item.sav.authentication)}</p>
                                    </div>
                                    <div className={'form-group'}>
                                        <label className={'mb-1 productModalLabel'}>{CacheExtension.getDataFromCache("SealNumber")}</label>
                                        <p>{item.sealNumber}</p>
                                    </div>
                                    <div className={'form-group'}>
                                        <label className={'mb-1 productModalLabel'}>{CacheExtension.getDataFromCache("SealScore")}</label>
                                        <p>{item.sav.sealScore}</p>
                                    </div>
                                    <div className={'form-group'}>
                                        <label className={'mb-1 productModalLabel'}>{CacheExtension.getDataFromCache("Comment")}</label>
                                        <p>{item.sav.comment}</p>
                                    </div>
                                    <form>
                                        <div className="form-row">
                                            <div className={'form-group'}>
                                                <label className={'mb-1'}><strong>* {CacheExtension.getDataFromCache("ClosingTypeLabel")}</strong></label>
                                                <Dropdown
                                                    style={{width: '200px'}}
                                                    placeholder={CacheExtension.getDataFromCache('SelectClosingType')}
                                                    fluid
                                                    search
                                                    selection
                                                    onChange={(e, {value}) => {
                                                        setForm({
                                                            ...form,
                                                            closingType: value
                                                        })
                                                    }}
                                                    options={[
                                                        {
                                                            key: 1,
                                                            value: "returnedOwner",
                                                            text: CacheExtension.getDataFromCache('returnedOwner')
                                                        },
                                                        {
                                                            key: 2,
                                                            value: "seizedCounterfeit",
                                                            text: CacheExtension.getDataFromCache('seizedCounterfeit')
                                                        },
                                                        {
                                                            key: 3,
                                                            value: "finalReturn",
                                                            text: CacheExtension.getDataFromCache('finalReturn')
                                                        }
                                                    ]}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-row d-block">
                                            <div className="form-group">
                                                <label htmlFor="closingComment"><strong>* {CacheExtension.getDataFromCache('Comment')}</strong></label>
                                                <textarea value={form.closingComment} className="form-control" type='text' name="closingComment" onChange={handleChange}/>
                                            </div>
                                        </div>
                                        <div className="form-row justify-content-end">
                                            <button className="btn btnBo" disabled={!form.closingType || !form.closingComment} onClick={async (event) => {
                                                event.preventDefault()
                                                await service.closeSav(item.sav.key, form)
                                                window.location.reload();
                                            }}>{CacheExtension.getDataFromCache('Validate')}</button>
                                        </div>
                                    </form>
                                </div> :
                                <div className="m-3">{CacheExtension.getDataFromCache('NoUser')}</div>
                            }
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
