import React, {useEffect, useState} from "react";
import {motion as m} from "framer-motion";
import CacheExtension from "../../StarterKit/Filter/CacheExtension";
import 'semantic-ui-css/semantic.min.css'
import '../css/bo.css'
import '../css/Model.css'
import {Nav} from "../../components/navComponents";
import {Header} from "../../components/headerComponent";
import Products from "../Components/Products";
import {useParams} from "react-router-dom";

export default function ProductsAdmin() {

    let { search } = useParams();

    return (
        <>
            <m.div className={"wrapper"}>
                <>
                    <Nav />
                    <div id={"page"}>
                        <Header />
                        <div id="content">
                            <div className="card">
                                <div className="card-header">
                                    <h3>{CacheExtension.getDataFromCache('Instances')}</h3>
                                </div>
                                <Products pSearch={search}/>
                            </div>
                        </div>
                    </div>
                </>
            </m.div>
        </>
    );
}

