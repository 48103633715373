import HelperService from "../../../StarterKit/Service/Bll/HelperService";
import ApiShop from "../Api/ApiShop";

export default class ShopService {
    #helper;
    #api;

    constructor() {
        this.#api = new ApiShop();
        this.#helper = new HelperService();
    }


    async list() {
        const result = await this.#api.list();
        return result;
    }

    async listAll() {
        const result = await this.#api.listAll();
        return result;
    }

    async import(file) {
        const result = await this.#api.import(file);
        return result;
    }
}
