import React from "react";
import PropTypes from "prop-types";
import '../css/ProductCardDetail.css';
import '../css/bo.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {ProductShopModal} from "./ProductShopModal";



export const ProductCardShop = (props) => {
    return (
        <>
            <div className={'p-2'} style={{minWidth : '300px',flex: '0 0 25%'}}>
                <div className="productCardShop">
                    <div className="productCardTitle text-center py-2" style={{minHeight:'50px'}}>
                        {props.data.title && <strong>{props.data.title}</strong>}
                        {props.data.subtitle && <div style={{fontSize:'10px'}}>{props.data.subtitle}</div>}
                    </div>
                    <div className={'productCardContent mb-1'}>
                        <div className={'productCardHeader p-2 d-flex justify-content-between w-100'}>
                            <div className={"text-left"}>
                                {
                                    Object.entries(props.data.options).map(([key, value], index) => (
                                        <div key={index}><strong>{key} : </strong>{value}</div>
                                    ))
                                }
                            </div>
                        </div>
                        <div className={'productCardPicture'} style={{backgroundImage : `url(${props.data.image})`,mixBlendMode:'multiply' }}></div>
                        {(props.data.stock !== undefined) &&
                            <>
                                <div className="d-flex align-items-center p-2 w-100 justify-content-between">
                                    <span className="mr-2 mb-2"> stock : {props.data.stock}</span>
                                    {props.data.stock !== 0 && (
                                        <ProductShopModal icon={<FontAwesomeIcon icon="fa-solid fa-list" />} model={props.data.key}></ProductShopModal>
                                    )}
                                </div>
                            </>
                        }
                    </div>
                </div>

            </div>
        </>
    );
}

ProductCardShop.propTypes = {
    data : PropTypes.object.isRequired,
    variant : PropTypes.string,
    customButtons : PropTypes.array,
    buttons : PropTypes.bool,
}
