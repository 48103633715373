import React, {useEffect, useState} from "react";
import {motion as m} from "framer-motion";
import CacheExtension from "../../StarterKit/Filter/CacheExtension";
import {HeaderComponent} from "../Components/HeaderComponent";
import 'semantic-ui-css/semantic.min.css'
import '../css/bo.css'
import ProductService from "../Services/Bll/ProductService";
import {ProductInvoiceModal} from "../Components/ProductInvoiceModal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconActionButton} from "../Components/IconActionButton";
import {ProductModal} from "../Components/ProductModal";
import {UserModal} from "../Components/UserModal";
import {SavDetailsModal} from "../Components/SavDetailsModal";
import {SavCloseModal} from "../Components/SavCloseModal";
import {Link} from "react-router-dom";

export default function ManageSav() {
    let currentDate = new Date().toISOString().split('T')[0];

    const initialForm = {
        date: currentDate
    };
    const service = new ProductService();
    const [data, setData] = useState(null);
    const [nbSav, setNbSav] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [skuSearch, setSkuSearch] = useState("");
    const [variantIdSearch, setVariantIdSearch] = useState("");
    const [serialSearch, setSerialSearch] = useState("");
    const [orderField, setOrderField] = useState("date");
    const [orderDirection, setOrderDirection] = useState("desc");

    useEffect(() => {
        service.listAllSav(currentPage, skuSearch, variantIdSearch, serialSearch, orderField, orderDirection).then(function (res) {
            setData(res.getData())
            setNbSav(res.getContext().count)
        })

    }, [currentPage, skuSearch, variantIdSearch, serialSearch, orderField, orderDirection])

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    return (
        <>
                <m.div className={"wrapper"}>
                    <div id={"page"}>
                        <HeaderComponent logo={'/pictures/Logo_Pequignet.png'}
                                         title={CacheExtension.getDataFromCache('Sav')}></HeaderComponent>
                        <div id="content">
                            <div id={"page"}>
                                <div className="d-flex justify-content-end" style={{padding: "2rem 2rem 2rem 1.25rem"}}>
                                    <div className={"d-flex flex-column"}>
                                        <Link className="btn btnBo mb-2" to="/models">
                                            {CacheExtension.getDataFromCache('Models')}
                                        </Link>
                                        <Link className="btn btnBo" to="/products">
                                            {CacheExtension.getDataFromCache('Instances')}
                                        </Link>
                                    </div>
                                </div>
                                {data &&
                                    <>
                                        <div className="row" style={{padding: "2rem 2rem 2rem 1.25rem"}}>
                                            <div className="col-7">
                                                <span className={"nbModel"}>{CacheExtension.getDataFromCache('NbInstances')} : <strong>{nbSav}</strong></span>
                                            </div>
                                            <div className="col-5 d-flex justify-content-end">
                                                <Link className="btn btnBo w-50" to="/sav/add">
                                                    {CacheExtension.getDataFromCache('AddSav')}
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="row" style={{margin: "0"}}>
                                            <div className="col-3">
                                                <div className="input-group w-75 mb-3">
                                                    <input
                                                        type="text"
                                                        placeholder={CacheExtension.getDataFromCache('Serial')}
                                                        className="form-control"
                                                        value={serialSearch}
                                                        onChange={e => {
                                                            setSerialSearch(e.target.value)
                                                            setCurrentPage(1)
                                                        }}
                                                    />
                                                    <div className="input-group-append">
                                                        <button
                                                            className="btn btn-outline-secondary"
                                                            type="button"
                                                            onClick={()=> {
                                                                setSerialSearch("")
                                                                setCurrentPage(1)
                                                            }}
                                                        >
                                                            <svg
                                                                className="svg-inline--fa fa-xmark"
                                                                aria-hidden="true"
                                                                focusable="false"
                                                                data-prefix="fas"
                                                                data-icon="xmark"
                                                                role="img"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 320 512"
                                                                data-fa-i2svg=""
                                                            >
                                                                <path
                                                                    fill="currentColor"
                                                                    d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z"
                                                                />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="input-group w-75 mb-3">
                                                    <input
                                                        type="text"
                                                        placeholder={CacheExtension.getDataFromCache('Sku')}
                                                        className="form-control"
                                                        value={skuSearch}
                                                        onChange={e => {
                                                            setSkuSearch(e.target.value)
                                                            setCurrentPage(1)
                                                        }}
                                                    />
                                                    <div className="input-group-append">
                                                        <button
                                                            className="btn btn-outline-secondary"
                                                            type="button"
                                                            onClick={()=> {
                                                                setSkuSearch("")
                                                                setCurrentPage(1)
                                                            }}
                                                        >
                                                            <svg
                                                                className="svg-inline--fa fa-xmark"
                                                                aria-hidden="true"
                                                                focusable="false"
                                                                data-prefix="fas"
                                                                data-icon="xmark"
                                                                role="img"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 320 512"
                                                                data-fa-i2svg=""
                                                            >
                                                                <path
                                                                    fill="currentColor"
                                                                    d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z"
                                                                />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="input-group w-75 mb-3">
                                                    <input
                                                        type="text"
                                                        placeholder={CacheExtension.getDataFromCache('VariantId')}
                                                        className="form-control"
                                                        value={variantIdSearch}
                                                        onChange={e => {
                                                            setVariantIdSearch(e.target.value)
                                                            setCurrentPage(1)
                                                        }}
                                                    />
                                                    <div className="input-group-append">
                                                        <button
                                                            className="btn btn-outline-secondary"
                                                            type="button"
                                                            onClick={()=> {
                                                                setVariantIdSearch("")
                                                                setCurrentPage(1)
                                                            }}
                                                        >
                                                            <svg
                                                                className="svg-inline--fa fa-xmark"
                                                                aria-hidden="true"
                                                                focusable="false"
                                                                data-prefix="fas"
                                                                data-icon="xmark"
                                                                role="img"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 320 512"
                                                                data-fa-i2svg=""
                                                            >
                                                                <path
                                                                    fill="currentColor"
                                                                    d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z"
                                                                />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={"col-3 d-flex justify-content-end"}>
                                                <nav>
                                                    <ul className="pagination">
                                                        {[...Array(data.totalPages).keys()].map((page) => {
                                                            if (page === 0 || page === data.totalPages - 1 || (page >= currentPage - 2 && page <= currentPage) || (currentPage < 4 && page < 4) || (currentPage >= data.totalPages - 2 && page >= data.totalPages - 4)) {
                                                                return (
                                                                    <li key={page} className={`page-item ${currentPage === page + 1 ? 'active' : ''}`}>
                                                                        <button className={`btn ${currentPage === page + 1 ? 'btnBo' : 'btnBoReverse'}`} onClick={() => handlePageChange( page + 1)}>
                                                                            {page + 1}
                                                                        </button>
                                                                    </li>
                                                                );
                                                            } else {
                                                                return null;
                                                            }
                                                        })}
                                                    </ul>
                                                </nav>
                                            </div>
                                        </div>
                                        <div className={'modelsSection card-body pt-0'}>
                                            <div className="row mx-0 py-2" style={{borderBottom: '1px solid #dee2e6'}}>
                                                <div className="col-2">
                                                    <strong>{CacheExtension.getDataFromCache('Serial')}</strong>
                                                    {orderField == "serial" ?
                                                        (orderDirection == "asc" ?
                                                            <IconActionButton  action={() => {
                                                                setOrderDirection("desc")
                                                                setCurrentPage(1)
                                                            }} state={true} icon={<FontAwesomeIcon icon="fas fa-caret-up"/>}  className={"btn modelDetails px-1 py-0"} tooltip={CacheExtension.getDataFromCache('SortAsc')}/>
                                                            :
                                                            <IconActionButton  action={() => {
                                                                setOrderDirection("asc")
                                                                setCurrentPage(1)
                                                            }} state={true} icon={<FontAwesomeIcon icon="fas fa-caret-down"/>}  className={"btn modelDetails px-1 py-0"} tooltip={CacheExtension.getDataFromCache('SortDesc')}/>)
                                                        :
                                                        <IconActionButton  action={() => {
                                                            setOrderField("serial")
                                                            setOrderDirection("asc")
                                                            setCurrentPage(1)
                                                        } } state={true} icon={<FontAwesomeIcon icon="fas fa-sort"/>}  className={"btn modelDetails px-1 py-0"} tooltip={CacheExtension.getDataFromCache('SortDesc')}/>
                                                    }
                                                </div>
                                                <div className="col-2">
                                                    <strong>{CacheExtension.getDataFromCache('Sku')}</strong>
                                                    {orderField == "sku" ?
                                                        (orderDirection == "asc" ?
                                                            <IconActionButton  action={() => {
                                                                setOrderDirection("desc")
                                                                setCurrentPage(1)
                                                            }} state={true} icon={<FontAwesomeIcon icon="fas fa-caret-up"/>} className={"btn modelDetails px-1 py-0"} tooltip={CacheExtension.getDataFromCache('SortAsc')}/>
                                                            :
                                                            <IconActionButton  action={() => {
                                                                setOrderDirection("asc")
                                                                setCurrentPage(1)
                                                            }} state={true} icon={<FontAwesomeIcon icon="fas fa-caret-down"/>} className={"btn modelDetails px-1 py-0"} tooltip={CacheExtension.getDataFromCache('SortDesc')}/>)
                                                        :
                                                        <IconActionButton  action={() => {
                                                            setOrderField("sku")
                                                            setOrderDirection("asc")
                                                            setCurrentPage(1)
                                                        } } state={true} icon={<FontAwesomeIcon icon="fas fa-sort"/>} className={"btn modelDetails px-1 py-0"} tooltip={CacheExtension.getDataFromCache('SortDesc')}/>
                                                    }
                                                </div>
                                                <div className="col-2">
                                                    <strong>{CacheExtension.getDataFromCache('VariantId')}</strong>
                                                    {orderField == "variantId" ?
                                                        (orderDirection == "asc" ?
                                                            <IconActionButton  action={() => {
                                                                setOrderDirection("desc")
                                                                setCurrentPage(1)
                                                            }} state={true} icon={<FontAwesomeIcon icon="fas fa-caret-up"/>} className={"btn modelDetails px-1 py-0"} tooltip={CacheExtension.getDataFromCache('SortAsc')}/>
                                                            :
                                                            <IconActionButton  action={() => {
                                                                setOrderDirection("asc")
                                                                setCurrentPage(1)
                                                            }} state={true} icon={<FontAwesomeIcon icon="fas fa-caret-down"/>} className={"btn modelDetails px-1 py-0"} tooltip={CacheExtension.getDataFromCache('SortDesc')}/>)
                                                        :
                                                        <IconActionButton  action={() => {
                                                            setOrderField("variantId")
                                                            setOrderDirection("asc")
                                                            setCurrentPage(1)
                                                        } } state={true} icon={<FontAwesomeIcon icon="fas fa-sort"/>} className={"btn modelDetails px-1 py-0"} tooltip={CacheExtension.getDataFromCache('SortDesc')}/>
                                                    }
                                                </div>
                                                <div className="col-2">
                                                    <strong>{CacheExtension.getDataFromCache('StartDate')}</strong>
                                                    {orderField == "date" ?
                                                        (orderDirection == "asc" ?
                                                            <IconActionButton  action={() => {
                                                                setOrderDirection("desc")
                                                                setCurrentPage(1)
                                                            }} state={true} icon={<FontAwesomeIcon icon="fas fa-caret-up"/>} className={"btn modelDetails px-1 py-0"} tooltip={CacheExtension.getDataFromCache('SortAsc')}/>
                                                            :
                                                            <IconActionButton  action={() => {
                                                                setOrderDirection("asc")
                                                                setCurrentPage(1)
                                                            }} state={true} icon={<FontAwesomeIcon icon="fas fa-caret-down"/>} className={"btn modelDetails px-1 py-0"} tooltip={CacheExtension.getDataFromCache('SortDesc')}/>)
                                                        :
                                                        <IconActionButton  action={() => {
                                                            setOrderField("date")
                                                            setOrderDirection("desc")
                                                            setCurrentPage(1)
                                                        } } state={true} icon={<FontAwesomeIcon icon="fas fa-sort"/>} className={"btn modelDetails px-1 py-0"} tooltip={CacheExtension.getDataFromCache('SortDesc')}/>
                                                    }
                                                </div>
                                                <div className={"col-4"}>
                                                    <div className={"row"}>
                                                        <div className="col-4 d-flex justify-content-center">
                                                            <strong>{CacheExtension.getDataFromCache('User')}</strong>
                                                        </div>
                                                        <div className="col-4 d-flex justify-content-center">
                                                            <strong>{CacheExtension.getDataFromCache('Details')}</strong>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {data && data.products.length > 0 ?
                                                <div className="">
                                                    {data.products.map((item, j) => (
                                                            <div key={j} className={'' + (j % 2 === 0 ? 'oddTableLine' : 'evenTableLine')}>
                                                                <div className="row mx-0" style={{height: "90px"}}>
                                                                    <div className="col-2 d-flex align-items-center">
                                                                        <p>{item.product.serial}</p>
                                                                    </div>
                                                                    <div className="col-2 d-flex align-items-center">
                                                                        <p>{item.product.modelId}</p>
                                                                    </div>
                                                                    <div className="col-2 d-flex align-items-center">
                                                                        <p>{item.variantId}</p>
                                                                    </div>
                                                                    <div className="col-2 d-flex align-items-center">
                                                                        <p>{item.sav.date.split('T')[0]}</p>
                                                                    </div>
                                                                    <div className={"col-4"}>
                                                                        <div className={"row h-100"}>
                                                                            <div className="col-4 d-flex justify-content-center align-items-center">
                                                                                {item.product.ownerKey ?
                                                                                    <UserModal owner={item.product.ownerKey}/>
                                                                                    :
                                                                                    <IconActionButton action={() => { return }} state={false} className={"btn"} icon={<FontAwesomeIcon icon="fas fa-user"/>}  tooltip={CacheExtension.getDataFromCache('NoUser')}/>
                                                                                }
                                                                            </div>
                                                                            <div className="col-4 d-flex justify-content-center align-items-center">
                                                                                <SavDetailsModal item={item}/>
                                                                            </div>
                                                                            <div className="col-4 d-flex justify-content-center align-items-center">
                                                                                <SavCloseModal item={item}/>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    )}
                                                </div>
                                                :
                                                <p className="text-center mt-2">{CacheExtension.getDataFromCache('NoProductMatch')}</p>
                                            }
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </m.div>
        </>
    );
}

