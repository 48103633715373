// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.productCardContent{
    display: flex;
    flex-direction: column;
    height: 35vh;
    color: #3c3c3b;
    align-items: center;
    justify-content: space-around;
    background-color: #eee;

}
.productCardPicture{
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    flex-grow: 1;
    width: 100%;
}

.productCardContent{
    font-family: OpenSans;
}

.typeTitle{
    font-family: OpenSans !important;
    font-weight: bold;

}

.subTypeTitle{
    font-family: OpenSans;
}

.header__stock {
   display: flex;
    justify-content: space-between;
    align-items: center;
}

.addbutton {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.addbutton #newButtonScan {
    max-width: 200px;
    width: 150px;
    margin: inherit;
    margin-top: 0;
}

@media only screen and (max-width: 700px) {
    .modelsContainer{
        justify-content: center;
    }
}

.productCardTitle {
    border-bottom: rgb(255, 158, 23) solid 1px;
}
`, "",{"version":3,"sources":["webpack://./src/App/css/ProductCardDetail.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,sBAAsB;IACtB,YAAY;IACZ,cAAc;IACd,mBAAmB;IACnB,6BAA6B;IAC7B,sBAAsB;;AAE1B;AACA;IACI,4BAA4B;IAC5B,2BAA2B;IAC3B,wBAAwB;IACxB,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,gCAAgC;IAChC,iBAAiB;;AAErB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;GACG,aAAa;IACZ,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,yBAAyB;AAC7B;;AAEA;IACI,gBAAgB;IAChB,YAAY;IACZ,eAAe;IACf,aAAa;AACjB;;AAEA;IACI;QACI,uBAAuB;IAC3B;AACJ;;AAEA;IACI,0CAA0C;AAC9C","sourcesContent":["\n.productCardContent{\n    display: flex;\n    flex-direction: column;\n    height: 35vh;\n    color: #3c3c3b;\n    align-items: center;\n    justify-content: space-around;\n    background-color: #eee;\n\n}\n.productCardPicture{\n    background-repeat: no-repeat;\n    background-position: center;\n    background-size: contain;\n    flex-grow: 1;\n    width: 100%;\n}\n\n.productCardContent{\n    font-family: OpenSans;\n}\n\n.typeTitle{\n    font-family: OpenSans !important;\n    font-weight: bold;\n\n}\n\n.subTypeTitle{\n    font-family: OpenSans;\n}\n\n.header__stock {\n   display: flex;\n    justify-content: space-between;\n    align-items: center;\n}\n\n.addbutton {\n    width: 100%;\n    display: flex;\n    justify-content: flex-end;\n}\n\n.addbutton #newButtonScan {\n    max-width: 200px;\n    width: 150px;\n    margin: inherit;\n    margin-top: 0;\n}\n\n@media only screen and (max-width: 700px) {\n    .modelsContainer{\n        justify-content: center;\n    }\n}\n\n.productCardTitle {\n    border-bottom: rgb(255, 158, 23) solid 1px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
