import React from "react";
import '../css/header.css';
import {LogoComponent} from "./LogoComponent";
import {IconActionButton} from "./IconActionButton";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../Picture/Logo_Pequignet.png'
import PropTypes from "prop-types";
import CacheExtension from "../../StarterKit/Filter/CacheExtension";
export const HeaderComponent = (props) => {
    var lastname = ""
    if (sessionStorage['lastname']) {
        lastname = sessionStorage['profile'];
    }
    return (
        <>
            <div className={'container__nav__bo'} style={{borderBottom: "4px solid #ff9e17"}}>
                <div className={'container__header container__logo'}>
                    { (props.logo) ? (
                        <LogoComponent logo={props.logo} />
                    ) : ( '')
                    }
                </div>
                <div className={'title container__header'} >
                    { (props.title) ? (
                        <h1 id={"walletTitle"}>{props.title}</h1>
                    ) : ( '')
                    }
                </div>
                <div className={'nameUsers container__header'}>
                    { (lastname) ? (
                            <p className={"profilName"} id={'profilName'}>{lastname}</p>

                    ) : ( '')
                    }
                    <IconActionButton action={() => document.location.href = '/logout'  } state={true} icon={<FontAwesomeIcon icon="fa-solid fa-right-from-bracket" />} tooltip={CacheExtension.getDataFromCache('logout')}></IconActionButton>
                </div>
                <div className={"modal-backdrop fade"}>

                </div>
                <div className={'container__modal'}>

                </div>
            </div>
        </>
    );
}

HeaderComponent.prototype= {
    title : PropTypes.string,
    logo : PropTypes.string,
}

