import CacheExtension from "../../StarterKit/Filter/CacheExtension";
import React, {useEffect, useState,} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconActionButton} from "./IconActionButton";
import ProductService from "../Services/Bll/ProductService";

export const ProductDetails = ({product}) => {
    const service = new ProductService();

    const [open, setOpen] = useState(false)
    const [data, setData] = useState(null)

    $(function () {
        $('[data-toggle="tooltip"]').tooltip()
    })

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        service.listSavForProduct(product.key).then((res) => {
            setData(res.getData())
            setOpen(true)
        })
    };

    return (
        <>
            <IconActionButton tooltip={CacheExtension.getDataFromCache("Details")} action={handleOpen} state={true} className={"btn modelDetails"} icon={<FontAwesomeIcon icon="fas fa-circle-info" />}></IconActionButton>
            {open && (
                <div className="modal-container">
                    <div className="modal-overlay" onClick={handleClose}></div>
                    <div className="modal-dialog w-50" style={{maxWidth: "none", height: "90%"}}>
                        <div className="modal-content" style={{height: "100%"}}>
                            <div className="modal-header">
                                <h5 className="modal-title">{product.serial}</h5>
                                <button type="button" className="close" onClick={handleClose}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="fullSize modal-body model-modal">
                                {data.sealVectors &&
                                    <div className="mb-5">
                                        <h4 className="mb-4">{CacheExtension.getDataFromCache("SealVectors")}</h4>
                                        {(data.sealVectors && (data.sealVectors.watch || data.sealVectors.card)) ?
                                            <>
                                                {data.sealVectors.watch &&
                                                    <div className={'form-group'}>
                                                        <label className={'mb-1 productModalLabel'}>{CacheExtension.getDataFromCache("watchLabel")}</label>
                                                        <p>{data.sealVectors.watch}</p>
                                                    </div>
                                                }
                                                {data.sealVectors.card &&
                                                    <div className={'form-group'}>
                                                        <label className={'mb-1 productModalLabel'}>{CacheExtension.getDataFromCache("authCardLabel")}</label>
                                                        <p>{data.sealVectors.card}</p>
                                                    </div>
                                                }
                                            </> :
                                            <p>{CacheExtension.getDataFromCache("NoSV")}</p>
                                        }
                                    </div>
                                }
                                <div className="mb-5">
                                    <h4 className="mb-4">{CacheExtension.getDataFromCache("Sale")}</h4>
                                    {(product.sale && (product.sale.navision || product.sale.comment)) ?
                                        <>
                                            {product.sale.navision &&
                                                <div className={'form-group'}>
                                                    <label className={'mb-1 productModalLabel'}>Navision</label>
                                                    <p>{product.sale.navision}</p>
                                                </div>
                                            }
                                            {product.sale.comment &&
                                                <div className={'form-group'}>
                                                    <label className={'mb-1 productModalLabel'}>{CacheExtension.getDataFromCache("Comment")}</label>
                                                    <p>{product.sale.comment}</p>
                                                </div>
                                            }
                                        </> :
                                        <p>{CacheExtension.getDataFromCache("NoSell")}</p>
                                    }
                                </div>
                                {data.savs &&
                                    <div className="mb-5">
                                        <h4 className="mb-4">{CacheExtension.getDataFromCache("Sav")}</h4>
                                        {data.savs.length > 0 ?
                                            <>
                                            {data.savs.map((sav, i) =>
                                                <div className={i < data.length - 1 ? "line-separator mb-3" : "mb-3"} key={i}>
                                                    <div className={'form-group'}>
                                                        <label className={'mb-1 productModalLabel'}>{CacheExtension.getDataFromCache("SavState")}</label>
                                                        <p>{CacheExtension.getDataFromCache(sav.state)}</p>
                                                    </div>
                                                    <div className={'form-group'}>
                                                        <label className={'mb-1 productModalLabel'}>{CacheExtension.getDataFromCache("SavType")}</label>
                                                        <p>{CacheExtension.getDataFromCache(sav.type)}</p>
                                                    </div>
                                                    <div className={'form-group'}>
                                                        <label className={'mb-1 productModalLabel'}>{CacheExtension.getDataFromCache("OpenDate")}</label>
                                                        <p>{sav.date.split('T')[0]}</p>
                                                    </div>
                                                    <div className={'form-group'}>
                                                        <label className={'mb-1 productModalLabel'}>{CacheExtension.getDataFromCache("Authentication")}</label>
                                                        <p>{CacheExtension.getDataFromCache(sav.authentication)}</p>
                                                    </div>
                                                    <div className={'form-group'}>
                                                        <label className={'mb-1 productModalLabel'}>{CacheExtension.getDataFromCache("SealScore")}</label>
                                                        <p>{sav.sealScore}</p>
                                                    </div>
                                                    <div className={'form-group'}>
                                                        <label className={'mb-1 productModalLabel'}>{CacheExtension.getDataFromCache("Comment")}</label>
                                                        <p>{sav.comment}</p>
                                                    </div>
                                                    {sav.closingType &&
                                                        <div className={'form-group'}>
                                                            <label className={'mb-1 productModalLabel'}>{CacheExtension.getDataFromCache("ClosingType")}</label>
                                                            <p>{CacheExtension.getDataFromCache(sav.closingType)}</p>
                                                        </div>
                                                    }
                                                    {sav.closingDate &&
                                                        <div className={'form-group'}>
                                                            <label className={'mb-1 productModalLabel'}>{CacheExtension.getDataFromCache("ClosingDate")}</label>
                                                            <p>{sav.closingDate.split('T')[0]}</p>
                                                        </div>
                                                    }
                                                    {sav.closingComment &&
                                                        <div className={'form-group'}>
                                                            <label className={'mb-1 productModalLabel'}>{CacheExtension.getDataFromCache("ClosingComment")}</label>
                                                            <p>{sav.closingComment}</p>
                                                        </div>
                                                    }
                                                </div>
                                            )}
                                            </> :
                                            <p>{CacheExtension.getDataFromCache("NoSav")}</p>
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
