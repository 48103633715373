import {BaseApiService} from "../BaseApiService";
import HelperService from "../Bll/HelperService";
import configData from "../../../config.json";
import { saveAs } from 'file-saver';

/*******************************************************************
 * Diji Starter Kit
 *
 * ApiLogin
 *******************************************************************/
export default class ApiMedias extends BaseApiService {
    /*******************************************************************
     * public function
     *******************************************************************/


    async save(key, name) {
        var helperService = new HelperService();
        var token = await this.getServiceToken();
        let response = await fetch(configData.SERVER_URL +sessionStorage['language'] + "/medias/" + key,{
            headers: {
                'Accept': '*/*',
                'Content-Type': '*',
                'Access-Control-Allow-Origin' : '*',
                "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
                "Access-Control-Allow-Headers": "Content-Type, Authorization, X-Requested-With",
                'Authorization': 'bearer '+ token
            },
            method : 'GET'
        })
        let blob = await response.blob();
        saveAs(blob, name)
    }

    async read(key) {
        var token = await this.getServiceToken();
        let response = await fetch(configData.SERVER_URL +sessionStorage['language'] + "/medias/" + key,{
            headers: {
                'Accept': '*/*',
                'Content-Type': '*',
                'Access-Control-Allow-Origin' : '*',
                "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
                "Access-Control-Allow-Headers": "Content-Type, Authorization, X-Requested-With",
                'Authorization': 'bearer '+ token
            },
            method : 'GET'
        })
        let blob = await response.blob();
        return blob
    }
}
