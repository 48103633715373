import ApiLogin from "../Api/ApiLogin";
import ApiResult from "../../Model/ApiResult";
import HelperService from "./HelperService";
import ApiSettings from "../Api/ApiSettings";
import ApiGroups from "../Api/ApiGroups";
import ApiLanguages from "../Api/ApiLanguages"

export default class SettingService {
    #helper;
    #api;
    #apiGroups;
    #apiLanguages;


    constructor() {
        this.#api = new ApiSettings();
        this.#helper = new HelperService();
        this.#apiGroups = new ApiGroups();
        this.#apiLanguages = new ApiLanguages();
    }


    async getFromGroup(group) {
        // cast as ApiResult
        var result = Object.assign(new ApiResult(),await this.#api.getFromGroup(group))
        return result;
    }

    async list(group = "")  {
        console.debug("[Diji SK] - Service - SettingService - list : " + sessionStorage['language'] + "/" + group);

        var result = new ApiResult();
        var temp = await this.#apiGroups.getGroups()
        if (temp.getCode() === 0){
            var groups = [];
            temp.getData().forEach(function(gr) {
                groups[ gr['key'] ] = gr['key']; // TODO api must return name as well
                var create = (gr['create'] == 1) ? true : false;
                var update = (gr['update'] == 1) ? true : false;
                var deleteVar = (gr['delete'] == 1) ? true : false;
                var needKey = (gr['needkey'] == 1) ? true : false;
            });
            if (group === "" && temp.getData()[0]) {
                var create = (temp.getData()[0]['create'] == 1) ? true : false;
                var update = (temp.getData()[0]['update'] == 1) ? true : false;
                var deleteVar = (temp.getData()[0]['delete'] == 1) ? true : false;
                var needKey = (temp.getData()[0]['needkey'] == 1) ? true : false;
                group = temp.getData()[0]['key'];
                var form = temp.context.forms
            }

            var settings = [];
            var page = 1;
            var pageCount = 1;

            if (group !== "") {
                while ((page <= pageCount) && (result.getCode() === 0)) {
                    temp = await this.#api.getSettings( group, page);
                    if (temp.getCode() === 0) {
                        pageCount = temp.getContext()['pageCount'];
                        let tmpData = temp.getData()
                        for (var i in tmpData) {
                            settings[i] = {
                                form : temp.context.forms,
                                text : tmpData[i]['text'],
                                numeric : tmpData[i]['numeric'],
                                extra : tmpData[i]['extra']
                            }
                        }
                        page ++;
                    } else {
                        result.setCode(temp.getCode());
                        result.setMessage(temp.getMessage());
                    }
                }
            }
        } else {
            result.setCode(temp.getCode());
            result.setMessage(temp.getMessage());
        }

        if (result.getCode() === 0) {
            result.setData({
                groups : typeof groups === 'undefined' ? []: groups,
                settings : typeof settings === 'undefined'? []: settings,
                definition : temp.context.definition,
                form : typeof form === 'undefined'? []: temp.context.forms,
                create : typeof create === 'undefined'? []: create,
                update : typeof update === 'undefined'? []: update,
                delete : typeof deleteVar === 'undefined'? []: deleteVar,
                needKey : typeof needKey === 'undefined'? []: needKey,
                group : group
            });
        }
        result.setContext({
            form : typeof form === 'undefined'? []: temp.context.forms,
            group: group,
        });
        return result;
    }


    async add(lang, group, model)
    {
        console.debug(`[Diji SK] - Service - SettingService - add in ${group}`);
        // if (group) {
        //     // return await this.#apiLanguages.addLanguage( model);
        // }
        console.log(lang, group, model)
        return await this.#api.addSetting(lang, group, model);

    }

/********************/
    async update(lang,group,key,setting)
    {
        console.debug(`[Diji SK] - Service - SettingService - update ${group} / ${key} `);
        const result = await this.#api.updateSetting(lang, group, key, {...setting});
        return result;
    }

/********************/
    async delete(lang, group, key)
    {
        console.debug(`[Diji SK] - Service - SettingService - delete ${group} / ${key} `);
        const result = await  this.#api.deleteSetting(lang, group, key);
        return result;
    }
}
