import {Link, useParams} from "react-router-dom";
import '../../styles/login.css'
import '../../styles/register.css';
import { useNavigate } from "react-router-dom";
import React, {useEffect, useState} from "react";
import SettingService from "../Service/Bll/SettingService";
import LoginService from "../Service/Bll/LoginService";
import CacheExtension from "../Filter/CacheExtension";
import logo from "../../res/logo512.png";

export default function ResetPage() {
    const navigate = useNavigate();
    var service = new LoginService();
    const settingService = new SettingService()
    const [formReinit, setFormReinit] = useState(null);
    const [reinitMessage, setReinitMessage] = useState(false);
    const [confirmation, setConfirmation] = useState('');
    const [regexPw, setRegexPw] = useState('');
    const [errorsReinit, setErrorsReinit] = useState({});

    const {user, token} = useParams();

    useEffect(() => {
        settingService.getFromGroup('app').then(function (result) {
            let setting = result.getData()
            let regex = new RegExp(setting.passwordValidation.text)
            setRegexPw(regex)
        })

        setFormReinit({
            token,
            user
        })
    },[])
    const handleFormReinitChange = event => {
        setFormReinit({
            ...formReinit,
            [event.target.name]:event.target.value
        });
    }

    const submitReinit = async (e) => {
        e.preventDefault();
        if(formReinit && validateReinit(formReinit)) {
            let res = await service.changePasswordRecover(formReinit);
            if(res.getCode() === 0) {
                setReinitMessage(true)
            } else {
                navigate('/')
            }
        }
    };

    const validateReinit = (data) => {
        const newErrors = {};

        if(!data.password || data.password !== confirmation) {
            newErrors.password = CacheExtension.getDataFromCache("PasswordDoesntMatch")
        }
        if (!regexPw.test(data.password)) {
            newErrors.passwordPattern = CacheExtension.getDataFromCache("PasswordDoesntMatchPattern");
        }

        setErrorsReinit(newErrors);
        return Object.keys(newErrors).length === 0;
    };


    return (
        <>
            <div style={{overflowX:'hidden'}}>
                <div id="header">
                    <div className="row cm-color-title">
                        <div className="col-12 text-center">
                            <img
                                src={logo}
                                className="logoHeader mb-3 mt-3"
                                style={{height: 150}}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-10 offset-1">
                        <form onSubmit={(e) => submitReinit(e)}>
                            <div className="modal-body">
                                <div className={"mb-5"}>{CacheExtension.getDataFromCache("reset.intro")}</div>
                                <div id="confirmUser_Form">
                                    <div id="confirmUser_Group_confirmToken_password"
                                         className="form-inline mb-2">
                                        <label
                                            htmlFor="confirmUser_Control_confirmToken_password"
                                            className="col-4 sk-auto-form sk-form-label font-weight-bold"
                                            style={{justifyContent: "left"}}>{CacheExtension.getDataFromCache("Password")}</label>
                                        <input
                                            id="confirmUser_Control_confirmToken_password" type="password" name="password"
                                            className={errorsReinit.passwordPattern ? "inputError col-8 form-control sk-auto-form sk-form-password" : "col-8 form-control sk-auto-form sk-form-password"} onChange={handleFormReinitChange}/>
                                    </div>
                                    <div id="confirmUser_Group_confirmToken_confirmPassword" className="form-inline mb-2">
                                        <label
                                            htmlFor="confirmUser_Control_confirmToken_confirmPassword"
                                            className="col-4 sk-auto-form sk-form-label font-weight-bold"
                                            style={{justifyContent: "left"}}>{CacheExtension.getDataFromCache("ConfirmPassword")}</label>
                                        <input
                                            id="confirmUser_Control_confirmToken_confirmPassword"
                                            type="password"
                                            name="confirmationPassword"
                                            className={errorsReinit.passwordPattern ? "inputError col-8 form-control sk-auto-form sk-form-password" : "col-8 form-control sk-auto-form sk-form-password"} onChange={(e)=> setConfirmation(e.target.value)}/>
                                    </div>
                                    {errorsReinit.password && <div className="showError">{errorsReinit.password}</div>}
                                    <div>
                                        <div className="col-12">{CacheExtension.getDataFromCache("PasswordDoesntMatchPattern")}</div>
                                    </div>
                                </div>
                            </div>
                            <div className={'modal-footer'}>
                                <div className={'d-flex justify-content-center align-items-center'}>
                                    <div>
                                        <button disabled={reinitMessage} type='submit' className={'button submitform btn'}>{CacheExtension.getDataFromCache("Validate")}</button>
                                    </div>
                                </div>
                            </div>
                            {reinitMessage &&
                                <div className="d-flex justify-content-center">
                                    <div>
                                        <div>{CacheExtension.getDataFromCache('ReinitMessage')}</div>
                                        <Link className="button submitform btn w-100 mt-3" to={`/login`}>{CacheExtension.getDataFromCache("login")}</Link>
                                    </div>
                                </div>
                            }
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}
