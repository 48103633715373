import CacheExtension from "../../StarterKit/Filter/CacheExtension";
import React, {useEffect, useRef, useState,} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ProductService from "../Services/Bll/ProductService";
import {Dropdown} from 'semantic-ui-react'
import {HeaderComponent} from "../Components/HeaderComponent";
import {Link, useNavigate} from "react-router-dom";
import {motion as m} from "framer-motion";
import ModelService from "../Services/Bll/ModelService";
import {ProductCardSav} from "../Components/ProductCardSav";
import {ConfirmModalComponent} from "../../StarterKit/Components/ConfirmModalComponent";

export const SavAdd = () => {
    const modelService = new ModelService()
    const productService = new ProductService()
    const navigate = useNavigate();

    const initialForm = {
        type: 'maintenance',
        comment: '',
        sealScore: null,
        authentication: 'authentic',
    };
    const [skus, setSkus] = useState([])
    const [form, setForm] = useState(initialForm)
    const [step, setStep] = useState(1)
    const [selectedSku, setSelectedSku] = useState(null)
    const [selectedSerial, setSelectedSerial] = useState(null)
    const [sealNumber, setSealNumber] = useState(null)
    const [product, setProduct] = useState(null)
    const [notFound, setNotFound] = useState(false)
    const [sealNumberErr, setSealNumberErr] = useState(false)
    const [sealNumberMatch, setSealNumberMatch] = useState(false)
    const serialInput = useRef();

    $(function () {
        $('[data-toggle="tooltip"]').tooltip()
    })

    useEffect(() => {
        modelService.listSav().then(function (result) {
            let res = result.getData();
            setSkus(res)
        })
    }, [])

    const handleChange = event => {
        setForm({
            ...form,
            [event.target.name]:event.target.value
        });
    }
    const handleChangeSerial = (newValue) => {
        serialInput.current.value = newValue;
        serialInput.current.dispatchEvent(new Event('change', { bubbles: true }));
    };


    async function checkModelSerial(sku, serial){
        let res = await productService.checkModelSerialExist(sku, serial);
        if(res.getCode() == 0) {
            let data = res.getData()
            if (data.exist) {
                setNotFound(false)
                setProduct(data.product)
            } else {
                setProduct(null)
                setNotFound(true)
            }
        }
    }

    async function checkSealNumber(product, sealNumber){
        let res = await productService.checkSealNumber(product, sealNumber);
        if(res.getCode() == 0) {
            let data = res.getData()
            if (data.success) {
                setSealNumberErr(false)
                setSealNumberMatch(true)
            } else {
                setSealNumberMatch(false)
                setSealNumberErr(true)
            }
        }
    }

    function modalHtml(selectedSku, selectedSerial, sealNumber, product) {
        return (
            <div className="mb-5">
                <div className="mb-3">
                    <label><strong>{CacheExtension.getDataFromCache("CreateSavText")}</strong></label>
                </div>
                {product &&
                    <div className="form-group">
                        <label className="mb-1"><strong>{CacheExtension.getDataFromCache("Model")}</strong></label>
                        <p>{` ${product.model.title} ${product.model.subtitle}`}</p>
                    </div>
                }
                <div className="form-group">
                    <label className="mb-1"><strong>{CacheExtension.getDataFromCache("SKU")}</strong></label>
                    <p>{` ${selectedSku}`}</p>
                </div>
                <div className="form-group">
                    <label className="mb-1"><strong>{CacheExtension.getDataFromCache("Serial")}</strong></label>
                    <p>{` ${selectedSerial}`}</p>
                </div>
                <div className="form-group">
                    <label className="mb-1"><strong>{CacheExtension.getDataFromCache("SavType")}</strong></label>
                    <p>{`  ${CacheExtension.getDataFromCache(form.type)}`}</p>
                </div>
                <div className="form-group">
                    <label className="mb-1"><strong>{CacheExtension.getDataFromCache("Comment")}</strong></label>
                    <p>{` ${form.comment}`}</p>
                </div>
                <div className="form-group">
                    <label className="mb-1"><strong>{CacheExtension.getDataFromCache("Authentication")}</strong></label>
                    <p>{` ${CacheExtension.getDataFromCache(form.authentication)}`}</p>
                </div>
                {form.authentication == 'authentic' &&
                    <>
                        <div className="form-group">
                            <label className="mb-1"><strong>{CacheExtension.getDataFromCache("SealNumber")}</strong></label>
                            <p>{` ${sealNumber}`}</p>
                        </div>
                        <div className="form-group">
                            <label className="mb-1"><strong>{CacheExtension.getDataFromCache("SealScore")}</strong></label>
                            <p>{` ${form.sealScore}`}</p>
                        </div>
                    </>
                }
            </div>
        )
    }

    return (
        <>
            <m.div className={"wrapper"}>

                <div id={"page"}>
                    <HeaderComponent logo={'/pictures/Logo_Pequignet.png'}
                                     title={CacheExtension.getDataFromCache('Sav')}></HeaderComponent>
                    <div style={{padding:"20px"}}>
                        <Link className="backLinkModel" to={`/sav`}>
                            <FontAwesomeIcon icon="fas fa-chevron-left" style={{marginRight:"10px", color:"rgb(255, 158, 23)"}} />
                            {CacheExtension.getDataFromCache('Back')}
                        </Link>
                    </div>
                    <div id="content">
                        <div id={"page"}>
                            <div className={'stockSection'}>
                                <span className={"step"}>{CacheExtension.getDataFromCache('Step').replace('%%1%%', CacheExtension.getDataFromCache('stepSav1'))}</span>
                                <div className={'stockSectionContent justify-content-start'}>
                                    <div className="container-fluid">
                                        <div className="form-group my-3">
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input
                                                    className="custom-control-input"
                                                    type="radio"
                                                    name="maintenance"
                                                    id="maintenance"
                                                    value="maintenance"
                                                    checked={form.type === 'maintenance'}
                                                    disabled={step != 1}
                                                    onChange={() => {
                                                        setForm({
                                                            ...form,
                                                            type: 'maintenance'
                                                        })
                                                    }}
                                                />
                                                <label className="custom-control-label" htmlFor="maintenance">{CacheExtension.getDataFromCache('maintenance')}</label>
                                            </div>
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input
                                                    className="custom-control-input"
                                                    type="radio"
                                                    name="repair"
                                                    id="repair"
                                                    value="repair"
                                                    checked={form.type === 'repair'}
                                                    disabled={step != 1}
                                                    onChange={() => {
                                                        setForm({
                                                            ...form,
                                                            type: 'repair'
                                                        })
                                                    }}
                                                />
                                                <label className="custom-control-label" htmlFor="repair">{CacheExtension.getDataFromCache('repair')}</label>
                                            </div>
                                        </div>
                                        <div className="row d-flex justify-content-end mb-3">
                                            <button disabled={!form.type || step != 1} className={'btn btnBo'} onClick={() => setStep(2)}>{CacheExtension.getDataFromCache('Validate')}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={step == 2 ? 'stockSection' : 'stockSection disabledSection'}>
                                <span className={"step"}>{CacheExtension.getDataFromCache('Step').replace('%%1%%', CacheExtension.getDataFromCache('stepSav2'))}</span>
                                <div className={'stockSectionContent justify-content-start'}>
                                    <div className="container-fluid">
                                        <div className="d-flex">
                                            <div className={'form-group my-3'}>
                                                <p className={'mb-1'}>{CacheExtension.getDataFromCache("SKU")}</p>
                                                <Dropdown
                                                    placeholder={CacheExtension.getDataFromCache('SelectSKU')}
                                                    fluid
                                                    search
                                                    selection
                                                    disabled={step != 2}
                                                    style={{width:"215px"}}
                                                    onChange={async (e, {value}) => {
                                                        setSelectedSku(value)
                                                        setSelectedSerial('')
                                                        setNotFound(false)
                                                        setProduct(null)
                                                        handleChangeSerial('')
                                                    }}
                                                    options={[
                                                        { key: 'none', value: '', text: CacheExtension.getDataFromCache('SelectSKU') },
                                                        ...skus.map((value, i) => ({
                                                            key: i,
                                                            value: value,
                                                            text: value
                                                        }))
                                                    ]}
                                                />
                                            </div>
                                            <div className={'form-group m-3'}>
                                                <p className={'mb-1'}>{CacheExtension.getDataFromCache("Serial")}</p>
                                                <input
                                                    ref={serialInput} maxLength={4} disabled={step != 2 || !selectedSku} type="text" style={{width : '200px'}} className="form-control"
                                                    onChange={function (e) {
                                                        if ($(e.target).val() !== ''){
                                                            setProduct(null)
                                                            setSelectedSerial($(e.target).val().padStart(4, '0'))
                                                        }
                                                    }}
                                                    onBlur={() => handleChangeSerial(selectedSerial.padStart(4, '0'))}
                                                />
                                            </div>
                                            <div className={'form-group m-3 d-flex align-items-end'}>
                                                <button disabled={step != 2 || !selectedSku} className={'btn btnBo'} onClick={() => checkModelSerial(selectedSku, selectedSerial)}>{CacheExtension.getDataFromCache('Validate')}</button>
                                            </div>
                                        </div>
                                        <div>
                                            {product &&
                                                <>
                                                    <div>{CacheExtension.getDataFromCache('ProductFoundSav')}</div>
                                                    <ProductCardSav data={product.model}/>
                                                </>
                                            }
                                            {notFound && <div className="showError">{CacheExtension.getDataFromCache('ProductNotFoundSav')}</div>}
                                        </div>
                                        <div className="row d-flex justify-content-end mb-3">
                                            <button disabled={step != 2 || !selectedSku || !selectedSerial || !product} className={'btn btnBo'} onClick={() => setStep(3)}>{CacheExtension.getDataFromCache('Validate')}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={step == 3 ? 'stockSection' : 'stockSection disabledSection'}>
                                <span className={"step"}>{CacheExtension.getDataFromCache('Step').replace('%%1%%', CacheExtension.getDataFromCache('stepSav3'))}</span>
                                <div className={'stockSectionContent justify-content-start'}>
                                    <div className="container-fluid">
                                        <div  className="form-group my-3">
                                            <p className={'mb-1'}>{CacheExtension.getDataFromCache('Comment')}</p>
                                            <textarea disabled={step != 3} value={form.comment} className="form-control" type='text' name="comment" onChange={handleChange}/>
                                        </div>
                                        <div className="row d-flex justify-content-end mb-3">
                                            <button disabled={step != 3 || !form.comment} className={'btn btnBo'} onClick={() => setStep(4)}>{CacheExtension.getDataFromCache('Validate')}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={step == 4 ? 'stockSection' : 'stockSection disabledSection'}>
                                <span className={"step"}>{CacheExtension.getDataFromCache('Step').replace('%%1%%', CacheExtension.getDataFromCache('stepSav4'))}</span>
                                <div className={'stockSectionContent justify-content-start'}>
                                    <div className="container-fluid">
                                        <div className="form-group my-3">
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input
                                                    className="custom-control-input"
                                                    type="radio"
                                                    name="authentic"
                                                    id="authentic"
                                                    value="authentic"
                                                    checked={form.authentication === 'authentic'}
                                                    disabled={step != 4}
                                                    onChange={() => {
                                                        setForm({
                                                            ...form,
                                                            authentication: 'authentic',
                                                            sealScore: null
                                                        })
                                                        setSealNumberErr(false)
                                                        setSealNumberMatch(false)
                                                    }}
                                                />
                                                <label className="custom-control-label" htmlFor="authentic">{CacheExtension.getDataFromCache('authentic')}</label>
                                            </div>
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input
                                                    className="custom-control-input"
                                                    type="radio"
                                                    name="fake"
                                                    id="fake"
                                                    value="fake"
                                                    checked={form.authentication === 'fake'}
                                                    disabled={step != 4}
                                                    onChange={() => {
                                                        setForm({
                                                            ...form,
                                                            authentication: 'fake',
                                                            sealScore: 0
                                                        })
                                                        setSealNumberErr(false)
                                                        setSealNumberMatch(false)
                                                    }}
                                                />
                                                <label className="custom-control-label" htmlFor="fake">{CacheExtension.getDataFromCache('fake')}</label>
                                            </div>
                                            <div className="custom-control custom-radio custom-control-inline">
                                                <input
                                                    className="custom-control-input"
                                                    type="radio"
                                                    name="undetermined"
                                                    id="undetermined"
                                                    value="undetermined"
                                                    checked={form.authentication === 'undetermined'}
                                                    disabled={step != 4}
                                                    onChange={() => {
                                                        setForm({
                                                            ...form,
                                                            authentication: 'undetermined',
                                                            sealScore: 0
                                                        })
                                                        setSealNumberErr(false)
                                                        setSealNumberMatch(false)
                                                    }}
                                                />
                                                <label className="custom-control-label" htmlFor="undetermined">{CacheExtension.getDataFromCache('undetermined')}</label>
                                            </div>
                                        </div>
                                        {form.authentication == 'authentic' &&
                                        <>
                                            <div className={'d-flex'}>
                                                <div className={'form-group my-3'}>
                                                    <p htmlFor="sealNumber">{CacheExtension.getDataFromCache('SealNumber')}</p>
                                                    <input type="text"
                                                           className="form-control"
                                                           name="sealNumber"
                                                           disabled={step != 4}
                                                           onChange={(e) => {
                                                               setSealNumber(e.target.value)
                                                               setSealNumberMatch(false)
                                                           }}/>
                                                </div>
                                                <div className={'form-group m-3'}>
                                                    <p htmlFor="sealScore">{CacheExtension.getDataFromCache('SealScore')}</p>
                                                    <input type="text"
                                                           className="form-control"
                                                           name="sealScore"
                                                           pattern="[0-9+]*"
                                                           onKeyDown={(event) => {
                                                               if (!/[0-9+]/.test(event.key) && ![ 'Backspace', 'Delete', 'Tab', 'ArrowLeft', 'ArrowRight' ].includes(event.key)) {
                                                                   event.preventDefault();
                                                               }
                                                           }}
                                                           value={form.sealScore}
                                                           disabled={step != 4}
                                                           onChange={(event) => {
                                                               let value = event.target.value;
                                                               if (value > 100) {
                                                                   value = 100;
                                                               }
                                                               handleChange({ target: { name: event.target.name, value: value } });
                                                           }}/>
                                                </div>
                                                <div className="form-group m-3 d-flex align-items-end">
                                                    <button disabled={step != 4 || !sealNumber || !form.sealScore} className={'btn btnBo mt-3 mr-3'} onClick={() => {
                                                        checkSealNumber(product.product.key, sealNumber)
                                                    }}>{CacheExtension.getDataFromCache('CheckSeal')}</button>
                                                </div>
                                            </div>
                                            {sealNumberErr && <div >{CacheExtension.getDataFromCache('SealNumberDontMatch')}</div>}
                                        </>
                                        }
                                        <div className="row d-flex justify-content-end mb-3">
                                            <button
                                                disabled={
                                                    step != 4 ||
                                                    (form.authentication === 'authentic' && !sealNumberMatch)
                                                }
                                                className={'btn btnBo'}
                                                onClick={() => setStep(5)}
                                            >
                                                {CacheExtension.getDataFromCache('Validate')}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={step == 5 ? 'stockSection' : 'stockSection disabledSection'}>
                                <span className={"step"}>{CacheExtension.getDataFromCache('Step').replace('%%1%%', CacheExtension.getDataFromCache('stepSav5'))}</span>
                                <div className={'stockSectionContent justify-content-start'}>
                                    <div className="container-fluid">
                                        <div className="row d-flex justify-content-end mb-3">
                                            <ConfirmModalComponent disabled={step != 5} html={modalHtml(selectedSku, selectedSerial, sealNumber, product)} icon={<span>{CacheExtension.getDataFromCache('Validate')}</span>} title={CacheExtension.getDataFromCache('CreateSavModalTitle')} action={async function (e) {
                                                await productService.addSav(product.product.key, form)
                                                navigate('/sav')
                                            }}></ConfirmModalComponent>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </m.div>
        </>
    );
}
