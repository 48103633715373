import React, {useEffect, useState}  from "react";
import {motion as m} from "framer-motion";
import { Dropdown } from 'semantic-ui-react'
import ShopService from "../Services/Bll/ShopService";
import CacheExtension from "../../StarterKit/Filter/CacheExtension";
import 'semantic-ui-css/semantic.min.css'
import {HeaderComponent} from "../Components/HeaderComponent";
import 'semantic-ui-css/semantic.min.css'
import {ProductCardShop} from "../Components/ProductCardShop";

export default function StockShop () {
    const service = new ShopService();
    const [data, setData] = useState(null);
    const [selected, setSelected] = useState('all');

    useEffect(() => {
        service.list().then(function (result) {
            let res = result.getData();
            if (res.stockDetail){
                setData(res);
            }
        })
    },[])

        return (
            <>
                {data &&
                <m.div className={"wrapper"}>
                    <div id={"page"}>
                        <HeaderComponent logo={'/pictures/Logo_Pequignet.png'} title={CacheExtension.getDataFromCache('Stock').replace('%%1%%', data.name)}></HeaderComponent>

                        <div id="content">
                            <div id={"page"}>
                                <div className={'filters d-flex align-items-end'}>
                                    <div>
                                        <div>
                                            <label htmlFor="">{CacheExtension.getDataFromCache('modeleAvailable')}</label>
                                            <Dropdown
                                                placeholder={CacheExtension.getDataFromCache('PlaceholderDropdownWatch')}
                                                fluid
                                                search
                                                selection
                                                options={[{ key: 0, value: "all", text: CacheExtension.getDataFromCache('AllModels') }, ...Object.keys(data.stockDetail).map((key, i) => ({ key: i+1, value: key, text: key }))]}
                                                onChange={(e, { value }) => setSelected(value)}
                                            />
                                        </div>

                                    </div>
                                    {/*<ModaleQrCode tooltip={CacheExtension.getDataFromCache('receiveProduct')} icon={<button className={'btn btn-primary'} >{CacheExtension.getDataFromCache('receiveProduct')}</button>}  action={function (key){*/}
                                    {/*    var productService = new ProductService()*/}
                                    {/*    productService.received(key)*/}
                                    {/*}}></ModaleQrCode>*/}

                                </div>

                                <div className="App mt-4">
                                    {selected && (
                                        <div key={selected}>
                                            <h5 className={'typeTitle'}>{selected === "all" ? CacheExtension.getDataFromCache('AllModels') : selected}</h5>
                                            <div className={'modelsContainer d-flex flex-wrap'}>
                                                {selected === "all" ?
                                                    Object.keys(data.stockDetail).flatMap(model =>
                                                        Object.keys(data.stockDetail[model]).map(variant =>
                                                            <ProductCardShop key={variant} variant={variant} data={data.stockDetail[model][variant]}></ProductCardShop>
                                                        )
                                                    ) :
                                                    Object.keys(data.stockDetail[selected]).map(variant =>
                                                        <ProductCardShop key={variant} variant={variant} data={data.stockDetail[selected][variant]}></ProductCardShop>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </m.div>
                }
            </>
        );
}
