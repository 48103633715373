import '../../styles/login.css';
import '../../styles/sk.css';
import '../../styles/settings.css';
import * as ReactDOM from 'react-dom';
import axios from 'axios';
import {motion as m} from "framer-motion"
import { createRoot } from 'react-dom/client';
import React, {useEffect, useState,} from "react";
import { Nav } from "../../components/navComponents"
import { Header } from "../../components/headerComponent"
import { Footer } from "../../components/footerComponent";
import ReactDomServer from "react-dom/server"
import Autoform from "../Autoform/Autoform"
import SettingService from "../Service/Bll/SettingService";
import Sk from "../Sk";
import CacheExtension from "../Filter/CacheExtension";



export default function Settings() {
    $(function () {
        $('[data-toggle="tooltip"]').tooltip()
    })
    const [post, setPost] = React.useState(null);
    const [postLanguage, setPostLanguage] = React.useState(null);
    var group = null
    const [getPost, setGetPost] = React.useState(null);
    var service = new SettingService()

    if(!post) {
        service.list("").then(function (result) {
            setPost(result)
        })
        return null;
    }
    if(!postLanguage) {
        service.list("language").then(function (result) {
            setPostLanguage(result)
        })
        return null;
    }
    var arrayFormKeysGroups = Object.keys(post.data.groups); // clé des data envoyé
    if (!group){
        const queryParameters = new URLSearchParams(window.location.search)
        group = queryParameters.get("group") !== null ? queryParameters.get("group") : arrayFormKeysGroups[0]
    }
    var arrayFormKeysLanguage = Object.keys(postLanguage.data.settings); // clé des data envoyé
    var str = window.location.href
    var url = new URL(str);
    var search_params = new URLSearchParams(url.search);
    if(search_params.has('group')) {
        var groupUrl = search_params.get('group');
        var langUrl = search_params.get('lang');
        if (groupUrl && !getPost) {
            service.list(groupUrl).then(function (result) {
                setGetPost(result)
            })
            return null
        }
    } else {
        if (!getPost) {
            service.list(arrayFormKeysGroups[0]).then(function (result) {
                setGetPost(result)
            })

            return null
        }
    }



    function SettingsChangeGroup() {
        var setting_groupList = document.getElementById('setting_groupList')
        var setting_languageList = document.getElementById("setting_languageList")
        if (setting_groupList && setting_languageList.value) {
            var url =  window.location.pathname + "?group=" + setting_groupList.value + "&lang=" + setting_languageList.value
            window.location.href = url
        }
    }
    function updateSetting(lang,group,key,setting) {
        var container__modal = document.querySelector('.container__modal')
        var openModal = new Autoform([{submitCallback: "",groupsForm: group, langForm: lang, dataModal : setting, idModal : key,  methodRoute : "put" ,  declaration: 'update' , route : "users" , keyName : 'update' , data : valueForm.update , modale : true , name : 'user' , classInput : 'form-control' , cancel : 'cancel' , submit : 'submit' , modaleHeader : "HeaderUpdateUser" , hidden : true  }],SettingService)
        const root = createRoot(container__modal);
        var a = root.render(openModal.render());
        setTimeout(() => {
            openModal.openModal(key,setting)
        }, "100")
    }

    function deleteSetting(lang,group,key) {
        var container__modal = document.querySelector('.container__modal')
        var openModal = new Autoform([{submitCallback: "",groupsForm: group, langForm: lang, idModal : key,  methodRoute : "delete" ,  declaration: 'delete' , route : "users" , keyName : 'delete ' , data : {} , modale : true , name : 'user' , classInput : 'form-control' , cancel : 'cancel' , submit : 'submit' , modaleHeader : "Voulez vous vraiment delete" , hidden : true  }],SettingService)
        const root = createRoot(container__modal);
        var a = root.render(openModal.render());
        setTimeout(() => {
            openModal.openModal(key,"")
        }, "100")
    }



    var valueForm = post.data.form
    var getPostKey = Object.keys(getPost.data.settings);
    var getObjectPost = Object.values(getPost.data.settings);

    var HasAutomaticKey =  getPost.data.definition.HasAutomaticKey;
    var IsCreatable =  getPost.data.definition.IsCreatable;
    var IsDeletable =  getPost.data.definition.IsDeletable;
    var IsSameForAllLanguages = getPost.data.definition.IsSameForAllLanguages;
    var IsUpdatable =  getPost.data.definition.IsUpdatable;

    var languagedesfault = ""
    var groupsdesfault = ""

    if (groupUrl) {
        groupsdesfault = groupUrl
    } else {
        groupsdesfault = arrayFormKeysGroups[0]
    }

    if (langUrl) {
        languagedesfault = langUrl
    } else {
        languagedesfault = "FR"
    }

        return (
            <>
                <m.div className={"wrapper"}>
                    <Nav />
                    <div id={"page"}>
                        <Header />
                        <div id="content">
                            <div className={'card'}>
                                <div className="card-header">
                                    <div className="row align-items-center">
                                        <div className="col-6">
                                            <h3>{CacheExtension.getDataFromCache('SettingsPageTitle')}</h3>
                                            <input type="hidden" defaultValue={1} id="settings_page"/>
                                            <input type="hidden" defaultValue={1} id="settings_pageCount"/>
                                        </div>
                                        <div className="col-6 text-right">
                                            <a
                                                className="actionLink mr-3"
                                                onClick={() => Sk.skExportCSV('settings','settings')}
                                                data-toggle="tooltip"
                                                data-placement="top"
                                                title={"ExportCSV"}
                                                data-original-title="Exporter la table en CSV">
                                                <svg
                                                    className="svg-inline--fa fa-download"
                                                    aria-hidden="true"
                                                    focusable="false"
                                                    data-prefix="fas"
                                                    data-icon="download"
                                                    role="img"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 512 512"
                                                    data-fa-i2svg="">
                                                    <path
                                                        fill="currentColor"
                                                        d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zM432 456c-13.3 0-24-10.7-24-24s10.7-24 24-24s24 10.7 24 24s-10.7 24-24 24z"
                                                    />
                                                </svg>
                                                {/* <i class="fas fa-download"></i> Font Awesome fontawesome.com */}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className={'card-body container fullSize'}>
                                    <div className="row mb-4">
                                        <div className="col-sm-6">
                                            <select className="custom-select" onChange={SettingsChangeGroup}
                                                    id="setting_groupList">
                                                { (arrayFormKeysGroups) ? (
                                                    arrayFormKeysGroups.map( (data, index) =>
                                                        <option selected={arrayFormKeysGroups[index] === group} key={index} value={arrayFormKeysGroups[index]}>{arrayFormKeysGroups[index]}</option>
                                                    )
                                                ) : ( 'ok'
                                                )
                                                }
                                            </select>
                                        </div>
                                        <div className="col-sm-3 offset-3">
                                            <select className="custom-select" id="setting_languageList"
                                                    onChange={SettingsChangeGroup}>
                                                { (arrayFormKeysLanguage) ? (
                                                    arrayFormKeysLanguage.map( (data, index) =>
                                                        <option key={index} value={arrayFormKeysLanguage[index]}>{arrayFormKeysLanguage[index]}</option>
                                                    )
                                                ) : ( 'ok'
                                                )
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row border-bottom mt-1">
                                        <div className="col-3 1">
                                            <div className="input-group mb-3">
                                                <input
                                                    type="text"
                                                    data-sk="filter"
                                                    data-sktype="text"
                                                    data-skname="key"
                                                    placeholder={CacheExtension.getDataFromCache('search_key')}
                                                    data-csvfilter="Clé"
                                                    className="form-control"
                                                    id="setting_search_key"
                                                    onChange={() => skTableKeyPress(this)}
                                                    // onBlur="skTableSearch(event, this)"
                                                />
                                                <div className="input-group-append">
                                                    <button
                                                        className="btn btn-outline-secondary"
                                                        type="button"
                                                        data-sk="clear"
                                                        data-sktype="text"
                                                        data-skname="setting_search_key"
                                                        data-toggle="tooltip"
                                                        data-placement="top"
                                                        title=""
                                                        data-original-title="Effacer le filtre"
                                                    >
                                                        <svg
                                                            className="svg-inline--fa fa-xmark"
                                                            aria-hidden="true"
                                                            focusable="false"
                                                            data-prefix="fas"
                                                            data-icon="xmark"
                                                            role="img"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 320 512"
                                                            data-fa-i2svg=""
                                                        >
                                                            <path
                                                                fill="currentColor"
                                                                d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z"
                                                            />
                                                        </svg>
                                                        {/* <i class="fas fa-times"></i> Font Awesome fontawesome.com */}
                                                    </button>
                                                </div>
                                            </div>
                                            {" "}
                                        </div>
                                        <div className="col-6">
                                            <div className="input-group mb-3">
                                                <input
                                                    type="text"
                                                    data-sk="filter"
                                                    data-sktype="text"
                                                    data-skname="text"
                                                    placeholder={CacheExtension.getDataFromCache('search_label')}
                                                    data-csvfilter="Label"
                                                    className="form-control"
                                                    onChange={() => skTableKeyPress(this)}
                                                    id="setting_search_text"
                                                />
                                                <div className="input-group-append">
                                                    <button
                                                        className="btn btn-outline-secondary"
                                                        type="button"
                                                        data-sk="clear"
                                                        data-sktype="text"
                                                        data-skname="setting_search_text"
                                                        data-toggle="tooltip"
                                                        data-placement="top"
                                                        title=""
                                                        data-original-title="Effacer le filtre"
                                                    >
                                                        <svg
                                                            className="svg-inline--fa fa-xmark"
                                                            aria-hidden="true"
                                                            focusable="false"
                                                            data-prefix="fas"
                                                            data-icon="xmark"
                                                            role="img"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 320 512"
                                                            data-fa-i2svg=""
                                                        >
                                                            <path
                                                                fill="currentColor"
                                                                d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z"
                                                            />
                                                        </svg>
                                                        {/* <i class="fas fa-times"></i> Font Awesome fontawesome.com */}
                                                    </button>
                                                </div>
                                            </div>
                                            {" "}
                                        </div>
                                        <div className="col-2">&nbsp;</div>
                                        <div className="col-1">&nbsp;</div>
                                    </div>

                                    {IsCreatable === true ?
                                        new Autoform([{submitCallback: "",groupsForm: groupsdesfault, langForm: languagedesfault, declaration: 'add' , methodRoute:"post", route:"/settings",  keyName:'new', data: valueForm.new, modale:false, name:'settings', classInput:'form-control'  }],SettingService ).render()
                                        : ""}
                                    { (getPostKey) ? (
                                        getPostKey.map( (data, index) =>
                                            <div key={index}
                                                className={"row pt-2 pb-2 align-items-center listsettings" +(index%2 ? ' evenTableLine':' oddTableLine')}
                                                id={"setting_" + index}>
                                                <div className="col-3 {{ (needKey)?: 'hidden' }}">
                                                    <strong className={'keyname'}>{getPostKey[index]}</strong>
                                                </div>
                                                <div className="col-6 pt-1 pb-1">
                                                <span className={'labelname'} data-csvdata="text" id="setting_text_{{ index }}">
                                                    {getObjectPost[index].text}</span>
                                                </div>
                                                <div className="col-2">
                                                <span data-csvdata="text" id="setting_numeric_{{ index }}">
                                                    {getObjectPost[index].numeric}</span>
                                                </div>
                                                <div className={"col-1 text-center"}>
                                                    {IsUpdatable === true ?
                                                        <a className="actionLink "
                                                           id="setting_edit_1"
                                                           data-skkey={1}
                                                           data-sk="icon"
                                                           data-sktype="edit"
                                                           data-toggle="tooltip"
                                                           data-placement="top"
                                                           onClick={() => updateSetting(languagedesfault,groupsdesfault,getPostKey[index],getObjectPost[index])}
                                                           title={CacheExtension.getDataFromCache('SettingsUpdateTooltip')}
                                                           data-original-title="Modifier">
                                                            <svg
                                                                className="svg-inline--fa fa-pencil"
                                                                aria-hidden="true"
                                                                focusable="false"
                                                                data-prefix="fas"
                                                                data-icon="pencil"
                                                                role="img"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 512 512"
                                                                data-fa-i2svg="">
                                                                <path
                                                                    fill="currentColor"
                                                                    d="M421.7 220.3l-11.3 11.3-22.6 22.6-205 205c-6.6 6.6-14.8 11.5-23.8 14.1L30.8 511c-8.4 2.5-17.5 .2-23.7-6.1S-1.5 489.7 1 481.2L38.7 353.1c2.6-9 7.5-17.2 14.1-23.8l205-205 22.6-22.6 11.3-11.3 33.9 33.9 62.1 62.1 33.9 33.9zM96 353.9l-9.3 9.3c-.9 .9-1.6 2.1-2 3.4l-25.3 86 86-25.3c1.3-.4 2.5-1.1 3.4-2l9.3-9.3H112c-8.8 0-16-7.2-16-16V353.9zM453.3 19.3l39.4 39.4c25 25 25 65.5 0 90.5l-14.5 14.5-22.6 22.6-11.3 11.3-33.9-33.9-62.1-62.1L314.3 67.7l11.3-11.3 22.6-22.6 14.5-14.5c25-25 65.5-25 90.5 0z"
                                                                />
                                                            </svg>
                                                        </a>
                                                        : ""}
                                                    {IsDeletable === true ?
                                                        <a
                                                            className="actionLink ml-3"
                                                            id="setting_delete_1"
                                                            data-skkey="confirmToken"
                                                            data-sk="icon"
                                                            data-sktype="delete"
                                                            onClick={() => deleteSetting(languagedesfault,groupsdesfault,getPostKey[index])}
                                                            data-toggle="tooltip"
                                                            data-placement="top"
                                                            title=""
                                                            data-original-title="Supprimer la clé">
                                                            <svg
                                                                className="svg-inline--fa fa-trash-can"
                                                                aria-hidden="true"
                                                                focusable="false"
                                                                data-prefix="fas"
                                                                data-icon="trash-can"
                                                                role="img"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 448 512"
                                                                data-fa-i2svg=""><path
                                                                fill="currentColor"
                                                                d="M135.2 17.7C140.6 6.8 151.7 0 163.8 0H284.2c12.1 0 23.2 6.8 28.6 17.7L320 32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 96 0 81.7 0 64S14.3 32 32 32h96l7.2-14.3zM32 128H416V448c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V128zm96 64c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16z"
                                                            />
                                                            </svg>
                                                        </a>
                                                        : ""}
                                                </div>
                                            </div>
                                        )
                                    ) : ( 'ok'
                                    )
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </m.div>
            </>
        )
}




function skTableKeyPress(value) {
    var tableGO  = [];
    var fullList = document.querySelectorAll(".listsettings")
    var fullistKey = document.querySelectorAll(".listsettings .keyname")
    var fullistLabel = document.querySelectorAll(".listsettings .labelname")
    var value = document.getElementById('setting_search_key')
    var value2 = document.getElementById("setting_search_text")
    if (fullList) {
        for (let i = 0; i < fullList.length ; i++) {
            if (value.value === "" && value2.value === "") {
                tableGO.push(fullList[i])
            }
            if (value.value == "" & value2.value !== "") {
                if (fullistLabel[i].innerText.includes(value2.value)) {
                    tableGO.push(fullList[i])
                }
            }
            if (value.value !== "" & value2.value == "") {
                if (fullistKey[i].innerText.includes(value.value)) {
                    tableGO.push(fullList[i])
                }
            }
            if (value.value !== "" & value2.value != "") {
                if (fullistLabel[i].innerText.includes(value2.value) && fullistKey[i].innerText.includes(value.value)) {
                    tableGO.push(fullList[i])
                }
            }
            fullList[i].style.display = "none"
        }
    }


    for (let i = 0; i < tableGO.length ; i++) {
        tableGO[i].style.display = 'flex';
        tableGO[i].classList.remove('evenTableLine','oddTableLine')
        tableGO[i].classList.add(i%2 ? 'evenTableLine':'oddTableLine')
    }
}


