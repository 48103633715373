import {
    createBrowserRouter,
} from "react-router-dom";
import ForgotPassword from "./StarterKit/Route/ForgotPassword";
import HomePage from "./StarterKit/Route/HomePage";
import ErrorPage from "./StarterKit/Route/ErrorPage";
import Users from "./StarterKit/Route/Users"
import React from "react";
import Settings from "./StarterKit/Route/Settings";
import Login from "./StarterKit/Route/Login";
import Imports from "./App/Route/Imports";
import Logout from "./StarterKit/Route/Logout";
import StockPequignet from "./App/Route/StockPequignet";
import Zones from "./StarterKit/Route/Zones";
import StockShop from "./App/Route/StockShop";
import Groups from "./StarterKit/Route/Groups";
import Rights from "./StarterKit/Route/Rights";
import StockGestion from "./App/Route/StockGestion";
import ManageInvoices from "./App/Route/ManageInvoices";
import ModelsPage from "./App/Route/ModelsPage";
import ModelsAdmin from "./App/Route/ModelsAdmin";
import ProductsPage from "./App/Route/ProductsPage";
import ProductsAdmin from "./App/Route/ProductsAdmin";
import ManageSav from "./App/Route/ManageSav";
import {SavAdd} from "./App/Route/SavAdd";
import ResetPage from "./StarterKit/Route/ResetPage";
import ValidatedInvoices from "./App/Route/ValidatedInvoices";

const ProtectedRoute = ({ allowed, children }) => {
    var profile = sessionStorage['profile']
    if (!allowed.includes(profile)) {
        return <Logout />
    }
    return children;
};

const router = createBrowserRouter([
    {
        path: "/login",
        element: <Login />,
        errorElement: <ErrorPage />
    },
    {
        path: "/logout",
        element: <Logout />,
        errorElement: <ErrorPage />
    },
    {
        path: "/forgotPassword",
        element: <ForgotPassword />,
        errorElement: <ErrorPage />
    },
    {
        path: "/admin/rights",
        element: <ProtectedRoute allowed={['admin']}><Rights /></ProtectedRoute>,
        errorElement: <ErrorPage />
    },
    {
        path: "/admin/settings",
        element: <ProtectedRoute allowed={['admin']}><Settings /></ProtectedRoute>,
        errorElement: <ErrorPage />
    },
    {
        path: "/admin/users",
        element: <ProtectedRoute allowed={['admin']}><Users /></ProtectedRoute>,
        errorElement: <ErrorPage />
    },
    {
        path: "/admin/imports",
        element: <ProtectedRoute allowed={['admin']}><Imports /></ProtectedRoute>,
        errorElement: <ErrorPage />
    },
    {
        path: "/admin/zones",
        element: <ProtectedRoute allowed={['admin']}><Zones /></ProtectedRoute>,
        errorElement: <ErrorPage />
    },
    {
        path: "/admin/groups",
        element: <ProtectedRoute allowed={['admin']}><Groups /></ProtectedRoute>,
        errorElement: <ErrorPage />
    },
    {
        path: "/prod/stock",
        element: <ProtectedRoute allowed={['prod']}><StockPequignet /></ProtectedRoute>,
        errorElement: <ErrorPage />
    },
    {
        path: "/shop/stock",
        element: <ProtectedRoute allowed={['shop']}><StockShop /></ProtectedRoute>,
        errorElement: <ErrorPage />
    },
    {
        path: "/gestion/stock",
        element: <ProtectedRoute allowed={['gestion']}><StockGestion/></ProtectedRoute> ,
        errorElement: <ErrorPage />
    },
    {
        path: "/invoices/manage",
        element: <ProtectedRoute allowed={['invoiceManager']}><ManageInvoices/></ProtectedRoute> ,
        errorElement: <ErrorPage />
    },
    {
        path: "/invoices/historic",
        element: <ProtectedRoute allowed={['invoiceManager']}><ValidatedInvoices/></ProtectedRoute> ,
        errorElement: <ErrorPage />
    },
    {
        path: "/sav",
        element: <ProtectedRoute allowed={['sav']}><ManageSav/></ProtectedRoute> ,
        errorElement: <ErrorPage />
    },
    {
        path: "/sav/add",
        element: <ProtectedRoute allowed={['sav']}><SavAdd/></ProtectedRoute> ,
        errorElement: <ErrorPage />
    },
    {
        path: "/models",
        element: <ProtectedRoute allowed={['prod', 'gestion', 'sav']}><ModelsPage/></ProtectedRoute>,
        errorElement: <ErrorPage />
    },
    {
        path: "/products/:search?",
        element: <ProtectedRoute allowed={['prod', 'gestion', 'sav']}><ProductsPage/></ProtectedRoute>,
        errorElement: <ErrorPage />
    },
    {
        path: "/admin/models",
        element: <ProtectedRoute allowed={['admin']}><ModelsAdmin/></ProtectedRoute>,
        errorElement: <ErrorPage />
    },
    {
        path: "/admin/products/:search?",
        element: <ProtectedRoute allowed={['admin']}><ProductsAdmin/></ProtectedRoute>,
        errorElement: <ErrorPage />
    },
    {
        path: "/",
        element: <HomePage />,
        errorElement: <ErrorPage />,
    },
    {
        path: "/reset/:user/:token",
        element: <ResetPage />,
        errorElement: <ErrorPage />
    },
]);


export default router;
