import HelperService from "./HelperService";
import ApiProfiles from "../Api/ApiProfiles";


export default class RightService {
    #helper;
    #api;

    constructor() {
        this.#api = new ApiProfiles();
        this.#helper = new HelperService();
    }


    async list() {
        let result = await this.#api.getRights();

        return result;
    }

    async add(profile) {
        let right = { "name": profile.name, "zones": {} };

        let result = await this.#api.addProfile(right);

        return result;
    }

    async delete(profile) {

        let result = await this.#api.deleteProfile(profile);

        return result;
    }

    async update(profile, zones) {
        let right = {};
        right["zones"] = zones;
        let result = await this.#api.updateProfile(profile, right);

        return result;
    }
}
