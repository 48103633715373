import PropTypes from "prop-types";
import React, { useEffect, useRef } from 'react';

export const IconActionButton = (props) => {
    const buttonRef = useRef(null);

    useEffect(() => {
        $(buttonRef.current).tooltip('dispose');
        $(buttonRef.current).tooltip({ title: props.tooltip??"" });

        $(buttonRef.current).on('click', function() {
            $(this).tooltip('hide');
        });

        return () => {
            $(buttonRef.current).off('click');
            $(buttonRef.current).tooltip('dispose');
        }
    }, [props.tooltip]);

    return (
        <>
            <button ref={buttonRef} className={props.className ?? "btn actionLink"} onClick={props.action} disabled={!props.state} data-toggle="tooltip" data-placement="top">
                {props.state === false ? (props.disabledIcon ? props.disabledIcon : props.icon) : props.icon }
            </button>
        </>
    );
}



IconActionButton.propTypes = {
    action : PropTypes.func.isRequired,
    className : PropTypes.string,
    icon : PropTypes.element.isRequired,
    disabledIcon : PropTypes.element,
    state : PropTypes.bool.isRequired,
    tooltip: PropTypes.string
}