import React, {useEffect, useState} from "react";
import ModelService from "../Services/Bll/ModelService";
import CacheExtension from "../../StarterKit/Filter/CacheExtension";
import 'semantic-ui-css/semantic.min.css'
import '../css/bo.css'
import '../css/Model.css'
import CollapseComponent from "../../StarterKit/Components/CollapseComponent";
import {IconActionButton} from "../Components/IconActionButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ModelModal} from "../Components/ModelModal";
import {Link} from "react-router-dom";

export default function ModelsPaginateCollapse() {
    const service = new ModelService();

    const [data, setData] = useState(null);
    const [nbModels, setNbModels] = useState(null);
    const [currentPage, setCurrentPage] = useState({});
    const [search, setSearch] = useState("");
    const [filteredData, setFilteredData] = useState(data);

    useEffect(() => {
        service.listAll().then(function (res) {
            setData(res.getData())
            setNbModels(res.getContext().count)
            const initialPage = {};
            Object.keys(res.getData()).forEach(collectionName => {
                initialPage[collectionName] = 1;
            });
            setCurrentPage(initialPage);
        })

    }, [])

    useEffect(() => {
        const result = {};
        const fieldsToSearch = ['variantId', 'gtinSku', 'sku', 'subtitle', 'title', 'description'];
        if (search.length < 3) {
            setFilteredData(data);
        } else {
            for (const collection in data) {
                result[collection] = data[collection].filter(model => {
                    return fieldsToSearch.some(field =>
                        model[field]?.toString().toLowerCase().includes(search.toLowerCase())
                    );
                });
            }
            setFilteredData(result);
        }
    }, [data, search]);


    const handlePageChange = (collectionName, page) => {
        setCurrentPage({...currentPage, [collectionName]: page});
    };

    async function lockModel(key) {
        await service.lock(key);

        const updated = {...data};
        for (let collectionName in updated) {
            updated[collectionName] = updated[collectionName].map(model => {
                if (model.key === key) {
                    return {...model, flagLocked: true};
                } else {
                    return model;
                }
            });
        }

        setData(updated);
    };

    async function unlockModel(key) {
        await service.unlock(key);

        const updated = {...data};
        for (let collectionName in updated) {
            updated[collectionName] = updated[collectionName].map(model => {
                if (model.key === key) {
                    return {...model, flagLocked: false};
                } else {
                    return model;
                }
            });
        }

        setData(updated);
    };

    return (
        <>
            {data &&
                <>
                    <div className="d-flex justify-content-between" style={{padding: sessionStorage["profile"] == "admin" ? "2rem 2rem 2rem 1.25rem" : "1.25rem 1.25rem 1.25rem 1.25rem"}}>
                        <span className={"nbModel"}>{CacheExtension.getDataFromCache('NbModels')} <strong>{nbModels}</strong></span>
                        <div className="input-group w-25">
                            <input type="text" className="form-control"
                                   placeholder={CacheExtension.getDataFromCache('Search')}
                                   id="search-input"
                                   style={{height: "auto"}}
                                   value={search || ''}
                                   onChange={e => setSearch(e.target.value)}/>
                            <div className="input-group-append">
                                <button className="btn" type="button" id="clear-search"
                                        style={{height: "auto"}}
                                        onClick={()=> setSearch("")}>
                                    <FontAwesomeIcon size={"xl"} icon="fas fa-x" />
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className={'modelsSection card-body container fullSize pt-0'}>
                        {filteredData && Object.keys(filteredData).length !== 0 ?
                            <>
                                {Object.keys(filteredData).map((collectionName, i) => {
                                    const nbPages = Math.ceil(filteredData[collectionName].length / 10);
                                    return (
                                        <CollapseComponent key={i} buttonClass={"btn w-100 subTypeTitle"} isSearching={search.length >= 3} hasResults={filteredData[collectionName].length !== 0} buttonText={`${collectionName != "Other" ? collectionName : CacheExtension.getDataFromCache(collectionName)} (${filteredData[collectionName].length})`} dom={
                                            <>
                                                <div className={"d-flex justify-content-end"}>
                                                    <nav>
                                                        <ul className="pagination">
                                                            {[...Array(nbPages).keys()].map((page) => {
                                                                if (page === 0 || page === nbPages - 1 || (page >= currentPage[collectionName] - 2 && page <= currentPage[collectionName]) || (currentPage[collectionName] < 4 && page < 4) || (currentPage[collectionName] >= nbPages - 2 && page >= nbPages - 4)) {
                                                                    return (
                                                                        <li key={page} className={`page-item ${currentPage[collectionName] === page + 1 ? 'active' : ''}`}>
                                                                            <button className={`btn ${currentPage[collectionName] === page + 1 ? 'btnBo' : 'btnBoReverse'}`} onClick={() => handlePageChange(collectionName, page + 1)}>
                                                                                {page + 1}
                                                                            </button>
                                                                        </li>
                                                                    );
                                                                } else {
                                                                    return null;
                                                                }
                                                            })}
                                                        </ul>
                                                    </nav>
                                                </div>
                                                <div className="row mx-0 py-2" style={{borderBottom: '1px solid #dee2e6'}}>
                                                    <div className="col-11">
                                                        <div className="row">
                                                            <div className="col-2">
                                                                <strong>{CacheExtension.getDataFromCache('Title')}</strong>
                                                            </div>
                                                            <div className="col-2">
                                                                <strong>{CacheExtension.getDataFromCache('Subtitle')}</strong>
                                                            </div>
                                                            <div className="col-2">
                                                                <strong>{CacheExtension.getDataFromCache('Sku')}</strong>
                                                            </div>
                                                            <div className="col-2">
                                                                <strong>{CacheExtension.getDataFromCache('VariantId')}</strong>
                                                                <p>({CacheExtension.getDataFromCache('BraceletColorDate')})</p>
                                                            </div>
                                                            <div className="col-2">
                                                                <strong>{CacheExtension.getDataFromCache('GtinSku')}</strong>
                                                            </div>
                                                            <div className="col-2">
                                                                <strong>{CacheExtension.getDataFromCache('NbProducts')}</strong>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-1">
                                                        <div className="row">
                                                            <div className="col-6 d-flex justify-content-center">
                                                                <strong>{CacheExtension.getDataFromCache('Details')}</strong>
                                                            </div>
                                                            <div className="col-6 d-flex justify-content-center">
                                                                <strong>{CacheExtension.getDataFromCache('Locked')}</strong>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="">
                                                    {filteredData[collectionName].slice((currentPage[collectionName] - 1) * 10, currentPage[collectionName] * 10).map((model, j) => (
                                                            <div key={j} className={'' + (j % 2 === 0 ? 'oddTableLine' : 'evenTableLine')}>
                                                                <div className="row mx-0">
                                                                    <div className="col-11">
                                                                        <div className="row py-2">
                                                                            <div className="col-2">
                                                                                <p>{model.title}</p>
                                                                            </div>
                                                                            <div className="col-2">
                                                                                <p>{model.subtitle}</p>
                                                                            </div>
                                                                            <div className="col-2">
                                                                                <p>{model.sku}</p>
                                                                            </div>
                                                                            <div className="col-2">
                                                                                <p>
                                                                                    {model.variantId}<br />
                                                                                    ({model.optionsJson["Type de bracelet"] || 'N/A'}, {model.optionsJson["Couleur"] || 'N/A'}, {model.optionsJson["Date"] || 'N/A'})
                                                                                </p>
                                                                            </div>
                                                                            <div className="col-2">
                                                                                <p>{model.gtinSku}</p>
                                                                            </div>
                                                                            <div className="col-2">
                                                                                {model.nbProducts > 0 ?
                                                                                    <p>
                                                                                        {CacheExtension.getDataFromCache('NbProductsLink').split('%%1%%')[0]}
                                                                                        <span className="productsNb">
                                                                                            <Link to={(sessionStorage['profile'] == "admin" ?  "/admin/products/" : "/products/") + model.variantId}>{model.nbProducts}</Link>
                                                                                        </span>
                                                                                        {CacheExtension.getDataFromCache('NbProductsLink').split('%%1%%')[1]}
                                                                                    </p> :
                                                                                    <p>{CacheExtension.getDataFromCache('NoProducts')}</p>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-1">
                                                                        <div className="row h-100">
                                                                            <div className="col-6 d-flex align-items-center justify-content-center">
                                                                                <ModelModal model={model}/>
                                                                            </div>
                                                                            <div className="col-6 d-flex align-items-center justify-content-center">

                                                                                {sessionStorage['profile'] == "admin" ?
                                                                                    (model.flagLocked == 1 ?
                                                                                        <IconActionButton action={() => unlockModel(model.key)} state={true} className={"btn modelDetails"} tooltip={CacheExtension.getDataFromCache('UnlockModel')} icon={<FontAwesomeIcon icon="fas fa-eye-slash"/>}/>
                                                                                        :
                                                                                        <IconActionButton action={() => lockModel(model.key)} state={true} className={"btn modelDetails"} tooltip={CacheExtension.getDataFromCache('LockModel')} icon={<FontAwesomeIcon icon="fas fa-eye"/>}/>)
                                                                                    :
                                                                                    (model.flagLocked == 1 ?
                                                                                        <FontAwesomeIcon icon="fas fa-eye-slash" />
                                                                                        :
                                                                                        <FontAwesomeIcon icon="fas fa-eye" />)

                                                                                }

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    )}
                                                </div>
                                            </>
                                        }/>
                                    );
                                })}
                            </> :
                            <p className="text-center">{CacheExtension.getDataFromCache('NoModelMatch')}</p>
                        }
                    </div>
                </>
            }
        </>
    );
}

