// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../res/newIcone.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#content .card svg {
    height: 1em;
}

.oddTableLine {
    background-color: var(--sk_oddLine);
}

.evenTableLine {
    background-color: var(--sk_evenLine);
}

.selectedTableLine {
    background-color: var(--sk_selected) !important;
}

.new-group,
.update-group {
    display: flex;
    /*margin: 0 15px;*/
    padding: 8px 0;
    align-items: center;
    justify-content: space-between;
}

.new-group input:first-child{
    margin: 0 20px 0 0 ;
}

.submit-new-group {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-repeat: no-repeat;
    height: 14px;
    overflow: visible;
    vertical-align: -0.125em;
    font-size: 0;
    border: 0;
}

.new-group label {
    display: none;
}

body .open {
    display: block;
}

.formthis {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.formthis .form-group {
    width: 100%;
}

.buttonCompteLock {
    display: inline-block;
}

body .new-group {
    justify-content: space-between;
}

.switchBtn:focus {
    border: none;
    box-shadow: none;
}

.switchBtn {
    transition: none;
}

.btnFilter {
    background-color: white!important;
    border: 1px solid black!important;
}


`, "",{"version":3,"sources":["webpack://./src/styles/groups.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;;AAEA;IACI,mCAAmC;AACvC;;AAEA;IACI,oCAAoC;AACxC;;AAEA;IACI,+CAA+C;AACnD;;AAEA;;IAEI,aAAa;IACb,kBAAkB;IAClB,cAAc;IACd,mBAAmB;IACnB,8BAA8B;AAClC;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,yDAA0C;IAC1C,4BAA4B;IAC5B,YAAY;IACZ,iBAAiB;IACjB,wBAAwB;IACxB,YAAY;IACZ,SAAS;AACb;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,eAAe;IACf,8BAA8B;AAClC;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,8BAA8B;AAClC;;AAEA;IACI,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,iCAAiC;IACjC,iCAAiC;AACrC","sourcesContent":["#content .card svg {\n    height: 1em;\n}\n\n.oddTableLine {\n    background-color: var(--sk_oddLine);\n}\n\n.evenTableLine {\n    background-color: var(--sk_evenLine);\n}\n\n.selectedTableLine {\n    background-color: var(--sk_selected) !important;\n}\n\n.new-group,\n.update-group {\n    display: flex;\n    /*margin: 0 15px;*/\n    padding: 8px 0;\n    align-items: center;\n    justify-content: space-between;\n}\n\n.new-group input:first-child{\n    margin: 0 20px 0 0 ;\n}\n\n.submit-new-group {\n    background-image: url(../res/newIcone.svg);\n    background-repeat: no-repeat;\n    height: 14px;\n    overflow: visible;\n    vertical-align: -0.125em;\n    font-size: 0;\n    border: 0;\n}\n\n.new-group label {\n    display: none;\n}\n\nbody .open {\n    display: block;\n}\n\n.formthis {\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: space-between;\n}\n\n.formthis .form-group {\n    width: 100%;\n}\n\n.buttonCompteLock {\n    display: inline-block;\n}\n\nbody .new-group {\n    justify-content: space-between;\n}\n\n.switchBtn:focus {\n    border: none;\n    box-shadow: none;\n}\n\n.switchBtn {\n    transition: none;\n}\n\n.btnFilter {\n    background-color: white!important;\n    border: 1px solid black!important;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
