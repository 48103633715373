import CacheExtension from "../../StarterKit/Filter/CacheExtension";
import React, {useEffect, useState,} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconActionButton} from "./IconActionButton";
import ClientService from "../Services/Bll/ClientService";

export const UserModal = ({owner}) => {
    const clientService = new ClientService();

    const [open, setOpen] = useState(false)
    const [data, setData] = useState(null)

    $(function () {
        $('[data-toggle="tooltip"]').tooltip()
    })

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        clientService.read(owner).then((res) => {
            const r = res.getData()
            setData(r)
            setOpen(true);
        })

    };

    return (
        <>
            <IconActionButton tooltip={CacheExtension.getDataFromCache("User")} action={handleOpen} state={true} className={"btn modelDetails"} icon={<FontAwesomeIcon icon="fas fa-user" />}></IconActionButton>
            {open && data && data.length != 0 && (
                <div className="modal-container">
                    <div className="modal-overlay" onClick={handleClose}></div>
                    <div className="modal-dialog w-50" style={{maxWidth: "none", height: "90%"}}>
                        <div className="modal-content" style={{height: "100%"}}>
                            <div className="modal-header pb-0">
                                <h1 className="QrTitle mb-2">{CacheExtension.getDataFromCache("User")}</h1>
                                <button type="button" className="close" onClick={handleClose}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            {(data && data.length != 0) ?
                                <div className="fullSize modal-body">
                                    <div className={'form-group'}>
                                        <label className={'mb-1'}><strong>{CacheExtension.getDataFromCache("Name")}</strong></label>
                                        <p>{data.name}</p>
                                    </div>

                                    <div className={'form-group'}>
                                        <label className={'mb-1'}><strong>{CacheExtension.getDataFromCache("Lastname")}</strong></label>
                                        <p>{data.lastName}</p>
                                    </div>

                                    <div className={'form-group'}>
                                        <label className={'mb-1'}><strong>{CacheExtension.getDataFromCache("Email")}</strong></label>
                                        <p>{Object.keys(data.mails).find(key => data.mails[key] === 1)}</p>
                                    </div>
                                    {Object.keys(data.addresses).length !== 0 &&
                                        <div className={'form-group'}>
                                            <label className={'mb-1'}><strong>{CacheExtension.getDataFromCache("Address")}</strong></label>
                                            <p>{Object.keys(data.addresses).find(key => data.addresses[key] === 1)}</p>
                                        </div>
                                    }
                                    {Object.keys(data.phones).length !== 0 &&
                                        <div className={'form-group'}>
                                            <label className={'mb-1'}><strong>{CacheExtension.getDataFromCache("Phone")}</strong></label>
                                            <p>{Object.keys(data.phones).find(key => data.phones[key] === 1)}</p>
                                        </div>
                                    }
                                </div> :
                                <div className="m-3">{CacheExtension.getDataFromCache('NoUser')}</div>
                            }
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
