import React, {useEffect, useState} from "react";
import {motion as m} from "framer-motion";
import CacheExtension from "../../StarterKit/Filter/CacheExtension";
import 'semantic-ui-css/semantic.min.css'
import {Nav} from "../../components/navComponents";
import {Header} from "../../components/headerComponent";
import Models from "../Components/Models";
import SettingService from "../../StarterKit/Service/Bll/SettingService";
import ModelsPaginateCollapse from "../Components/ModelsPaginateCollapse";
import ModelsPaginate from "../Components/ModelsPaginate";

export default function ModelsAdmin() {
    var serviceSettings = new SettingService();

    const [paginate, setPaginate] = useState(false);
    const [collapse, setCollapse] = useState(false);

    useEffect(() => {
        serviceSettings.getFromGroup("app").then(function (res) {
            setPaginate(res.getData()['paginate'].text === 'yes')
            setCollapse(res.getData()['collapseList'].text === 'yes')
        })

    }, [])

    return (
        <>
            <m.div className={"wrapper"}>
                <>
                    <Nav />
                    <div id={"page"}>
                        <Header />
                        <div id="content">
                            <div className="card">
                                <div className="card-header">
                                    <h3>{CacheExtension.getDataFromCache('Models')}</h3>
                                </div>
                                {collapse ?
                                    <ModelsPaginateCollapse/> :
                                    <ModelsPaginate/>
                                }
                            </div>
                        </div>
                    </div>
                </>
            </m.div>
        </>
    );
}

