import {BaseApiService} from "../../../StarterKit/Service/BaseApiService";


export default class ApiProduct extends BaseApiService {

    async received(shop,key) {
        this.setPostMethod();
        this.setSecureByUser();
        this.setApiRoute(sessionStorage['language'] + "/shops/"+shop+'/'+key+"/received");
        return await this.callApi();
    }

    async send(key,shop) {
        this.setPostMethod();
        this.setSecureByUser();
        this.setShowReturn();
        this.setApiRoute(sessionStorage['language'] + "/shops/"+shop+'/'+key+"/sent");
        return await this.callApi();
    }

    async destroy(key) {
        this.setPostMethod();
        this.setSecureByUser();
        this.setApiRoute(sessionStorage['language'] + "/products/"+key+"/destroyed");
        return await this.callApi();
    }

    async lost(key) {
        this.setPostMethod();
        this.setSecureByUser();
        this.setApiRoute(sessionStorage['language'] + "/products/"+key+"/lost");
        return await this.callApi();
    }

    async stolen(key) {
        this.setPostMethod();
        this.setSecureByUser();
        this.setApiRoute(sessionStorage['language'] + "/products/"+key+"/stolen");
        return await this.callApi();
    }

    async getToken(sealVector,sku,product) {
        this.setPostMethod();
        this.setSecureByUser();
        this.callNeedDataInBody();
        this.setBody({
            sealVector,
            sku,
            product
        });
        this.setApiRoute(sessionStorage['language'] + "/products/generateToken");
        return await this.callApi();
    }

    async read(gtin, serial) {
        this.setGetMethod();
        this.setSecureByService();
        this.setApiRoute(sessionStorage['language'] + "/products/"+gtin+"/"+serial);
        return await this.callApi();
    }

    async listFromModel(key) {
        this.setGetMethod();
        this.setSecureByUser();
        this.setApiRoute(sessionStorage['language'] + "/products_model/"+key);
        return await this.callApi();
    }

    async listState(state) {
        this.setGetMethod();
        this.setSecureByUser();
        this.setApiRoute(sessionStorage['language'] + "/products/list_state/" + state);
        return await this.callApi();
    }

    async listInvoices(state, page, skuSearch, variantIdSearch, serialSearch, orderField, orderDirection) {
        this.setGetMethod();
        this.setSecureByUser();
        this.setApiRoute(`${sessionStorage['language']}/products/list_invoices/${state}?page=${page}&sku=${skuSearch}&variantId=${variantIdSearch}&serial=${serialSearch}&orderField=${orderField}&orderDirection=${orderDirection}`);
        return await this.callApi();
    }
    async validate(product) {
        this.setPostMethod();
        this.setSecureByUser();
        this.setApiRoute(sessionStorage['language'] + "/products/" + product + "/validate");
        return await this.callApi();
    }

    async validateInvoice(product, form) {
        this.setPostMethod();
        this.setSecureByUser();
        this.setBody(form);
        this.setApiRoute(sessionStorage['language'] + "/products/" + product + "/validateInvoice");
        return await this.callApi();
    }

    async refuseInvoice(product) {
        this.setPostMethod();
        this.setSecureByUser();
        this.setApiRoute(sessionStorage['language'] + "/products/" + product + "/refuseInvoice");
        return await this.callApi();
    }

    async updateWarrantyDates(product, form) {
        this.setPutMethod();
        this.setSecureByUser();
        this.setBody(form);
        this.setApiRoute(sessionStorage['language'] + "/products/" + product + "/warranty");
        return await this.callApi();
    }

    async linkToModel(product, model) {
        this.setPostMethod();
        this.setSecureByUser();
        this.setApiRoute(sessionStorage['language'] + "/products/" + product + "/model/" + model);
        return await this.callApi();
    }

    async sell(product, form, file = null) {
        this.setPostMethod();
        this.setSecureByUser();
        this.callNeedDataInBody();
        this.setBody(form);
        if(file) {
            this.callNeedFileInBody();
            this.setFile(file);
        }
        this.setShowReturn()
        this.setApiRoute(sessionStorage['language'] + "/products/" + product + "/sell");
        return await this.callApi();
    }

    async sellAndCreate(product, form, file = null) {
        this.setPostMethod();
        this.setSecureByUser();
        this.callNeedDataInBody(); //todo check needDataInBody & setBody: hasFile = false
        this.setBody(form);
        if(file) {
            this.callNeedFileInBody();
            this.setFile(file);
        }
        this.setShowReturn()
        this.setApiRoute(sessionStorage['language'] + "/products/" + product + "/sellAndCreate");
        return await this.callApi();
    }

    async listAll(page, skuSearch, variantIdSearch, serialSearch, orderField, orderDirection) {
        this.setGetMethod();
        this.setSecureByUser();
        this.setApiRoute(`${sessionStorage['language']}/products?page=${page}&sku=${skuSearch}&variantId=${variantIdSearch}&serial=${serialSearch}&orderField=${orderField}&orderDirection=${orderDirection}`);
        return await this.callApi();
    }

    async listAllSav(page, skuSearch, variantIdSearch, serialSearch, orderField, orderDirection) {
        this.setGetMethod();
        this.setSecureByUser();
        this.setApiRoute(`${sessionStorage['language']}/products/sav?page=${page}&sku=${skuSearch}&variantId=${variantIdSearch}&serial=${serialSearch}&orderField=${orderField}&orderDirection=${orderDirection}`);
        return await this.callApi();
    }

    async addSav(product, form) {
        this.setPostMethod();
        this.setSecureByUser();
        this.setBody(form);
        this.setApiRoute(sessionStorage['language'] + "/products/" + product + "/sav/add");
        return await this.callApi();
    }

    async closeSav(sav, form) {
        this.setPutMethod();
        this.setSecureByUser();
        this.setBody(form);
        this.setApiRoute(sessionStorage['language'] + "/products/" + sav + "/sav/close");
        return await this.callApi();
    }
    async checkModelSerialExist(sku, serial) {
        this.setGetMethod();
        this.setSecureByUser();
        this.setApiRoute(sessionStorage['language'] + "/products/" + sku + "/" + serial + "/check");
        return await this.callApi();
    }

    async checkSealNumber(product, sealNumber) {
        this.setGetMethod();
        this.setSecureByUser();
        this.setApiRoute(sessionStorage['language'] + "/products/" + product + "/" + sealNumber + "/checkSealNumber");
        return await this.callApi();
    }

    async updateSav(sav, form) {
        this.setPutMethod();
        this.setSecureByUser();
        this.setBody(form);
        this.setApiRoute(sessionStorage['language'] + "/products/" + sav + "/sav/edit");
        return await this.callApi();
    }

    async listSavForProduct(product) {
        this.setGetMethod();
        this.setSecureByUser();
        this.setApiRoute(sessionStorage['language'] + "/products/" + product + "/sav");
        return await this.callApi();
    }

    async updateShop(product, shop) {
        this.setPutMethod();
        this.setSecureByUser();
        this.setApiRoute(sessionStorage['language'] + "/products/" + product + "/shops/" + shop);
        return await this.callApi();
    }

    async removeSerial(key) {
        this.setPutMethod();
        this.setSecureByUser();
        this.setApiRoute(sessionStorage['language'] + "/products/" + key + "/removeSerial");
        return await this.callApi();
    }

    async removeFromShop(key) {
        this.setPutMethod();
        this.setSecureByUser();
        this.setApiRoute(sessionStorage['language'] + "/products/" + key + "/removeShop");
        return await this.callApi();
    }


    async updateLinkedModel(product, model) {
        this.setPutMethod();
        this.setSecureByUser();
        this.setApiRoute(sessionStorage['language'] + "/products/" + product + "/" + model + "/updateLinkedModel");
        return await this.callApi();
    }

    async importInvoice(file, product) {
        this.setPostMethod();
        this.setSecureByUser();
        this.callNeedFileInBody();
        this.setFile(file);
        this.setShowReturn();
        this.setApiRoute(sessionStorage['language'] + "/products/" + product + "/importInvoice");
        return await this.callApi();
    }


    async listAllKey() {
        this.setGetMethod();
        this.setSecureByUser();
        this.setApiRoute(sessionStorage['language'] + "/products/listAllKey");
        return await this.callApi();
    }

    async regenerateToken(product) {
        this.setPostMethod();
        this.setSecureByUser();
        this.setApiRoute(sessionStorage['language'] + "/products/regenerate/"+product);
        return await this.callApi();
    }
}
