import PropTypes from "prop-types";
import CacheExtension from "../../StarterKit/Filter/CacheExtension";
import React, {useEffect, useState,} from "react";
import {disable} from "workbox-navigation-preload";




export const ConfirmModalComponent = (props) => {

    const [open, setOpen] = useState(false);

    const CreateGuid = () => {
        function _p8(s) {
            var p = (Math.random().toString(16) + "000000000").substr(2, 8);
            return s ? "-" + p.substr(0, 4) + "-" + p.substr(4, 4) : p;
        }

        return _p8() + _p8(true) + _p8(true) + _p8();
    }

    var id = CreateGuid()

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    return (
        <>
                {props.actionLink ?
                    <button disabled={props.disabled} className={"btn actionLink"} onClick={handleOpen} data-toggle="tooltip" data-placement="top" title={props.title}>{props.icon}
                    </button> :
                    <button disabled={props.disabled} className={"btn btnBo " + props.class} onClick={handleOpen}>{props.icon}
                    </button>
                }
                {open &&
                    <div>
                        <div id={'skModal'} className="modal-container" style={{alignItems:"start"}}>
                            <div className="modal-overlay" onClick={handleClose}></div>
                            <div className="modal-dialog" style={{width: "40%"}}>
                                <div className={"modal-content"}>
                                    <div className="modal-header pb-0">
                                        <h1 className={"QrTitle mb-2"}>{props.title}</h1>
                                        <button type="button" className="close" onClick={handleClose}>
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className={'fullSize modal-body'}>
                                        {props.html && props.html}
                                        <div className={'d-flex justify-content-center align-items-center'}>
                                            <div>
                                                <button disabled={props.disabledSubmit} className={'btn btnBo submit'} onClick={()=> {
                                                    props.action();
                                                    handleClose()
                                                }}>{CacheExtension.getDataFromCache("Validate")}</button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
        </>
    );
}

ConfirmModalComponent.propTypes = {
    action : PropTypes.func.isRequired,
    icon : PropTypes.element.isRequired,
    title : PropTypes.string.isRequired,
    html: PropTypes.element,
    disabled: PropTypes.bool,
    disabledSubmit: PropTypes.bool,
    class: PropTypes.string,
    actionLink: PropTypes.bool
}
