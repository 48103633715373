import '../../styles/login.css';
import '../../styles/sk.css';
import '../../styles/rights.css';
import {motion as m} from "framer-motion"
import React, {useEffect, useState} from "react";
import { Nav } from "../../components/navComponents"
import { Header } from "../../components/headerComponent"
import CacheExtension from "../Filter/CacheExtension";
import RightService from "../Service/Bll/RightService";
import {IconActionButton} from "../../App/Components/IconActionButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import SettingService from "../Service/Bll/SettingService";
import Autoform from "../Autoform/Autoform";
import {createRoot} from "react-dom/client";

export default function profiles() {
    $(function () {
        $('[data-toggle="tooltip"]').tooltip()
    })
    let service = new RightService();
    let settingService = new SettingService();

    const [profiles, setProfiles] = useState(null);
    const [zoneNames, setZoneNames] = useState(null);
    const [profileNames, setProfileNames] = useState(null);

    let tabSize = 4;
    let nbTab = 1;
    let valueForm = null

    async function fetchData() {
        let res = await service.list()
        setProfiles(res)
        let zoneRes = await settingService.getFromGroup("securityZone")
        let profileRes = await settingService.getFromGroup("profile")
        setZoneNames(zoneRes.getData())
        setProfileNames(profileRes.getData())
    }
    useEffect( () => {

        fetchData();
    }, []);

    function sortProfiles(profiles, keyOrder) {
        const sortedProfiles = {};

        const remainingKeys = Object.keys(profiles).filter((key) => !keyOrder.includes(key)).sort();

        [...keyOrder.sort(), ...remainingKeys].forEach((key) => {
            sortedProfiles[key] = profiles[key];
        });

        return sortedProfiles;
    }

    async function addProfile(key,setting) {
        let res = await service.list()
        valueForm = res.context.forms
        var container__modal = document.querySelector('.container__modal')
        var autoform = new Autoform([
            {
                submitCallback: async() => {
                    autoform.closeModal();
                    fetchData();
                },
                groupsForm: "",
                langForm: "",
                declaration: 'add',
                methodRoute: "post",
                route: "profiles",
                keyName: 'new',
                data: valueForm.new,
                modale: true,
                name: 'profile',
                classInput: 'form-control',
                cancel: CacheExtension.getDataFromCache('cancel'),
                submit: CacheExtension.getDataFromCache('submit'),
                modaleHeader: CacheExtension.getDataFromCache('HeaderAddProfile'),
                hidden: true
            }
        ], RightService);
        const root = createRoot(container__modal);
        var a = root.render(autoform.render());
        setTimeout(() => {
            autoform.openModal(key,setting)
        }, "100")
    }

    const deleteProfile = (key) => {
        var container__modal = document.querySelector('.container__modal')
        var autoform = new Autoform([
            {
                arrayFinal: [key],
                submitCallback: () => {
                    setProfiles((prevProfiles) => {
                        const newProfiles = { ...prevProfiles };
                        delete newProfiles[key];
                        return newProfiles;
                    });
                    autoform.closeModal();
                },
                groupsForm: "",
                langForm: "",
                idModal: key,
                methodRoute: "delete",
                declaration: 'delete',
                route: `profiles`,
                keyName: 'delete ',
                data: {},
                modale: true,
                name: 'profile',
                classInput: 'form-control',
                cancel: CacheExtension.getDataFromCache('cancel'),
                submit: CacheExtension.getDataFromCache('submit'),
                modaleHeader: CacheExtension.getDataFromCache('deleteConfirmationHeader'),
                hidden: true
            }
        ], RightService);
        const root = createRoot(container__modal);
        var a = root.render(autoform.render());
        setTimeout(() => {
            autoform.openModal(key, "");
        }, "100");
    };

    async function updateRights(profile, zones) {
        let res = await service.update(profile, zones)
        setProfiles(prevProfiles => {
            const updatedProfiles = { ...prevProfiles };
            updatedProfiles.data[profile].zones = res.getData();
            return updatedProfiles;
        });
    }

    function createContent  (){
        let elements = [];
        let zones = [];
        let sortedProfiles = sortProfiles(profiles.data, profiles.context.count);
        console.log(sortedProfiles)
        Object.entries(sortedProfiles).forEach(([profile, data]) => {
            Object.keys(data.zones).forEach((zoneName) => {
                if (!zones.includes(zoneName)) {
                    zones.push(zoneName);
                }
            });
        });

        for (let tabIndex = 1; tabIndex <= nbTab; tabIndex++) {
            let tabMin = (tabIndex - 1) * tabSize;
            let tabMax = tabIndex * tabSize;
            let background = 'oddTableLine'
            function switchBg () {
                background == 'oddTableLine' ? background = 'evenTableLine' : background = 'oddTableLine';
            }
            let profilesForTab = Object.entries(sortedProfiles).slice(tabMin, tabMax);

            elements.push(
                <div
                    key={tabIndex}
                    className={`tab-pane fade show ${tabIndex === 1 ? 'active' : ''}`}
                    id={"rightTab_" + tabIndex}
                    role="tabpanel"
                    aria-labelledby={"rightTab_" + tabIndex}
                >
                    <div className="row mt-2 mb-3">
                        <div className="col-3">&nbsp;</div>
                        {profileNames && profilesForTab.map((profile, i) => (
                            <div className="col-2 text-center" key={i}>
                                <strong
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title={CacheExtension.getDataFromCache("RightsProfileNameTooltip").replace(
                                        '%%1%%',
                                        profile[1].users.active
                                    )}
                                >
                                    <a href={"admin/users?profile=" + profile[0]}>{profileNames[profile[0]] && profileNames[profile[0]].text}</a>
                                </strong>
                                {!profiles.context.count.includes(profile[0]) &&
                                    <IconActionButton action={() => deleteProfile(profile[0])} state={true} icon={<FontAwesomeIcon icon="fas fa-trash-alt" className="mr-2" title={CacheExtension.getDataFromCache('RightsDeleteProfileTooltip')}/>}/>
                                }
                            </div>
                        ))}
                        <div className="col-1 text-center">
                            <IconActionButton tooltip={CacheExtension.getDataFromCache('RightsAddProfileTooltip')} action={() => addProfile()} state={true} icon={<FontAwesomeIcon icon="fas fa-plus-square"/>}/>
                        </div>
                    </div>
                    <div>
                        {zoneNames && zones.map((zone, index) => (
                            <div key={index} className={"row pt-2 pb-2 " + background}>
                                <div className="col-3">
                                    <strong className="ml-2">{zoneNames[zone].text}</strong>
                                </div>
                                {profilesForTab.map((profile, i) => (
                                    <div key={i} className="col-2 text-center">
                                        {profile[1].zones[zone] == 2 && (
                                            <IconActionButton tooltip={CacheExtension.getDataFromCache('RightsFullAccessTooltip')} action={ () => updateRights(profile[0],{ ...profile[1].zones, [zone]: 0 } )} state={true} icon={<FontAwesomeIcon icon="fas fa-thumbs-up" className="text-success"/>}/>
                                        )}
                                        {profile[1].zones[zone] == 1 && (
                                            <IconActionButton tooltip={CacheExtension.getDataFromCache('RightsReadOnlyTooltip')} action={() => updateRights(profile[0],{ ...profile[1].zones, [zone]: 2 } )} state={true} icon={<FontAwesomeIcon icon="fas fa-book" className="text-success"/>}/>
                                        )}
                                        {profile[1].zones[zone] == 0 && (
                                            <IconActionButton tooltip={CacheExtension.getDataFromCache('RightsForbiddenTooltip')} action={() => updateRights(profile[0],{ ...profile[1].zones, [zone]: 1 } )} state={true} icon={<FontAwesomeIcon icon="fas fa-ban" className="text-danger"/>}/>
                                        )}
                                    </div>
                                ))}
                                {switchBg()}
                            </div>
                        ))}
                    </div>
                </div>
            );
        }
        return elements;
    };

    function createTabs () {
        let nbProfile = Object.keys(profiles.data).length;
        let elements = [];
        nbTab = Math.ceil(nbProfile / tabSize)

        if(nbProfile > tabSize) {
            for(let i = 1; i <= nbTab; i++){
                elements.push(
                    <li key={i} className="nav-item">
                        <a className={`nav-link "  ${i === 1 ? 'active' : ''}`}
                           href={"#rightTab_"+i}
                           id={"rightTabControl_"+i}
                           data-toggle="tab"
                           role="tab"
                           aria-controls={"rightTab_"+i}
                           aria-selected={(i == 1) ? 'true' : 'false'}>
                            {"Page " + i}
                        </a>
                    </li>
                );
            }
        } else {
            nbTab = 1;
        }
        return elements;
    }

    return (
        <>
            <m.div className={"wrapper"}>
                <Nav />
                <div id={"page"}>
                    <Header />
                    {profiles &&
                        <div id="content">
                            <div className="card">
                                <div className="card-header">
                                    <h3>{CacheExtension.getDataFromCache('RightsPageTitle')}</h3>
                                </div>
                                <div className="card-body container fullSize">
                                    {Object.keys(profiles.data).length > tabSize &&
                                        <ul className="nav nav-pills justify-content-center mb-5" role="tablist">
                                            {createTabs()}
                                        </ul>
                                    }
                                    <div className="tab-content border container fullSize">
                                        {createContent()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </m.div>
        </>
    );
}






