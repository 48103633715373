import PropTypes from "prop-types";
import CacheExtension from "../../StarterKit/Filter/CacheExtension";
import React, {useEffect, useState,} from "react";
import {disable} from "workbox-navigation-preload";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";




export const DeleteSerialModalComponent = ({product}) => {
    const [inputValue, setInputValue] = useState('');
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setInputValue("")
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    return (
        <>
            <button className={"btn actionLink"} onClick={handleOpen}><FontAwesomeIcon className={"btn text-danger"} icon="fas fa-x"/></button>
            {open &&
                <div>
                    <div id={'skModal'} className="modal-container" style={{alignItems:"start"}}>
                        <div className="modal-overlay" onClick={handleClose}></div>
                        <div className="modal-dialog" style={{width: "40%"}}>
                            <div className={"modal-content"}>
                                <div className="modal-header pb-0">
                                    <h1 className={"QrTitle mb-2"}>{CacheExtension.getDataFromCache('RemoveSerial')}</h1>
                                    <button type="button" className="close" onClick={handleClose}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className={'fullSize modal-body'}>
                                    <div className="mb-5">
                                        <div className="mb-3">
                                            <label><strong>{CacheExtension.getDataFromCache("RemoveSerialText")}</strong></label>
                                            <input
                                                type="text"
                                                value={inputValue}
                                                onChange={e => setInputValue(e.target.value)}
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                    <div className={'d-flex justify-content-center align-items-center'}>
                                        <div>
                                            <button
                                                className={'btn btnBo submit'}
                                                disabled={inputValue.toLowerCase() !== 'valider'}
                                                onClick={async()=> {
                                                    await removeSerial(product.key)
                                                    handleClose()
                                                }}
                                            >
                                                {CacheExtension.getDataFromCache("Validate")}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

DeleteSerialModalComponent.propTypes = {
    product : PropTypes.string.isRequired
}
