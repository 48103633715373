import React, {useState, useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';
import reportWebVitals from './reportWebVitals';
import {RouterProvider, Navigate} from "react-router-dom";
import router from "./router";


import "./fonts/Belluga.woff2";
import "./fonts/Clother-Bold.ttf";
import "./fonts/Clother-Bold.woff";
import "./fonts/Clother-Bold.woff2";
import "./fonts/Clother-Light.woff";
import "./fonts/Clother-Light.woff2";
import "./fonts/Clother-Light.ttf";
import "./fonts/Clother-Regular.woff";
import "./fonts/Clother-Regular.woff2";
import "./fonts/Clother-Regular.ttf";
import "./fonts/Silvana-Light.woff";
import "./fonts/Silvana-Light.woff2";
import "./fonts/Silvana-LightItalic.woff";
import "./fonts/Silvana-LightItalic.woff2";
import "./styles/fonts.css";


import { library } from '@fortawesome/fontawesome-svg-core'

// import your icons
import { fas } from '@fortawesome/free-solid-svg-icons'
import HelperService from "./App/Services/Bll/HelperService";
import SkHelperService from "./StarterKit/Service/Bll/HelperService";
import {pdfjs} from "react-pdf";
var skHelperService = new SkHelperService()
var helperService = new HelperService()

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.js',
    import.meta.url,
).toString();
if (sessionStorage.length === 0){
    skHelperService.logoutUser()
}
if (location.pathname === '/login' && sessionStorage['isLogged'] !== 'yes'){
    localStorage.clear()
}
await helperService.setLocalStorage();
setInterval(async () => {
    await helperService.setLocalStorage();
}, 5 * 60 * 1000);


const ErrorBoundary = ({ children }) => {
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        const errorHandler = (error, message, ...params) => {
            console.error(error, message, ...params);
            setHasError(true);
        };

        window.addEventListener('error', errorHandler);
        return () => window.removeEventListener('error', errorHandler);
    }, []);

    if (hasError) {
        return (  <Navigate to="/login" replace={true} />)
    }

    return children;
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ErrorBoundary>
        <RouterProvider router={router} />
    </ErrorBoundary>
);



// // If you want your App to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.register();

// If you want to start measuring performance in your App, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
library.add(fas)
