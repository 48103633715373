import React from "react";
import PropTypes from "prop-types";
import '../css/ProductCardDetail.css';
import '../css/bo.css'
import {ProductListModal} from "./ProductListModal";
import CacheExtension from "../../StarterKit/Filter/CacheExtension";



export const ProductCardDetail = (props) => {
    return (
        <>
            <div data-sku={props.sku ?? ''} data-key={props.data.key} className={'p-2'} style={{minWidth : '300px',flex: '0 0 25%'}}>
                <div className="productCard">
                    <div className="productCardTitle py-2" style={{minHeight:'50px'}}>
                        {props.data.title && <strong>{props.data.title}</strong>}
                        {props.data.subtitle && <div style={{fontSize:'10px'}}>{props.data.subtitle}</div>}
                    </div>
                    <div className={'productCardContent mb-1'}>
                        <div className={'productCardHeader p-2 d-flex justify-content-between w-100'}>
                            {(props.sku !== undefined && props.data.price !== undefined) &&
                                <>
                                    <div className={"text-left"}>
                                        {
                                            Object.entries(props.data.options).map(([key, value], index) => (
                                                <div key={index}><strong>{key} : </strong>{value}</div>
                                            ))
                                        }
                                        <div><strong>{CacheExtension.getDataFromCache('VariantId')} : </strong>{props.data.variantId}</div>
                                    </div>
                                    {/*<span>{props.data.price.toLocaleString('fr-FR')} <FontAwesomeIcon icon="fa-solid fa-euro-sign" /></span>*/}
                                </>
                            }
                        </div>
                        <div className={'productCardPicture'} style={{backgroundImage : `url(${props.data.image})`,mixBlendMode:'multiply' }}></div>
                        {(props.data.stock !== undefined) &&
                            <>
                                <div className="d-flex align-items-center p-2 w-100 justify-content-start">
                                    <span> stock : {props.data.stock}</span>
                                    {props.data.stock !== 0 && (
                                            <ProductListModal icon={"fa-solid fa-list"} model={props.data.key}></ProductListModal>
                                    )}
                                </div>

                            </>
                        }
                        <div className={'productCardButtons d-flex justify-content-center'}>
                            {/*{(props.data.stock !== undefined && props.data.stock !== 0 && (props.buttons === undefined || props.buttons)) &&*/}
                            {/*    <>*/}
                            {/*        <ModaleQrCode icon={<FontAwesomeIcon icon="fa-solid fa-dollar-sign" />} tooltip={CacheExtension.getDataFromCache('SellModal')}  action={productService.sell} customInputs={["firstname", "lastname"]} ></ModaleQrCode>*/}
                            {/*        <ModaleQrCode icon={<FontAwesomeIcon icon="fa-solid fa-person-circle-question" />} tooltip={CacheExtension.getDataFromCache('LostModal')}  action={function (key){*/}
                            {/*            var productService = new ProductService()*/}
                            {/*            productService.lost(key)*/}
                            {/*        }}></ModaleQrCode>*/}
                            {/*        <ModaleQrCode icon={<FontAwesomeIcon icon="fa-solid fa-heart-crack" />} tooltip={CacheExtension.getDataFromCache('DestroyedModal')} action={function (key){*/}
                            {/*            var productService = new ProductService()*/}
                            {/*            productService.destroyed(key)*/}
                            {/*        }}></ModaleQrCode>*/}
                            {/*        <ModaleQrCode icon={<FontAwesomeIcon icon="fa-solid fa-wrench" />} tooltip={CacheExtension.getDataFromCache('SavModal')} action={() => console.log('SAV ' + props.data.key)}></ModaleQrCode>*/}
                            {/*    </>*/}
                            {/*}*/}
                            {props.customButtons && props.customButtons.map(function (i) {
                                if ((props.data.stock === 0 && i.props.noStock) || props.data.stock > 0){
                                    return i;
                                }
                            })}
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
}

ProductCardDetail.propTypes = {
    data : PropTypes.object.isRequired,
    sku : PropTypes.string,
    customButtons : PropTypes.array,
    buttons : PropTypes.bool,
}
