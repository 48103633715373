import '../../styles/login.css';
import '../../styles/sk.css';
import '../../styles/zone.css';
import * as ReactDOM from 'react-dom';
import axios from 'axios';
import {motion as m} from "framer-motion"
import { createRoot } from 'react-dom/client';
import React, {useEffect, useState,} from "react";
import { Nav } from "../../components/navComponents"
import { Header } from "../../components/headerComponent"
import { Footer } from "../../components/footerComponent";
import ReactDomServer from "react-dom/server"
import Autoform from "../Autoform/Autoform"
import ZoneService from "../Service/Bll/ZoneService";
import Sk from "../Sk";
import SettingService from "../Service/Bll/SettingService";
import CacheExtension from "../Filter/CacheExtension";



export default function zone() {
    $(function () {
        $('[data-toggle="tooltip"]').tooltip()
    })
    const [post, setPost] = React.useState(null);
    const [getPost, setGetPost] = React.useState(null);

    var service = new ZoneService()
    if(!post) {
        service.list("api").then(function (result) {
            setPost(result)
        })
        return null;
    }
    var valueForm = post.context['forms']
    function zoneChangeGroup() {
        var Zone_groupList = document.getElementById('Zone_groupList')
        var Zone_languageList = document.getElementById("Zone_languageList")
        if (Zone_groupList && Zone_languageList.value) {
            var url =  window.location.pathname + "?group=" + Zone_groupList.value + "&lang=" + Zone_languageList.value
            window.location.href = url
        }
    }
    function updateZone(key,Zone) {
        var custom_select = document.getElementById("zones_tierList")
        var tier = custom_select.value
        var arrayFinal = [tier,key]
        var container__modal = document.querySelector('.container__modal')
        var openModal = new Autoform([{status: 'refresh',arrayFinal :arrayFinal, submitCallback: "", dataModal : Zone, idModal : key,  methodRoute : "put" ,  declaration: 'update' , route : "securityZone" , keyName : 'update' , data : valueForm.update , modale : true , name : 'user' , classInput : 'form-control' , cancel : 'cancel' , submit : 'submit' , modaleHeader : "HeaderUpdateUser" , hidden : true  }],ZoneService)
        const root = createRoot(container__modal);
        var a = root.render(openModal.render());
        setTimeout(() => {
            openModal.openModal(key,Zone)
        }, "100")
    }

    function deleteZone(key) {
        var custom_select = document.getElementById("zones_tierList")
        var tier = custom_select.value
        var arrayFinal = [tier,key]
        var container__modal = document.querySelector('.container__modal')
        var openModal = new Autoform([{status: 'refresh',arrayFinal :arrayFinal,submitCallback: "", idModal : key,  methodRoute : "delete" ,  declaration: 'delete' , route : "securityzone" , keyName : 'delete ' , data : {} , modale : true , name : 'user' , classInput : 'form-control' , cancel : 'cancel' , submit : 'submit' , modaleHeader : "Voulez vous vraiment delete" , hidden : true  }],ZoneService)
        const root = createRoot(container__modal);
        var a = root.render(openModal.render());
        setTimeout(() => {
            openModal.openModal(key,"")
        }, "100")
    }

    var getPostKey = Object.keys(post.data.controllers);
    var getObjectPost = Object.values(post.data.controllers);
    var tier = post.context.tier
    var arrayFinal = [tier];

    return (
        <>
            <div>

            </div>
            <m.div className={"wrapper"}>
                <Nav />
                <div id={"page"}>
                    <Header />
                    <div id="content">
                        <div className={'card'}>
                            <div className="card-header">
                                <div className="row align-items-center">
                                    <div className="col-6">
                                        <h3>{CacheExtension.getDataFromCache('ZonesPageTitle')}</h3>
                                        <input type="hidden" defaultValue={1} id="zone_page"/>
                                        <input type="hidden" defaultValue={1} id="zone_pageCount"/>
                                    </div>
                                </div>
                            </div>
                            <div className={'card-body container fullSize'}>
                                <div className="row mb-4">
                                    <div className="col-sm-6">
                                        <select className="custom-select"
                                                id="zones_tierList">
                                            <option value="api">{"ZonesPageTierAPI"}</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="row mt-2 pb-2">
                                    <div className="col-5">
                                        <strong>Clé</strong>
                                    </div>
                                    <div className="col-5">
                                        <strong>Zone de sécurité</strong>
                                    </div>
                                    <div className="col-2">
                                        &nbsp;
                                    </div>
                                </div>
                                <div className="row border-bottom mt-1">
                                    <div className="col-5">
                                        <div className="input-group mb-3">
                                            <input
                                                type="text"
                                                data-sk="filter"
                                                data-sktype="text"
                                                data-skname="key"
                                                placeholder="Rechercher une clé"
                                                className="form-control"
                                                id="zone_search_key"
                                                onChange={() => skTableKeyPress(this)}
                                            />
                                            <div className="input-group-append">
                                                <button
                                                    className="btn btn-outline-secondary"
                                                    type="button"
                                                    data-sk="clear"
                                                    data-sktype="text"
                                                    data-skname="zone_search_key"
                                                    data-toggle="tooltip"
                                                    data-placement="top"
                                                    title=""
                                                    data-original-title="Effacer le filtre"
                                                >
                                                    <svg
                                                        className="svg-inline--fa fa-xmark"
                                                        aria-hidden="true"
                                                        focusable="false"
                                                        data-prefix="fas"
                                                        data-icon="xmark"
                                                        role="img"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 320 512"
                                                        data-fa-i2svg=""
                                                    >
                                                        <path
                                                            fill="currentColor"
                                                            d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z"
                                                        />
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                        {" "}
                                    </div>
                                    <div className="col-5">
                                        <div className="input-group mb-3">
                                            <select
                                                className="custom-select"
                                                id="zone_search_name"
                                                data-sk="filter"
                                                data-sktype="select"
                                                data-skname="name"
                                                onChange={() => skTableKeyPress(this)}
                                            >
                                                <option value="-">Toutes les zones</option>
                                                <option value="import_role">
                                                    Accès aux fonctions d’import côté BackOffice
                                                </option>
                                                <option value="local_only">Accès interdit</option>
                                                <option value="admin">Administration applicative</option>
                                                <option value="anonymous">Anonyme</option>
                                                <option value="apiService">Api pour accès externe</option>
                                                <option value="settings_operation">Opérations sur les settings</option>
                                                <option value="logged">Tout utilisateur connecté</option>
                                            </select>
                                            <div className="input-group-append">
                                                <button
                                                    className="btn btn-outline-secondary"
                                                    type="button"
                                                    data-sk="clear"
                                                    data-sktype="select"
                                                    data-skname="zone_search_name"
                                                    data-toggle="tooltip"
                                                    data-placement="top"
                                                    title=""
                                                    data-original-title="Effacer le filtre"
                                                >
                                                    <svg
                                                        className="svg-inline--fa fa-xmark"
                                                        aria-hidden="true"
                                                        focusable="false"
                                                        data-prefix="fas"
                                                        data-icon="xmark"
                                                        role="img"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 320 512"
                                                        data-fa-i2svg=""
                                                    >
                                                        <path
                                                            fill="currentColor"
                                                            d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z"
                                                        />
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                        {" "}
                                    </div>
                                    <div className="col-2">&nbsp;</div>
                                </div>


                                {getPostKey ?
                                    new Autoform([{status: 'refresh',arrayFinal :arrayFinal,submitCallback: "", declaration: 'add' , methodRoute:"post", route:"/settings",  keyName:'new', data: valueForm.new, modale:false, name:'settings', classInput:'form-control'  }],ZoneService ).render()
                                    : ""}
                                { (getPostKey) ? (
                                    getPostKey.map( (data, index) =>
                                        <div key={index}
                                            className={"row pt-2 pb-2 align-items-center listzone" +(index%2 ? ' evenTableLine':' oddTableLine')}
                                            id={"setting_" + index}>
                                            <div className="col-3 {{ (needKey)?: 'hidden' }}">
                                                <strong className={'keyname'}>{getPostKey[index]}</strong>
                                            </div>
                                            <div className="col-8 pt-1 pb-1">
                                                <span className={'labelname'} data-csvdata="text" id="setting_text_{{ index }}">
                                                    {getObjectPost[index]}</span>
                                            </div>
                                            <div className={"col-1 text-center"}>

                                                    <a className="actionLink "
                                                       id="setting_edit_1"
                                                       data-skkey={1}
                                                       data-sk="icon"
                                                       data-sktype="edit"
                                                       data-toggle="tooltip"
                                                       data-placement="top"
                                                       onClick={() => updateZone(getPostKey[index],getObjectPost[index])}
                                                       title={CacheExtension.getDataFromCache('ZonesUpdateTooltip')}
                                                       data-original-title="Modifier">
                                                        <svg
                                                            className="svg-inline--fa fa-pencil"
                                                            aria-hidden="true"
                                                            focusable="false"
                                                            data-prefix="fas"
                                                            data-icon="pencil"
                                                            role="img"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 512 512"
                                                            data-fa-i2svg="">
                                                            <path
                                                                fill="currentColor"
                                                                d="M421.7 220.3l-11.3 11.3-22.6 22.6-205 205c-6.6 6.6-14.8 11.5-23.8 14.1L30.8 511c-8.4 2.5-17.5 .2-23.7-6.1S-1.5 489.7 1 481.2L38.7 353.1c2.6-9 7.5-17.2 14.1-23.8l205-205 22.6-22.6 11.3-11.3 33.9 33.9 62.1 62.1 33.9 33.9zM96 353.9l-9.3 9.3c-.9 .9-1.6 2.1-2 3.4l-25.3 86 86-25.3c1.3-.4 2.5-1.1 3.4-2l9.3-9.3H112c-8.8 0-16-7.2-16-16V353.9zM453.3 19.3l39.4 39.4c25 25 25 65.5 0 90.5l-14.5 14.5-22.6 22.6-11.3 11.3-33.9-33.9-62.1-62.1L314.3 67.7l11.3-11.3 22.6-22.6 14.5-14.5c25-25 65.5-25 90.5 0z"
                                                            />
                                                        </svg>
                                                    </a>

                                                    <a
                                                        className="actionLink ml-3"
                                                        id="setting_delete_1"
                                                        data-skkey="confirmToken"
                                                        data-sk="icon"
                                                        data-sktype="delete"
                                                        onClick={() => deleteZone(getPostKey[index])}
                                                        data-toggle="tooltip"
                                                        data-placement="top"
                                                        title={CacheExtension.getDataFromCache('ZonesDeleteTooltip')}
                                                        data-original-title="Supprimer la clé">
                                                        <svg
                                                            className="svg-inline--fa fa-trash-can"
                                                            aria-hidden="true"
                                                            focusable="false"
                                                            data-prefix="fas"
                                                            data-icon="trash-can"
                                                            role="img"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            viewBox="0 0 448 512"
                                                            data-fa-i2svg=""><path
                                                            fill="currentColor"
                                                            d="M135.2 17.7C140.6 6.8 151.7 0 163.8 0H284.2c12.1 0 23.2 6.8 28.6 17.7L320 32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 96 0 81.7 0 64S14.3 32 32 32h96l7.2-14.3zM32 128H416V448c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V128zm96 64c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16z"
                                                        />
                                                        </svg>
                                                    </a>
                                            </div>
                                        </div>
                                    )
                                ) : ( 'ok'
                                )
                                }
                                <div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </m.div>
        </>
    )
}




function skTableKeyPress(value) {
    var tableGO  = [];
    var fullList = document.querySelectorAll(".listzone")
    var fullistKey = document.querySelectorAll(".listzone .keyname")
    var fullistLabel = document.querySelectorAll(".listzone .labelname")
    var value = document.getElementById('zone_search_key')
    var value2 = document.getElementById("zone_search_name")
    if (fullList) {
        for (let i = 0; i < fullList.length ; i++) {
            if (value.value === "" && value2.value === "-") {
                tableGO.push(fullList[i])
            }
            if (value.value == "" & value2.value !== "-") {
                if (fullistLabel[i].innerText.includes(value2.value)) {
                    tableGO.push(fullList[i])
                }
            }
            if (value.value !== "" & value2.value == "-") {
                if (fullistKey[i].innerText.includes(value.value)) {
                    tableGO.push(fullList[i])
                }
            }
            if (value.value !== "" & value2.value != "-") {
                if (fullistLabel[i].innerText.includes(value2.value) && fullistKey[i].innerText.includes(value.value)) {
                    tableGO.push(fullList[i])
                }
            }
            fullList[i].style.display = "none"
        }
    }


    for (let i = 0; i < tableGO.length ; i++) {
        tableGO[i].style.display = 'flex'
    }
}


