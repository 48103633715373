import React from "react";
import {motion as m} from "framer-motion";
import CacheExtension from "../../StarterKit/Filter/CacheExtension";
import 'semantic-ui-css/semantic.min.css'
import '../css/bo.css'
import '../css/Model.css'
import Products from "../Components/Products";
import {Link, useParams} from "react-router-dom";
import {HeaderComponent} from "../Components/HeaderComponent";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default function ProductsPage() {

    let { search } = useParams();

    return (
        <>
            <m.div className={"wrapper"}>
                <div id={"page"}>
                    <HeaderComponent logo={'/pictures/Logo_Pequignet.png'} title={CacheExtension.getDataFromCache('Models')}></HeaderComponent>
                    <div style={{padding:"20px"}}>
                        <Link className="backLinkModel" to={
                            sessionStorage["profile"] == "prod" ? `/prod/stock` :
                                sessionStorage["profile"] == "gestion" ? `/gestion/stock` :
                                    `/sav`
                        }>
                            <FontAwesomeIcon icon="fas fa-chevron-left" style={{marginRight:"10px", color:"rgb(255, 158, 23)"}} />
                            {CacheExtension.getDataFromCache('Back')}
                        </Link>
                    </div>
                    <div id="content">
                        <div id={"page"}>
                            <Products pSearch={search}/>
                        </div>
                    </div>
                </div>
            </m.div>
        </>
    );
}

