import '../../styles/login.css';
import '../../styles/sk.css';
import '../../styles/register.css';
import {motion as m} from "framer-motion"
import React, {useEffect, useState} from "react";
import { Nav } from "../../components/navComponents"
import SealVectorService from "../../App/Services/Bll/SealVectorService";
import FileModel from "../../StarterKit/Model/FileModel";
import SettingService from "../../StarterKit/Service/Bll/SettingService";
import {ImportComponent} from "../../StarterKit/Components/ImportComponent";
import {useNavigate} from "react-router-dom";
import ShopService from "../Services/Bll/ShopService";
import CacheExtension from "../../StarterKit/Filter/CacheExtension";
import ModelService from "../Services/Bll/ModelService";
import ProductService from "../Services/Bll/ProductService";

export default function Imports() {
    const [post, setPost] = React.useState(null);
    const [nbSeal, setNbSeal] = useState({});
    const [nbModels, setNbModels] = useState({});
    const [nbShops, setNbShops] = useState({});
    const [reload, setReload] = useState(false);

    const [products, setProducts] = useState(null);

    var productService = new ProductService();
    var sealVectorService = new SealVectorService();
    var settingService = new SettingService();
    var modelService = new ModelService();
    var shopService = new ShopService();
    var fileModel = new FileModel()
    let navigate = useNavigate()

    React.useEffect(() => {

        // settingService.getFromGroup('productType').then(function (result) {
        //     setPost(Object.keys(result.getData()))
        // })
//todo
        // productService.listAllKey().then(function (result) {
        //     setProducts(result.getData())
        // })


        settingService.getFromGroup('productType').then(function (result) {
            let sealState = {}
            setPost(Object.keys(result.getData()))
            Object.keys(result.getData()).forEach(function (el) {
                sealVectorService.list(el).then(function (res) {
                    sealState[el] = {
                        'available': res.getData().sealVectors.length,
                        'consumed': res.getData().sealVectorsConsumed.length
                    }
                    setNbSeal({...nbSeal,...sealState})
                })
            })
        })

        modelService.listAll().then(function (res) {
            setNbModels(res.getContext()['count'])
        })

        shopService.listAll().then(function (res) {
            setNbShops(res.getContext()['count'].Total)
        })
    },[reload])


    const submitForm = async (e) => {
        e.preventDefault()
        let form = $(e.target).closest('form')
        var imagefile = form.find('#file')[0];
        var type = form.find('#type').val();
        fileModel.file = imagefile.files[0]
        if (imagefile.files[0] && type){
            fileModel.title = imagefile.files[0].name
            let res = await sealVectorService.import(fileModel,type)
            window.location.reload()
        }

    };

    async function  submit(formName) {
        $("body").css('cursor','wait');
        var sealVectorService = new SealVectorService();
        var fileModel = new FileModel()
        var file = $('#'+ formName+'_form input[type="file"]')[0].files[0];
        if (file) {
            fileModel.title = file.name
            fileModel.file = file
            await sealVectorService.import(fileModel,formName);
            setReload(!reload)
            // window.location.reload()
        }
        $("body").css('cursor','default');
    }

    if (!post) return  null
    var service = new SealVectorService


    async function regenerate(products) {
        for (const product of products) {
            let res = await productService.regenerateToken(product)
        }
    }


    return (
        <>
            <m.div className={"wrapper"}>
                <Nav />
                <div id={"page"}>

                    <div id="content">
                        <div className="card">
                            <div className="card-header">
                                <div className="row align-items-center">
                                    <div className="col-6">
                                        <h3>{CacheExtension.getDataFromCache("Imports")}</h3>
                                        <input type="hidden" defaultValue={1} id="users_page"/>
                                        <input type="hidden" defaultValue={1} id="users_pageCount"/>
                                    </div>
                                </div>
                            </div>
                            <div className={"card-body"}>
                                <div id={"page"}>
                                    <div className="App">
                                        { (post && nbSeal) && (
                                            post.map( (index) =>
                                                <div key={index}>
                                                        {nbSeal[index] &&
                                                            <span className={nbSeal[index]['available'] > nbSeal[index]['consumed'] ? 'nbSeal nbSealGreen' : 'nbSeal nbSealRed'}>{CacheExtension.getDataFromCache('Availbale') + ' '+ nbSeal[index]['available']}</span>
                                                        }
                                                        <form
                                                            id={index+'_form'}
                                                            onChange={() => submit(index)}
                                                            action="">
                                                            <ImportComponent formName = {CacheExtension.getDataFromCache("ImportWithReplace").replace("%%1%%",CacheExtension.getDataFromCache(index))} form = {false} />
                                                        </form>
                                                </div>

                                            )
                                        )
                                        }
                                        {(nbShops !== undefined) &&
                                        <>
                                            <span className={nbShops > 0 ? 'nbSeal nbSealGreen' : 'nbSeal nbSealRed'}>{CacheExtension.getDataFromCache('Availbale') + ' '+ nbShops}</span>
                                            <ImportComponent formName = {CacheExtension.getDataFromCache("ImportWithReplace").replace("%%1%%",CacheExtension.getDataFromCache("Shops"))} form = {true} action={async (file) => {
                                                var shopService = new ShopService();
                                                await shopService.import(file);
                                                setReload(!reload)
                                            }}/>
                                        </>
                                        }


                                        {(nbModels !== undefined) &&
                                            <>
                                                <span className={nbModels > 0 ? 'nbSeal nbSealGreen' : 'nbSeal nbSealRed'}>{CacheExtension.getDataFromCache('Availbale') + ' '+ nbModels}</span>
                                                <ImportComponent formName = {CacheExtension.getDataFromCache("ImportWithReplace").replace("%%1%%",CacheExtension.getDataFromCache("Models"))} form = {true} action={async (file) => {
                                                    var modelService = new ModelService();
                                                    await modelService.import(file);
                                                    setReload(!reload)
                                                }}/>
                                            </>
                                        }

                                        {/*{products &&*/}
                                        {/*    <>*/}
                                        {/*        <div>{products.length}</div>*/}
                                        {/*        <button className="btn btnBo" onClick={() => {*/}
                                        {/*            regenerate(products)*/}
                                        {/*        }}>{CacheExtension.getDataFromCache('Validate')}</button>*/}
                                        {/*    </>*/}
                                        {/*}*/}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </m.div>
        </>
    );

}








